import isFunction from 'lodash/isFunction';
import globalConst from '../../constants';
import { checkItemInArray } from '../../utils/utils';

const LOAD = 'redux-ducks/project/LOAD';
const LOAD_SUCCESS = 'redux-ducks/project/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-ducks/project/LOAD_FAIL';

const LOAD_DETAIL = 'redux-ducks/project/LOAD_DETAIL';
const LOAD_DETAIL_SUCCESS = 'redux-ducks/project/LOAD_DETAIL_SUCCESS';
const LOAD_DETAIL_FAIL = 'redux-ducks/project/LOAD_DETAIL_FAIL';

const CREATE = 'redux-ducks/project/CREATE';
const CREATE_SUCCESS = 'redux-ducks/project/CREATE_SUCCESS';
const CREATE_FAIL = 'redux-ducks/project/CREATE_FAIL';

const UPDATE = 'redux-ducks/project/UPDATE';
const UPDATE_SUCCESS = 'redux-ducks/project/UPDATE_SUCCESS';
const UPDATE_FAIL = 'redux-ducks/project/UPDATE_FAIL';

const DELETE = 'redux-ducks/project/DELETE';
const DELETE_SUCCESS = 'redux-ducks/project/DELETE_SUCCESS';
const DELETE_FAIL = 'redux-ducks/project/DELETE_FAIL';

const DELETE_MULTI = 'redux-ducks/project/DELETE_MULTI';
const DELETE_MULTI_SUCCESS = 'redux-ducks/project/DELETE_MULTI_SUCCESS';
const DELETE_MULTI_FAIL = 'redux-ducks/project/DELETE_MULTI_FAIL';

const CLOSE = 'redux-ducks/project/CLOSE';
const CLOSE_SUCCESS = 'redux-ducks/project/CLOSE_SUCCESS';
const CLOSE_FAIL = 'redux-ducks/project/CLOSE_MULTI_FAIL';

const CLOSE_MULTI = 'redux-ducks/project/CLOSE_FAIL';
const CLOSE_MULTI_SUCCESS = 'redux-ducks/project/CLOSE_MULTI_SUCCESS';
const CLOSE_MULTI_FAIL = 'redux-ducks/project/CLOSE_MULTI_FAIL';

const LOAD_WORKERS = 'redux-ducks/project/LOAD_WORKERS';
const LOAD_WORKERS_SUCCESS = 'redux-ducks/project/LOAD_WORKERS_SUCCESS';
const LOAD_WORKERS_FAIL = 'redux-ducks/project/LOAD_WORKERS_FAIL';

const UPDATE_WORKER = 'redux-ducks/project/UPDATE_WORKER';
const UPDATE_WORKER_SUCCESS = 'redux-ducks/project/UPDATE_WORKER_SUCCESS';
const UPDATE_WORKER_FAIL = 'redux-ducks/project/UPDATE_WORKER_FAIL';

const ACCEPT_WORKER = 'redux-ducks/project/ACCEPT_WORKER';
const ACCEPT_WORKER_SUCCESS = 'redux-ducks/project/ACCEPT_WORKER_SUCCESS';
const ACCEPT_WORKER_FAIL = 'redux-ducks/project/ACCEPT_WORKER_FAIL';

const REJECT_WORKER = 'redux-ducks/project/REJECT_WORKER';
const REJECT_WORKER_SUCCESS = 'redux-ducks/project/REJECT_WORKER_SUCCESS';
const REJECT_WORKER_FAIL = 'redux-ducks/project/REJECT_WORKER_FAIL';

const REINVITE_WORKER = 'redux-ducks/project/REINVITE_WORKER';
const REINVITE_WORKER_SUCCESS = 'redux-ducks/project/REINVITE_WORKER_SUCCESS';
const REINVITE_WORKER_FAIL = 'redux-ducks/project/REINVITE_WORKER_FAIL';

export const SET_DEFAULT = 'redux-ducks/project/SET_DEFAULT';

const initialState = {
  projectEntity: {
    allIds: [],
    byId: {},
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    },
  },
};

/**
 * ACTIONS
 */

//
// Projects
// --------------------------------

/**
 * Load projects
 * @param {object} params
 */
export const loadProjects = (query) => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get(`v3/planners/projects?${query}`),
});

export const loadById = (id) => {
  const include = 'include=roles,organization,location,documents,project_custom_attributes_workers,projectlogs';

  return ({
    types: [LOAD_DETAIL, LOAD_DETAIL_SUCCESS, LOAD_DETAIL_FAIL],
    promise: (client) => client.get(`v3/planners/projects/${id}?${include}`),
  });
};

/**
 * Create project
 * @param {object} params
 */
export const createProject = (data) => ({
  types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
  promise: (client) => client.post('v3/planners/projects', { data }),
});

/**
 * Update project
 * @param {object} params
 */
export const updateProject = (data) => {
  const include = 'include=roles,organization,location,documents,project_custom_attributes_workers,projectlogs';

  return ({
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: (client) => client.patch(`v3/planners/projects/${data.id}?${include}`, { data }),
    data,
  });
};

/**
 * Delete project by id
 * @param {number} id projectId
 */
export const deleteProjectById = (id) => ({
  types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
  promise: (client) => client.del(`v3/planners/projects/${id}`),
  id,
});

/**
 * Remove multiple
 */
export const deleteProjects = (data) => ({
  types: [DELETE_MULTI, DELETE_MULTI_SUCCESS, DELETE_MULTI_FAIL],
  promise: (client) => client.del('v3/planners/projects/delete_multiple', { data }),
  data,
});

/**
 * Close project by id
 * @param {number} id projectId
 */
export const closeProjectById = (id) => ({
  types: [CLOSE, CLOSE_SUCCESS, CLOSE_FAIL],
  promise: (client) => client.patch(`v3/planners/projects/${id}/close`),
  id,
});

/**
 * Remove multiple
 */
export const closeMultiple = (data) => ({
  types: [CLOSE_MULTI, CLOSE_MULTI_SUCCESS, CLOSE_MULTI_FAIL],
  promise: (client) => client.patch('v3/planners/projects/close_multiple', { data }),
  data,
});

/**
 * Load workers
 */
export const loadWorkers = (paramsUrl) => ({
  types: [LOAD_WORKERS, LOAD_WORKERS_SUCCESS, LOAD_WORKERS_FAIL],
  promise: (client) => client.get(`v3/planners/projects/workers?${paramsUrl}`),
});

/**
 * Update projectlog
 */
export const updateWorker = (params) => ({
  types: [UPDATE_WORKER, UPDATE_WORKER_SUCCESS, UPDATE_WORKER_FAIL],
  promise: (client) => client
    .patch(`v3/planners/projects/${params.projectId}/projectlogs/${params.projectlogsId}`, { data: params.data }),
});

/**
 * Accept projectlog
 */
export const acceptWorker = (params) => ({
  types: [ACCEPT_WORKER, ACCEPT_WORKER_SUCCESS, ACCEPT_WORKER_FAIL],
  promise: (client) => client
    .patch(`v3/planners/projects/${params.projectId}/projectlogs/${params.projectlogsId}/accept`),
});

/**
 * Re-invite projectlog
 */
export const reinviteWorker = (params) => ({
  types: [REINVITE_WORKER, REINVITE_WORKER_SUCCESS, REINVITE_WORKER_FAIL],
  promise: (client) => client
    .post(`v3/planners/projects/${params.projectId}/projectlogs/${params.projectlogsId}/reinvite`),
});

/**
 * Reject projectlog
 */
export const rejectWorker = (params) => ({
  types: [REJECT_WORKER, REJECT_WORKER_SUCCESS, REJECT_WORKER_FAIL],
  promise: (client) => client
    .patch(`v3/planners/projects/${params.projectId}/projectlogs/${params.projectlogsId}/reject`),
});

export const setDefault = () => ({
  type: SET_DEFAULT
});

/**
 * Load shifts for new planning
 * @param {object} id
 */
export const loadProjectById = (id) => (dispatch) => dispatch(loadById(id));

/**
 * MUTATIONS
 */

// Projects
const loadProjectsSuccess = (action) => (state) => {
  const { data, included, meta } = action.result;
  let updId = [];
  const normalize = {};

  const organizationsIncl = included.filter((include) => include.type === 'organization');
  const locationsIncl = included.filter((include) => include.type === 'location');
  const rolesIncl = included.filter((include) => include.type === 'role');

  data.forEach((item) => {
    const project = item.attributes;
    if (!checkItemInArray(updId, project.id)) {
      updId = [...updId, project.id];
    }

    const orgId = item.relationships.organization.data.id;
    const organizationItem = organizationsIncl.find((org) => org.id === orgId) || {};

    const locationId = item.relationships.location?.data.id || '';
    const locationItem = locationsIncl.find((loc) => loc.id === locationId) || {};

    const roleIds = item.relationships.roles.data.map((role) => +role.id);
    const roleItems = rolesIncl.filter((role) => roleIds.includes(+role.id))
      .map((role) => role.attributes);

    normalize[project.id] = {
      ...project,
      organization: organizationItem.attributes || {},
      location: locationItem.attributes || {},
      roles: roleItems,
    };
  });

  return ({
    ...state,
    projectEntity: {
      allIds: updId,
      byId: normalize,
      pagination: {
        page: meta && meta.pagination ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta && meta.pagination ? meta.pagination.total : updId.length,
        total_pages: meta && meta.pagination ? meta.pagination.total_pages : 1,
      }
    }
  });
};

const updateSuccess = (action) => (state) => {
  const { byId } = state.projectEntity;
  const { data } = action.result;
  const project = data.attributes;

  return ({
    ...state,
    projectEntity: {
      ...state.projectEntity,
      byId: {
        ...byId,
        [project.id]: project,
      }
    },
  });
};

const deleteSuccess = (action) => (state) => {
  const { allIds, byId } = state.projectEntity;

  const filteredAllIds = allIds.filter((id) => id !== action.id);

  return ({
    ...state,
    projectEntity: {
      ...state.projectEntity,
      byId,
      allIds: filteredAllIds
    }
  });
};

const deleteMultiSuccess = (action) => (state) => {
  const { allIds, byId } = state.projectEntity;
  const filteredAllIds = allIds.filter((id) => !action.data.project.ids.includes(id));

  return ({
    ...state,
    projectEntity: {
      ...state.projectEntity,
      byId,
      allIds: filteredAllIds
    }
  });
};
const closeMultiSuccess = (action) => (state) => {
  const { allIds, byId } = state.projectEntity;
  const normalize = {};

  allIds.forEach((id) => {
    const project = byId[id];
    if (action.data.project.ids.includes(id)) {
      project.status = globalConst.CLOSED_STATUS;
    }

    normalize[project.id] = project;
  });

  return ({
    ...state,
    projectEntity: {
      ...state.projectEntity,
      byId: normalize,
    }
  });
};

const setDefaultSuccess = () => ({
  ...initialState,
});

const actionsLookup = {
  [LOAD_SUCCESS]: (state, action) => loadProjectsSuccess(action)(state),
  [UPDATE_SUCCESS]: (state, action) => updateSuccess(action)(state),
  [DELETE_SUCCESS]: (state, action) => deleteSuccess(action)(state),
  [DELETE_MULTI_SUCCESS]: (state, action) => deleteMultiSuccess(action)(state),
  [CLOSE_MULTI_SUCCESS]: (state, action) => closeMultiSuccess(action)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}