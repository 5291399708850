import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import globalConst from '../../constants';
import { serializeParamsToQueryString } from '../../utils/serialize';

const LOAD = 'redux-ducks/organization/LOAD';
const LOAD_SUCCESS = 'redux-ducks/organization/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-ducks/organization/LOAD_FAIL';

const LOAD_ALL = 'redux-ducks/organization/LOAD_ALL';
const LOAD_ALL_SUCCESS = 'redux-ducks/organization/LOAD_ALL_SUCCESS';
const LOAD_ALL_FAIL = 'redux-ducks/organization/LOAD_ALL_FAIL';

const LOAD_ORGANIZATION = 'redux-ducks/organization/LOAD_ORGANIZATION';
const LOAD_ORGANIZATION_SUCCESS = 'redux-ducks/organization/LOAD_ORGANIZATION_SUCCESS';
const LOAD_ORGANIZATION_FAIL = 'redux-ducks/organization/LOAD_ORGANIZATION_FAIL';
const CLEAR_CURRENT = 'redux-ducks/organization/CLEAR_CURRENT';

const CREATE_ORG = 'redux-ducks/organization/CREATE_ORG';
const CREATE_ORG_SUCCESS = 'redux-ducks/organization/CREATE_ORG_SUCCESS';
const CREATE_ORG_FAIL = 'redux-ducks/organization/CREATE_ORG_FAIL';

const COPY_ORG = 'redux-ducks/organization/COPY_ORG';
const COPY_ORG_SUCCESS = 'redux-ducks/organization/COPY_ORG_SUCCESS';
const COPY_ORG_FAIL = 'redux-ducks/organization/COPY_ORG_FAIL';

const UPDATE_ORG = 'redux-ducks/organization/UPDATE_ORG';
const UPDATE_ORG_SUCCESS = 'redux-ducks/organization/UPDATE_ORG_SUCCESS';
const UPDATE_ORG_FAIL = 'redux-ducks/organization/UPDATE_ORG_FAIL';

/* const DELETE_ORG = 'redux-ducks/organization/DELETE_ORG';
const DELETE_ORG_SUCCESS = 'redux-ducks/organization/DELETE_ORG_SUCCESS';
const DELETE_ORG_FAIL = 'redux-ducks/organization/DELETE_ORG_FAIL'; */

const ARCHIVE_ORG = 'redux-ducks/organization/ARCHIVE_ORG';
const ARCHIVE_ORG_SUCCESS = 'redux-ducks/organization/ARCHIVE_ORG_SUCCESS';
const ARCHIVE_ORG_FAIL = 'redux-ducks/organization/ARCHIVE_ORG_FAIL';

const RESTORE_ORG = 'redux-ducks/organization/RESTORE_ORG';
const RESTORE_ORG_SUCCESS = 'redux-ducks/organization/RESTORE_ORG_SUCCESS';
const RESTORE_ORG_FAIL = 'redux-ducks/organization/RESTORE_ORG_FAIL';

// CRUD for locations
const SAVE_LOC = 'redux-ducks/organization/SAVE_LOC';
const SAVE_LOC_SUCCESS = 'redux-ducks/organization/SAVE_LOC_SUCCESS';
const SAVE_LOC_FAIL = 'redux-ducks/organization/SAVE_LOC_FAIL';
const UPDATE_LOC = 'redux-ducks/organization/UPDATE_LOC';
const UPDATE_LOC_SUCCESS = 'redux-ducks/organization/UPDATE_LOC_SUCCESS';
const UPDATE_LOC_FAIL = 'redux-ducks/organization/UPDATE_LOC_FAIL';
const DELETE_LOC = 'redux-ducks/organization/DELETE_LOC';
const DELETE_LOC_SUCCESS = 'redux-ducks/organization/DELETE_LOC_SUCCESS';
const DELETE_LOC_FAIL = 'redux-ducks/organization/DELETE_LOC_FAIL';

const UPLOAD = 'redux-ducks/organization/UPLOAD';
const UPLOAD_SUCCESS = 'redux-ducks/organization/UPLOAD_SUCCESS';
const UPLOAD_FAIL = 'redux-ducks/organization/UPLOAD_FAIL';

const UPLOAD_DOCUMENT = 'redux-ducks/organization/UPLOAD_DOCUMENT';
const UPLOAD_DOCUMENT_SUCCESS = 'redux-ducks/organization/UPLOAD_DOCUMENT_SUCCESS';
const UPLOAD_DOCUMENT_FAIL = 'redux-ducks/organization/UPLOAD_DOCUMENT_FAIL';
const REMOVE_DOCUMENT = 'redux-ducks/organization/REMOVE_DOCUMENT';
const REMOVE_DOCUMENT_SUCCESS = 'redux-ducks/organization/REMOVE_DOCUMENT_SUCCESS';
const REMOVE_DOCUMENT_FAIL = 'redux-ducks/organization/REMOVE_DOCUMENT_FAIL';
const DOWNLOAD_DOCUMENT = 'redux-ducks/organization/DOWNLOAD_DOCUMENT';
const DOWNLOAD_DOCUMENT_SUCCESS = 'redux-ducks/organization/DOWNLOAD_DOCUMENT_SUCCESS';
const DOWNLOAD_DOCUMENT_FAIL = 'redux-ducks/organization/DOWNLOAD_DOCUMENT_FAIL';

const REMOVE_PHOTO = 'redux-ducks/organization/REMOVE_PHOTO';
const REMOVE_PHOTO_SUCCESS = 'redux-ducks/organization/REMOVE_PHOTO_SUCCESS';
const REMOVE_PHOTO_FAIL = 'redux-ducks/organization/REMOVE_PHOTO_FAIL';

const UPDATE_FAVORITES = 'redux-ducks/organization/UPDATE_FAVORITES';
const UPDATE_FAVORITES_SUCCESS = 'redux-ducks/organization/UPDATE_FAVORITES_SUCCESS';
const UPDATE_FAVORITES_FAIL = 'redux-ducks/organization/UPDATE_FAVORITES_FAIL';

const UPDATE_BLACKLIST = 'redux-ducks/organization/UPDATE_BLACKLIST';
const UPDATE_BLACKLIST_SUCCESS = 'redux-ducks/organization/UPDATE_BLACKLIST_SUCCESS';
const UPDATE_BLACKLIST_FAIL = 'redux-ducks/organization/UPDATE_BLACKLIST_FAIL';

const EXPORT_DATA = 'redux-ducks/organization/EXPORT_DATA';
const EXPORT_DATA_SUCCESS = 'redux-ducks/organization/EXPORT_DATA_SUCCESS';
const EXPORT_DATA_FAIL = 'redux-ducks/organization/EXPORT_DATA_FAIL';

const IMPORT_TEMPLATE = 'redux-duck/organization/IMPORT_TEMPLATE';
const IMPORT_TEMPLATE_SUCCESS = 'redux-duck/organization/IMPORT_TEMPLATE_SUCCESS';
const IMPORT_TEMPLATE_FAIL = 'redux-duck/organization/IMPORT_TEMPLATE_FAIL';

const IMPORT_ORGANIZATIONS = 'redux-duck/organization/IMPORT_ORGANIZATIONS';
const IMPORT_ORGANIZATIONS_SUCCESS = 'redux-duck/organization/IMPORT_ORGANIZATIONS_SUCCESS';
const IMPORT_ORGANIZATIONS_FAIL = 'redux-duck/organization/IMPORT_ORGANIZATIONS_FAIL';

const ENRICH_ORGANIZATIONS = 'redux-duck/organization/ENRICH_ORGANIZATIONS';
const ENRICH_ORGANIZATIONS_SUCCESS = 'redux-duck/organization/ENRICH_ORGANIZATIONS_SUCCESS';
const ENRICH_ORGANIZATIONS_FAIL = 'redux-duck/organization/ENRICH_ORGANIZATIONS_FAIL';

const LOAD_GROUP_ORGANIZATIONS = 'redux-duck/organization/LOAD_GROUP_ORGANIZATIONS';
const LOAD_GROUP_ORGANIZATIONS_SUCCESS = 'redux-duck/organization/LOAD_GROUP_ORGANIZATIONS_SUCCESS';
const LOAD_GROUP_ORGANIZATIONS_FAIL = 'redux-duck/organization/LOAD_GROUP_ORGANIZATIONS_FAIL';

const CREATE_GROUP_ORGANIZATIONS = 'redux-duck/organization/CREATE_GROUP_ORGANIZATIONS';
const CREATE_GROUP_ORGANIZATIONS_SUCCESS = 'redux-duck/organization/CREATE_GROUP_ORGANIZATIONS_SUCCESS';
const CREATE_GROUP_ORGANIZATIONS_FAIL = 'redux-duck/organization/CREATE_GROUP_ORGANIZATIONS_FAIL';

const UPDATE_GROUP_ORGANIZATIONS = 'redux-duck/organization/UPDATE_GROUP_ORGANIZATIONS';
const UPDATE_GROUP_ORGANIZATIONS_SUCCESS = 'redux-duck/organization/UPDATE_GROUP_ORGANIZATIONS_SUCCESS';
const UPDATE_GROUP_ORGANIZATIONS_FAIL = 'redux-duck/organization/UPDATE_GROUP_ORGANIZATIONS_FAIL';

const DELETE_GROUP_ORGANIZATIONS = 'redux-duck/organization/DELETE_GROUP_ORGANIZATIONS';
const DELETE_GROUP_ORGANIZATIONS_SUCCESS = 'redux-duck/organization/DELETE_GROUP_ORGANIZATIONS_SUCCESS';
const DELETE_GROUP_ORGANIZATIONS_FAIL = 'redux-duck/organization/DELETE_GROUP_ORGANIZATIONS_FAIL';

const LOAD_BILLING_INFO = 'redux-duck/organization/LOAD_BILLING_INFO';
const LOAD_BILLING_INFO_SUCCESS = 'redux-duck/organization/LOAD_BILLING_INFO_SUCCESS';
const LOAD_BILLING_INFO_FAIL = 'redux-duck/organization/LOAD_BILLING_INFO_FAIL';

const UPDATE_BILLING_INFO = 'redux-duck/organization/UPDATE_BILLING_INFO';
const UPDATE_BILLING_INFO_SUCCESS = 'redux-duck/organization/UPDATE_BILLING_INFO_SUCCESS';
const UPDATE_BILLING_INFO_FAIL = 'redux-duck/organization/UPDATE_BILLING_INFO_FAIL';

const UPDATE_ORG_EXPENSES = 'redux-duck/organization/UPDATE_ORG_EXPENSES';
const UPDATE_ORG_EXPENSES_SUCCESS = 'redux-duck/organization/UPDATE_ORG_EXPENSES_SUCCESS';
const UPDATE_ORG_EXPENSES_FAIL = 'redux-duck/organization/UPDATE_ORG_EXPENSES_FAIL';

const SET_DEFAULT = 'redux-ducks/organization/SET_DEFAULT';

const initialState = {
  loaded: false,
  loading: false,
  entity: {
    current: {
      locations: [],
      favorite_workers: [],
      blacklist_workers: [],
      custom_organization_attributes: [],
      managers: [],
      expenseTypesIds: [],
    },
    allIds: [],
    byId: {},
    byLocId: {},
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    }
  },
  billingInfo: {},
  groupEntity: {
    allIds: [],
    byId: {},
    byOrgId: {},
  },
  error: {},
};

/**
 * Load organizations
 */
export const load = (params) => {
  const include = 'include=locations';
  return ({
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get(`v3/planners/organizations?${include}`, { params }),
  });
};

/**
 * Load all organizations
 */
export const loadAll = () => ({
  types: [LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_FAIL],
  promise: (client) => client.get('v3/planners/organizations'),
});

/**
 * Load organization by id
 * @param {number} id Organization Id
 */
export const loadOrganizationById = (id, include = '') => ({
  types: [LOAD_ORGANIZATION, LOAD_ORGANIZATION_SUCCESS, LOAD_ORGANIZATION_FAIL],
  promise: (client) => client.get(`v3/planners/organizations/${id}?${include}`),
});

/**
 * Set default organization
 */
export const setDefault = () => ({
  type: SET_DEFAULT,
});

/**
 * Create organization
 * @param {object} data
 */
export const create = (data) => ({
  types: [CREATE_ORG, CREATE_ORG_SUCCESS, CREATE_ORG_FAIL],
  promise: (client) => client.post('v3/planners/organizations', { data }),
  data,
});

export const copy = (data) => ({
  types: [COPY_ORG, COPY_ORG_SUCCESS, COPY_ORG_FAIL],
  promise: (client) => client.post(`v3/planners/organizations/${data.id}/copy?include=${data.includes}`, { data }),
  data,
});
/**
 * Update organization
 * @param {object} data
 */
export const update = (data, include = '') => ({
  types: [UPDATE_ORG, UPDATE_ORG_SUCCESS, UPDATE_ORG_FAIL],
  promise: (client) => client.patch(`v3/planners/organizations/${data.id}?${include}`, { data }),
  data,
});

/**
 * Handle update managers for organization
 */
export const updateManagersList = (data, include = '') => ({
  types: [UPDATE_ORG, UPDATE_ORG_SUCCESS, UPDATE_ORG_FAIL],
  promise: (client) => client
    .patch(`v3/planners/organizations/${data.id}/update_internal_managers?${include}`, { data }),
  data,
});

/**
 * Upload photo for organization
 * @param {object} data
 */
export const uploadOrgPhoto = ({ orgId, file }) => {
  const attributesIncludes = 'custom_organization_attributes,custom_organization_attributes.custom_attribute';
  const workersIncludes = 'favorite_workers,blacklist_workers,internal_managers';
  const include = `include=${attributesIncludes},${workersIncludes}`;

  return ({
    types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL],
    promise: (client) => client.patch(`v3/planners/organizations/${orgId}/photo?${include}`, { data: file }),
  });
};

/**
 * Remove organization photo
 * @param {number} orgId
 */
export const removeOrgPhoto = (orgId) => ({
  types: [REMOVE_PHOTO, REMOVE_PHOTO_SUCCESS, REMOVE_PHOTO_FAIL],
  promise: (client) => client.del(`v3/planners/organizations/${orgId}/photo`),
  orgId,
});

/**
 * Save location
 * @param {object} data
 */
export const saveLocation = (data) => ({
  types: [SAVE_LOC, SAVE_LOC_SUCCESS, SAVE_LOC_FAIL],
  promise: (client) => client.post(`v3/planners/organizations/${data.organization_id}/locations`, { data }),
  data,
});

/**
 * Update location by id,
 * @param {object} data
 */
export const updateLocation = (data) => ({
  types: [UPDATE_LOC, UPDATE_LOC_SUCCESS, UPDATE_LOC_FAIL],
  promise: (client) => client.patch(`v3/planners/organizations/${data.orgId}/locations/${data.id}`, { data }),
  data,
});

/**
 * Remove location
 * @param {*} id
 * @param {*} orgId
 */
export const deleteLocation = (id, orgId = '') => {
  const params = {
    locationId: id,
    orgId,
  };

  return {
    types: [DELETE_LOC, DELETE_LOC_SUCCESS, DELETE_LOC_FAIL],
    promise: (client) => client.del(`v3/planners/organizations/${orgId}/locations/${id}`),
    params
  };
};

/**
 * Delete organization by id
 * @param {number} id Organization id
 */
/* export const deleteOrganization = (id) => ({
  types: [DELETE_ORG, DELETE_ORG_SUCCESS, DELETE_ORG_FAIL],
  promise: (client) => client.del(`v3/planners/organizations/${id}`),
  id,
}); */

/**
 * Archive organization by id
 * @param {number} id Organization id
 */
export const archiveOrganization = (id) => ({
  types: [ARCHIVE_ORG, ARCHIVE_ORG_SUCCESS, ARCHIVE_ORG_FAIL],
  promise: (client) => client.patch(`v3/planners/organizations/${id}/archive`),
  id,
});

/**
 * Resore organization by id
 * @param {number} id Organization id
 */
export const restoreOrganization = (id) => ({
  types: [RESTORE_ORG, RESTORE_ORG_SUCCESS, RESTORE_ORG_FAIL],
  promise: (client) => client.patch(`v3/planners/organizations/${id}/restore`),
  id,
});

/**
 * Update favorites list
 * @param data
 * @return {{types: [start,success,fail], promise: (function(*))}}
 */
export const updateFavorites = (data, include = '') => ({
  types: [UPDATE_FAVORITES, UPDATE_FAVORITES_SUCCESS, UPDATE_FAVORITES_FAIL],
  promise: (client) => client.patch(`v3/planners/favorites/${data.id}?${include}`, { data }),
});

/**
 * Update blacklist
 * @param data
 * @return {{types: [start,success,fail], promise: (function(*))}}
 */
export const updateBlacklist = (data, include = '') => ({
  types: [UPDATE_BLACKLIST, UPDATE_BLACKLIST_SUCCESS, UPDATE_BLACKLIST_FAIL],
  promise: (client) => client.patch(`v3/planners/blacklists/${data.id}?${include}`, { data }),
});

/**
 * Download data
 */
export const exportData = () => ({
  types: [EXPORT_DATA, EXPORT_DATA_SUCCESS, EXPORT_DATA_FAIL],
  promise: (client) => client.get('v3/planners/organizations/export'),
});

/**
 * Create import template
 */
export const createImportTemplate = () => ({
  types: [IMPORT_TEMPLATE, IMPORT_TEMPLATE_SUCCESS, IMPORT_TEMPLATE_FAIL],
  promise: (client) => client.get('v3/planners/organizations/generate_creation_template'),
});

/**
 * Import organizations from file (create)
 * @param {object} data
 */
export const importOrganizations = (data) => ({
  types: [IMPORT_ORGANIZATIONS, IMPORT_ORGANIZATIONS_SUCCESS, IMPORT_ORGANIZATIONS_FAIL],
  promise: (client) => client.post('v3/planners/organizations/import_creation_template', { data }),
});

/**
 * Import organizations from file (create)
 * @param {object} data
 */
export const enrichOrganizations = (data) => ({
  types: [ENRICH_ORGANIZATIONS, ENRICH_ORGANIZATIONS_SUCCESS, ENRICH_ORGANIZATIONS_FAIL],
  promise: (client) => client.post('v3/planners/organizations/enrich', { data }),
});

/**
 * Upload documents
 * @param {object} data
 */
export const uploadDocument = (data) => ({
  types: [UPLOAD_DOCUMENT, UPLOAD_DOCUMENT_SUCCESS, UPLOAD_DOCUMENT_FAIL],
  promise: (client) => client.patch(`v3/planners/organizations/${data.id}/document?include=documents`, { data })
});

/**
 * Remove documents
 * @param {object} data
 */
export const removeDocument = (data) => ({
  types: [REMOVE_DOCUMENT, REMOVE_DOCUMENT_SUCCESS, REMOVE_DOCUMENT_FAIL],
  promise: (client) => client.del(`v3/planners/organizations/${data.id}/document`, { data }),
  data,
});

/**
 * Download documents
 * @param {object} data
 */
export const downloadDocument = (data) => ({
  types: [DOWNLOAD_DOCUMENT, DOWNLOAD_DOCUMENT_SUCCESS, DOWNLOAD_DOCUMENT_FAIL],
  promise: (client) => client.get(
    `v3/planners/organizations/${data.entityId}/document?document_id=${data.docId}&password=${data.password}`,
    { responseType: 'blob' },
  ),
});

/**
 * Clear current
 */
export const clearCurrent = () => ({
  type: CLEAR_CURRENT,
});

export const loadGroupEntities = (filterParams) => {
  const params = serializeParamsToQueryString(filterParams, true);

  return ({
    types: [LOAD_GROUP_ORGANIZATIONS, LOAD_GROUP_ORGANIZATIONS_SUCCESS, LOAD_GROUP_ORGANIZATIONS_FAIL],
    promise: (client) => client.get('v3/planners/organizations_groups?include=organizations', { params }),
  });
};
export const createGroupEntity = (data) => ({
  types: [CREATE_GROUP_ORGANIZATIONS, CREATE_GROUP_ORGANIZATIONS_SUCCESS, CREATE_GROUP_ORGANIZATIONS_FAIL],
  promise: (client) => client.post('v3/planners/organizations_groups?include=organizations', { data }),
});
export const updateGroupEntity = (data) => ({
  types: [UPDATE_GROUP_ORGANIZATIONS, UPDATE_GROUP_ORGANIZATIONS_SUCCESS, UPDATE_GROUP_ORGANIZATIONS_FAIL],
  promise: (client) => client.patch(`v3/planners/organizations_groups/${data.id}?include=organizations`, { data }),
});
export const deleteGroupEntity = (id) => ({
  types: [DELETE_GROUP_ORGANIZATIONS, DELETE_GROUP_ORGANIZATIONS_SUCCESS, DELETE_GROUP_ORGANIZATIONS_FAIL],
  promise: (client) => client.del(`v3/planners/organizations_groups/${id}`),
  id,
});

export const loadBillingInfo = (id) => ({
  types: [LOAD_BILLING_INFO, LOAD_BILLING_INFO_SUCCESS, LOAD_BILLING_INFO_FAIL],
  promise: (client) => client.get(`v3/planners/organizations/${id}/billing_info`),
});

export const updateBillingInfo = (data) => ({
  types: [UPDATE_BILLING_INFO, UPDATE_BILLING_INFO_SUCCESS, UPDATE_BILLING_INFO_FAIL],
  promise: (client) => client.patch(`v3/planners/organizations/${data.id}/billing_info`, { data }),
});

// Expenses

export const updateOrgExpenses = (data) => ({
  types: [UPDATE_ORG_EXPENSES, UPDATE_ORG_EXPENSES_SUCCESS, UPDATE_ORG_EXPENSES_FAIL],
  promise: (client) => client
    .patch(`v3/planners/organizations/${data.id}/update_expenses?include=expense_types`, { data }),
});

// Actions
// ************************

/**
 * Create organization
 * @param {object} params
 */
export const createOrganization = (params) => (dispatch) => dispatch(create(params))
  .then((data) => data)
  .catch((err) => {
    throw err;
  });

export const copyOrganization = (params) => (dispatch) => dispatch(copy(params))
  .then((data) => data)
  .catch((err) => {
    throw err;
  });

/**
 * Update organization
 * @param {object} params
 */
export const updateOrganization = (params) => (dispatch) => dispatch(update(params));

/**
 * Load organizations
 */
export const loadOrganizations = (params = {}) => (dispatch) => {
  const paramsUrl = serializeParamsToQueryString(params, true);
  return dispatch(load(paramsUrl));
};

// Mutations
// *************************************

const onLoadOrganizations = (value) => (state) => ({
  ...state,
  loading: value
});

const loadOrganizationsSuccess = (action) => (state) => {
  const { data, included, meta } = action.result;

  const updId = data.map((item) => +item.id);
  const normalize = {};
  data.forEach((item) => {
    const locationIds = item.relationships.locations.data.map((loc) => +loc.id);

    normalize[item.id] = {
      ...item.attributes,
      locationIds,
    };
  });

  const byLocId = {};
  included.forEach((item) => {
    byLocId[item.id] = {
      ...item.attributes,
    };
  });

  return ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    entity: {
      ...state.entity,
      allIds: updId,
      byId: normalize,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      },
      byLocId,
    }
  });
};

const onLoadOrganizationsFail = (action, value) => (state) => ({
  ...state,
  loading: false,
  loaded: value,
  error: action.error,
  entity: {
    ...state.entity,
    allIds: [],
    byId: {},
    byLocId: {},
  }
});

const createOrgSuccess = (action) => (state) => {
  const { allIds, byId } = state.entity;
  const { data } = action.result;

  return ({
    ...state,
    entity: {
      ...state.entity,
      byId: {
        ...byId,
        [data.id]: {
          ...data.attributes,
          locationIds: [],
        }
      },
      allIds: [
        ...allIds,
        data.id
      ],
    },
  });
};

/**
 * Load organization by id
 * @param {object} action
 */
const loadOrgSuccess = (action) => (state) => {
  const { data, included } = action.result;

  const locations = included.filter((item) => item.type === 'location')
    .map((item) => ({
      ...item.attributes,
    }));
  const favoriteWorker = included.filter((item) => item.type === 'favorite_worker')
    .map((item) => ({
      ...item.attributes,
    }));
  const blacklistWorker = included.filter((item) => item.type === 'blacklist_worker')
    .map((item) => ({
      ...item.attributes,
    }));
  const managers = included.filter((item) => item.type === 'manager')
    .map((item) => ({
      ...item.attributes,
    }));

  const customAttribute = included.filter((item) => item.type === 'custom_attribute');
  const normalizeAttribute = customAttribute.reduce((obj, item) => ({ ...obj, [item.id]: item.attributes }), {});

  const customOrganizationAttribute = included.filter((item) => item.type === 'custom_organization_attribute')
    .map((item) => ({
      ...item.attributes,
      custom_attribute: normalizeAttribute[item.relationships.custom_attribute.data.id],
    }));
  const documents = included ? included.filter((item) => item.type === 'document')
    .map((item) => ({
      ...item.attributes,
    })) : [];
  const expenseTypesIds = included ? included.filter((item) => item.type === 'expense_type')
    .map((item) => (item.attributes.id)) : [];

  const getMaxDistance = data.attributes.max_distance_covered === null ? '' : +data.attributes.max_distance_covered;

  return ({
    ...state,
    entity: {
      ...state.entity,
      current: {
        ...data.attributes,
        locations,
        favorite_workers: favoriteWorker,
        blacklist_workers: blacklistWorker,
        custom_organization_attributes: customOrganizationAttribute,
        maxDistance: getMaxDistance,
        documents,
        managers,
        expenseTypesIds,
      },
    }
  });
};

const clearCurrentSuccess = () => (state) => ({
  ...state,
  entity: {
    ...state.entity,
    current: {},
  },
  billingInfo: {},
});

/**
 * Update organization in state
 * @param {object} action
 */

const updateOrgSuccess = (action) => (state) => {
  const { data, included } = action.result;
  const { current } = state.entity;

  const documentList = included.filter((item) => item.type === 'document')
    .map((item) => ({
      ...item.attributes,
    }));
  const favoriteWorker = included.filter((item) => item.type === 'favorite_worker')
    .map((item) => ({
      ...item.attributes,
    }));
  const blacklistWorker = included.filter((item) => item.type === 'blacklist_worker')
    .map((item) => ({
      ...item.attributes,
    }));

  const managers = included.filter((item) => item.type === 'manager')
    .map((item) => ({
      ...item.attributes,
    }));

  const documents = !isEmpty(documentList) ? documentList : current.documents;

  const customAttribute = included.filter((item) => item.type === 'custom_attribute');
  const normalizeAttribute = customAttribute.reduce((obj, item) => ({ ...obj, [item.id]: item.attributes }), {});

  const customOrganizationAttribute = included.filter((item) => item.type === 'custom_organization_attribute')
    .map((item) => ({
      ...item.attributes,
      custom_attribute: normalizeAttribute[item.relationships.custom_attribute.data.id],
    }));

  const expenseTypesIds = included.filter((item) => item.type === 'expense_type')
    .map((item) => item.attributes.id);

  const getMaxDistance = data.attributes.max_distance_covered === null ? '' : +data.attributes.max_distance_covered;

  return ({
    ...state,
    entity: {
      ...state.entity,
      current: {
        ...current,
        ...data.attributes,
        favorite_workers: favoriteWorker,
        blacklist_workers: blacklistWorker,
        custom_organization_attributes: customOrganizationAttribute,
        maxDistance: getMaxDistance,
        documents,
        managers,
        expenseTypesIds,
      },
    }
  });
};

/**
 * Restore organization in state
 * @param {object} action
 */
const restoreOrgSuccess = (action) => (state) => {
  const { data } = action.result;
  const { current } = state.entity;

  return ({
    ...state,
    entity: {
      ...state.entity,
      current: {
        ...current,
        status: data.attributes.status,
      },
    }
  });
};

/**
 * remove organization from state
 */
/* const deleteOrgSuccess = (action) => (state) => {
  const { allIds, byId } = state.entity;
  const filteredAllIds = allIds.filter((id) => id !== action.id);

  delete byId[action.id];

  return ({
    ...state,
    entity: {
      ...state.entity,
      allIds: filteredAllIds,
      byId,
    },
  });
}; */

/**
 * Save location
 * @param {object} action
 */
const saveLocationSuccess = (action) => (state) => {
  const { current } = state.entity;
  const { data, result } = action;

  // if save location for shift, not need to save
  if (data.location.type) {
    return ({
      ...state,
    });
  }

  const location = result.data.attributes;
  return ({
    ...state,
    entity: {
      ...state.entity,
      current: {
        ...current,
        locations: [
          ...current.locations,
          location,
        ],
      },
    }
  });
};

/**
 * Update location
 * @param {object} action
 */
const updateLocationSuccess = (action) => (state) => {
  const { current } = state.entity;
  const { id } = action.data;

  const location = action.result.data.attributes;

  const updated = current.locations.map((obj) => {
    if (obj.id === id) {
      return location;
    }

    return obj;
  });

  return ({
    ...state,
    entity: {
      ...state.entity,
      current: {
        ...current,
        locations: updated,
      },
    }
  });
};

/**
 * Remove location
 * @param {object} action
 */
const removeLocationSuccess = (action) => (state) => {
  const { current } = state.entity;
  const { locationId } = action.params;
  const filteredLocations = current.locations.filter((location) => location.id !== locationId);

  return ({
    ...state,
    entity: {
      ...state.entity,
      current: {
        ...current,
        locations: filteredLocations,
      },
    }
  });
};

/**
 * Remove photo
 * @param {object} action
 */
const removeOrgPhotoSuccess = () => (state) => {
  const { current } = state.entity;

  return ({
    ...state,
    entity: {
      ...state.entity,
      current: {
        ...current,
        photo: '',
      },
    },
  });
};

const uploadDocumentSuccess = (action) => (state) => {
  const { current } = state.entity;
  const { included } = action.result;

  const documents = included ? included.filter((item) => item.type === 'document')
    .map((item) => ({
      ...item.attributes,
    })) : [];

  return ({
    ...state,
    entity: {
      ...state.entity,
      loading: false,
      current: {
        ...current,
        documents,
      },
    },
  });
};

const removeDocumentSuccess = (action) => (state) => {
  const { current } = state.entity;
  const { data } = action;
  const filtered = current.documents.filter((item) => data.remove_ids.indexOf(item.id) === -1);

  return ({
    ...state,
    entity: {
      ...state.entity,
      loading: false,
      current: {
        ...current,
        documents: filtered,
      }
    },
  });
};

const loadGroupEntitiesSuccess = (action) => (state) => {
  const { data, included } = action.result;

  const updId = data.map((item) => +item.id);
  const normalize = {};
  data.forEach((item) => {
    normalize[item.id] = {
      ...item.attributes,
      organization_ids: item.attributes.organization_ids || [],
    };
  });

  const byOrgId = {};
  included.forEach((item) => {
    byOrgId[item.id] = {
      ...item.attributes,
    };
  });

  return ({
    ...state,
    groupEntity: {
      ...state.groupEntity,
      allIds: updId,
      byId: normalize,
      byOrgId,
    },
  });
};

const createGroupEntitySuccess = (action) => (state) => {
  const { allIds, byId } = state.groupEntity;
  const { data } = action.result;

  return ({
    ...state,
    groupEntity: {
      ...state.groupEntity,
      byId: {
        ...byId,
        [data.id]: {
          ...data.attributes,
          organizationIds: [],
        }
      },
      allIds: [
        ...allIds,
        data.id
      ],
    },
  });
};

const updateGroupEntitySuccess = (action) => (state) => {
  const { byId, byOrgId } = state.groupEntity;
  const { data, included } = action.result;

  const updatedOrgId = {};
  included.forEach((item) => {
    updatedOrgId[item.id] = {
      ...item.attributes,
    };
  });

  return ({
    ...state,
    groupEntity: {
      ...state.groupEntity,
      byId: {
        ...byId,
        [data.id]: {
          ...data.attributes,
        }
      },
      byOrgId: {
        ...byOrgId,
        ...updatedOrgId,
      }
    },
  });
};

const deleteGroupEntitySuccess = (action) => (state) => {
  const { allIds, byId } = state.groupEntity;
  const filteredAllIds = allIds.filter((id) => id !== action.id);

  delete byId[action.id];

  return ({
    ...state,
    groupEntity: {
      ...state.groupEntity,
      allIds: filteredAllIds,
      byId,
    },
  });
};

const updateBillingInfoSuccess = (action) => (state) => {
  const { data } = action.result;

  return ({
    ...state,
    billingInfo: {
      ...state.billingInfo,
      companyName: data.attributes.company_name || '',
      address: data.attributes.address || '',
      code: data.attributes.postal_code || '',
      city: data.attributes.city || '',
      KVKNumber: data.attributes.kvk || '',
      btwNumber: data.attributes.vat || '',
      IBANNumber: data.attributes.iban || '',
      email: data.attributes.email || '',
      phone: data.attributes.phone || '',
      invoiceTo: data.attributes.to_field || '',
      paymentTermInfo: data.attributes.payment_term_info || '',
      costCenter: data.attributes.cost_center || '',
      proActiveUbl: data.attributes.pro_active_ubl || false,
      correctness: data.attributes.correctness,
    }
  });
};

const setDefaultSuccess = () => initialState;

const actionsLookup = {
  [LOAD]: (state) => onLoadOrganizations(true)(state),
  [LOAD_SUCCESS]: (state, action) => loadOrganizationsSuccess(action)(state),
  [LOAD_FAIL]: (state, action) => onLoadOrganizationsFail(action, true)(state),
  [LOAD_ORGANIZATION_SUCCESS]: (state, action) => loadOrgSuccess(action)(state),
  [CLEAR_CURRENT]: (state) => clearCurrentSuccess()(state),
  [CREATE_ORG_SUCCESS]: (state, action) => createOrgSuccess(action)(state),
  [COPY_ORG_SUCCESS]: (state, action) => createOrgSuccess(action)(state),
  [UPDATE_ORG_SUCCESS]: (state, action) => updateOrgSuccess(action)(state),
  [RESTORE_ORG_SUCCESS]: (state, action) => restoreOrgSuccess(action)(state),
  // [DELETE_ORG_SUCCESS]: (state, action) => deleteOrgSuccess(action)(state),
  [SAVE_LOC_SUCCESS]: (state, action) => saveLocationSuccess(action)(state),
  [UPDATE_LOC_SUCCESS]: (state, action) => updateLocationSuccess(action)(state),
  [DELETE_LOC_SUCCESS]: (state, action) => removeLocationSuccess(action)(state),
  [REMOVE_PHOTO_SUCCESS]: (state, action) => removeOrgPhotoSuccess(action)(state),
  [UPLOAD_SUCCESS]: (state, action) => updateOrgSuccess(action)(state),
  [UPDATE_FAVORITES_SUCCESS]: (state, action) => updateOrgSuccess(action)(state),
  [UPDATE_BLACKLIST_SUCCESS]: (state, action) => updateOrgSuccess(action)(state),
  [UPLOAD_DOCUMENT_SUCCESS]: (state, action) => uploadDocumentSuccess(action)(state),
  [REMOVE_DOCUMENT_SUCCESS]: (state, action) => removeDocumentSuccess(action)(state),
  [LOAD_GROUP_ORGANIZATIONS_SUCCESS]: (state, action) => loadGroupEntitiesSuccess(action)(state),
  [CREATE_GROUP_ORGANIZATIONS_SUCCESS]: (state, action) => createGroupEntitySuccess(action)(state),
  [UPDATE_GROUP_ORGANIZATIONS_SUCCESS]: (state, action) => updateGroupEntitySuccess(action)(state),
  [DELETE_GROUP_ORGANIZATIONS_SUCCESS]: (state, action) => deleteGroupEntitySuccess(action)(state),
  [LOAD_BILLING_INFO_SUCCESS]: (state, action) => updateBillingInfoSuccess(action)(state),
  [UPDATE_BILLING_INFO_SUCCESS]: (state, action) => updateBillingInfoSuccess(action)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}
