import isFunction from 'lodash/isFunction';
import flowRight from 'lodash/flowRight';

const LOAD = 'redux-ducks/invoice/LOAD';
const LOAD_SUCCESS = 'redux-ducks/invoice/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-ducks/invoice/LOAD_FAIL';

const CREATE = 'redux-ducks/invoice/CREATE';
const CREATE_SUCCESS = 'redux-ducks/invoice/CREATE_SUCCESS';
const CREATE_FAIL = 'redux-ducks/invoice/CREATE_FAIL';

const REGENERATE = 'redux-ducks/invoice/REGENERATE';
const REGENERATE_SUCCESS = 'redux-ducks/invoice/REGENERATE_SUCCESS';
const REGENERATE_FAIL = 'redux-ducks/invoice/REGENERATE_FAIL';

const DELETE = 'redux-ducks/invoice/DELETE';
const DELETE_SUCCESS = 'redux-ducks/invoice/DELETE_SUCCESS';
const DELETE_FAIL = 'redux-ducks/invoice/DELETE_FAIL';

const DELETE_MULTI = 'redux-ducks/invoice/DELETE_MULTI';
const DELETE_MULTI_SUCCESS = 'redux-ducks/invoice/DELETE_MULTI_SUCCESS';
const DELETE_MULTI_FAIL = 'redux-ducks/invoice/DELETE_MULTI_FAIL';

const DOWNLOAD = 'redux-ducks/invoice/DOWNLOAD';
const DOWNLOAD_SUCCESS = 'redux-ducks/invoice/DOWNLOAD_SUCCESS';
const DOWNLOAD_FAIL = 'redux-ducks/invoice/DOWNLOAD_FAIL';

const SEND = 'redux-ducks/invoice/SEND';
const SEND_SUCCESS = 'redux-ducks/invoice/SEND_SUCCESS';
const SEND_FAIL = 'redux-ducks/invoice/SEND_FAIL';

const MULTIDOWNLOAD = 'redux-ducks/invoice/MULTIDOWNLOAD';
const MULTIDOWNLOAD_SUCCESS = 'redux-ducks/invoice/MULTIDOWNLOAD_SUCCESS';
const MULTIDOWNLOAD_FAIL = 'redux-ducks/invoice/MULTIDOWNLOAD_FAIL';

const CREATE_CREDIT = 'redux-ducks/invoice/CREATE_CREDIT';
const CREATE_CREDIT_SUCCESS = 'redux-ducks/invoice/CREATE_CREDIT_SUCCESS';
const CREATE_CREDIT_FAIL = 'redux-ducks/invoice/CREATE_CREDIT_FAIL';

const LOAD_INFO = 'redux-ducks/invoice/LOAD_INFO';
const LOAD_INFO_SUCCESS = 'redux-ducks/invoice/LOAD_INFO_SUCCESS';
const LOAD_INFO_FAIL = 'redux-ducks/invoice/LOAD_INFO_FAIL';

const CREATE_INFO = 'redux-ducks/invoice/CREATE_INFO';
const CREATE_INFO_SUCCESS = 'redux-ducks/invoice/CREATE_INFO_SUCCESS';
const CREATE_INFO_FAIL = 'redux-ducks/invoice/CREATE_INFO_FAIL';

const UPDATE_INFO = 'redux-ducks/invoice/UPDATE_INFO';
const UPDATE_INFO_SUCCESS = 'redux-ducks/invoice/UPDATE_INFO_SUCCESS';
const UPDATE_INFO_FAIL = 'redux-ducks/invoice/UPDATE_INFO_FAIL';

const DELETE_INFO = 'redux-ducks/invoice/DELETE_INFO';
const DELETE_INFO_SUCCESS = 'redux-ducks/invoice/DELETE_INFO_SUCCESS';
const DELETE_INFO_FAIL = 'redux-ducks/invoice/DELETE_INFO_FAIL';

const UPDATE_LOGO_INFO = 'redux-ducks/invoice/UPDATE_LOGO_INFO';
const UPDATE_LOGO_INFO_SUCCESS = 'redux-ducks/invoice/UPDATE_LOGO_INFO_SUCCESS';
const UPDATE_LOGO_INFO_FAIL = 'redux-ducks/invoice/UPDATE_LOGO_INFO_FAIL';

const DELETE_LOGO_INFO = 'redux-ducks/invoice/DELETE_LOGO_INFO';
const DELETE_LOGO_INFO_SUCCESS = 'redux-ducks/invoice/DELETE_LOGO_INFO_SUCCESS';
const DELETE_LOGO_INFO_FAIL = 'redux-ducks/invoice/DELETE_LOGO_INFO_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  entity: {
    invoices: [],
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    },
  },
  error: null,
};

/**
 * ACTIONS
 */
export const load = (params = '') => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get(`v3/planners/invoices?${params}`),
});

export const sendInvoices = (data) => ({
  types: [SEND, SEND_SUCCESS, SEND_FAIL],
  promise: (client) => client.post('v3/planners/invoices/mail', { data }),
});

export const createInvoice = (data) => ({
  types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
  promise: (client) => client.post('v3/planners/invoices', { data }),
});

export const regenerateInvoice = (id) => ({
  types: [REGENERATE, REGENERATE_SUCCESS, REGENERATE_FAIL],
  promise: (client) => client.post(`v3/planners/invoices/${id}/regenerate`),
  id,
});

/**
 * Delete invoice by id
 * @param {number} id invoiceId
 */
export const deleteInvoice = (id) => ({
  types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
  promise: (client) => client.del(`v3/planners/invoices/${id}`),
  id,
});

/**
 * Remove multiple
 */
export const deleteMultipleInvoices = (data) => ({
  types: [DELETE_MULTI, DELETE_MULTI_SUCCESS, DELETE_MULTI_FAIL],
  promise: (client) => client.del('v3/planners/invoices/delete_multiple', { data }),
  data
});

/**
 * Download multiple
 */
export const multiDownloadInvoice = (data) => ({
  types: [MULTIDOWNLOAD, MULTIDOWNLOAD_SUCCESS, MULTIDOWNLOAD_FAIL],
  promise: (client) => client.post('v3/planners/invoices/download_multiple', { data })
});

/**
 * Download single
 */
export const downloadInvoice = (el) => ({
  types: [DOWNLOAD, DOWNLOAD_SUCCESS, DOWNLOAD_FAIL],
  promise: (client) => client.post(
    `v3/planners/invoices/${el.id}/download`,
    { responseType: 'blob', data: { file_type: el.format } }
  )
});

export const createCreditInvoice = (data) => ({
  types: [CREATE_CREDIT, CREATE_CREDIT_SUCCESS, CREATE_CREDIT_FAIL],
  promise: (client) => client.post(`v3/planners/invoices/${data.id}/create_credit_invoice`, { data })
});

/**
 * Handle load invoices
 * @param {object} params
 */
export const loadInvoices = (params) => (dispatch) => dispatch(load(params));

// Billing info actions
export const loadInfo = () => ({
  types: [LOAD_INFO, LOAD_INFO_SUCCESS, LOAD_INFO_FAIL],
  promise: (client) => client.get('v3/planners/agency_information/billing_infos'),
});
export const createInfo = (data) => ({
  types: [CREATE_INFO, CREATE_INFO_SUCCESS, CREATE_INFO_FAIL],
  promise: (client) => client.post('v3/planners/agency_information/billing_infos', { data }),
});
export const updateInfo = (data) => ({
  types: [UPDATE_INFO, UPDATE_INFO_SUCCESS, UPDATE_INFO_FAIL],
  promise: (client) => client.patch(`v3/planners/agency_information/billing_infos/${data.id}`, { data }),
});
export const deleteInfo = (id) => ({
  types: [DELETE_INFO, DELETE_INFO_SUCCESS, DELETE_INFO_FAIL],
  promise: (client) => client.del(`v3/planners/agency_information/billing_infos/${id}`),
});
export const updateLogoInfo = (data) => ({
  types: [UPDATE_LOGO_INFO, UPDATE_LOGO_INFO_SUCCESS, UPDATE_LOGO_INFO_FAIL],
  promise: (client) => client.patch(`v3/planners/agency_information/billing_infos/${data.id}/logo`, { data }),
});
export const deleteLogoInfo = (id) => ({
  types: [DELETE_LOGO_INFO, DELETE_LOGO_INFO_SUCCESS, DELETE_LOGO_INFO_FAIL],
  promise: (client) => client.del(`v3/planners/agency_information/billing_infos/${id}/logo`),
});

/**
 * MUTATIONS
 */
const deleteSuccess = (action) => (state) => {
  const { invoices } = state.entity;
  const filterdInvoices = invoices.filter((el) => el.id !== action.id);
  return ({
    ...state,
    entity: {
      ...state.entity,
      invoices: [...filterdInvoices]
    }
  });
};

const multiDeleteSuccess = (action) => (state) => {
  const { data } = action;
  const { invoices } = state.entity;
  const filtered = invoices.filter((el) => !data.invoices_ids.includes(el.id));
  return ({
    ...state,
    entity: {
      ...state.entity,
      invoices: [...filtered]
    }
  });
};

const updateRequestOnLoad = (value) => (state) => ({
  ...state,
  loading: value
});

const updateRequestOnLoadSuccess = (value) => (state) => ({
  ...state,
  loading: value,
  loaded: true,
  error: null
});

const loadInvoicesSuccess = (action) => (state) => {
  const { data, meta, included } = action.result;

  const preparedData = data.map((item) => {
    const invoices = item.attributes;
    const recipient = included.find((el) => el.id === item.relationships.recipient.data?.id
        && el.type === item.relationships.recipient.data.type) || { attributes: { title: '' } };
    const sender = included.find((el) => el.id === item.relationships.sender.data?.id
        && el.type === item.relationships.sender.data.type) || { attributes: { title: '' } };
    return {
      ...invoices,
      recipient: { ...recipient.attributes, type: recipient.type },
      sender: { ...sender.attributes, type: sender.type }
    };
  });

  return ({
    ...state,
    entity: {
      invoices: preparedData,
      pagination: {
        page: meta.pagination.page,
        total: meta.pagination.total,
        total_pages: meta.pagination.total_pages
      }
    }
  });
};

const updateRequestOnLoadFail = (action, value) => (state) => ({
  ...state,
  loading: value,
  loaded: value,
  entity: {
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    },
    invoices: [],
  },
  error: action.error
});

const actionsLookup = {
  [LOAD]: (state) => updateRequestOnLoad(true)(state),
  [LOAD_SUCCESS]: (state, action) => flowRight(
    updateRequestOnLoadSuccess(false),
    loadInvoicesSuccess(action),
  )(state),
  [LOAD_FAIL]: (state, action) => updateRequestOnLoadFail(action, false)(state),
  [DELETE_SUCCESS]: (state, action) => deleteSuccess(action)(state),
  [DELETE_MULTI_SUCCESS]: (state, action) => multiDeleteSuccess(action)(state),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}