import isFunction from 'lodash/isFunction';
import flowRight from 'lodash/flowRight';
import { checkItemInArray } from '../../utils/utils';

export const LOAD_FILTERS = 'redux-ducks/filter/LOAD';
export const LOAD_FILTERS_SUCCESS = 'redux-ducks/filter/LOAD_SUCCESS';
export const LOAD_FILTERS_FAIL = 'redux-ducks/filter/LOAD_FAIL';

export const ADD_FILTER = 'redux-ducks/filter/ADD_FILTER';
export const ADD_FILTER_SUCCESS = 'redux-ducks/filter/ADD_FILTER_SUCCESS';
export const ADD_FILTER_FAIL = 'redux-ducks/filter/ADD_FILTER_FAIL';

const UPDATE = 'redux-ducks/filter/UPDATE';
const UPDATE_SUCCESS = 'redux-ducks/filter/UPDATE_SUCCESS';
const UPDATE_FAIL = 'redux-ducks/filter/UPDATE_FAIL';

export const REMOVE_FILTER = 'redux-ducks/filter/REMOVE_FILTER';
export const REMOVE_FILTER_SUCCESS = 'redux-ducks/filter/REMOVE_FILTER_SUCCESS';
export const REMOVE_FILTER_FAIL = 'redux-ducks/filter/REMOVE_FILTER_FAIL';

export const SET_DEFAULT = 'redux-ducks/filter/SET_DEFAULT';

const initialState = {
  loading: false,
  loaded: false,
  entity: {
    worker: [],
    report: [],
    review: [],
    worklog: [],
    organization: [],
    generate_invoice: [],
    invoice: [],
    gig: [],
    agreement: [],
    project: [],
    byId: {},
  },
  error: null,
};

// Actions
/**
 * Load filters from BD
 * @param {string} type
 */
export const loadFilters = (type = 'report') => ({
  types: [LOAD_FILTERS, LOAD_FILTERS_SUCCESS, LOAD_FILTERS_FAIL],
  promise: (client) => client.get(`filters?by_type=${type}`),
  filterType: type,
});

/**
 * Save filter in BD
 * @param {object} data property
 */
export const saveFilter = (data) => ({
  types: [ADD_FILTER, ADD_FILTER_SUCCESS, ADD_FILTER_FAIL],
  promise: (client) => client.post('filters', { data }),
  filterType: data.filter.type,
});

const update = (data) => ({
  types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
  promise: (client) => client.patch(`filters/${data.id}`, { data }),
  data,
});

/**
 * Delete filter in BD, by filter id
 */
export const removeFilter = (params) => ({
  types: [REMOVE_FILTER, REMOVE_FILTER_SUCCESS, REMOVE_FILTER_FAIL],
  promise: (client) => client.del(`filters/${params.id}`),
  filter: params,
});

/**
 * Set default state
 */
export const setDefault = () => ({ type: SET_DEFAULT });

/**
 * Update filter
 * @param {object} params
 */
export const updateFilter = (params) => (dispatch) => dispatch(update(params));

//
// Mutations
//
const updateRequestOnLoad = (value) => (state) => ({
  ...state,
  loading: value,
});

const updateRequestOnLoadSuccess = (value) => (state) => ({
  ...state,
  loading: value,
  loaded: true,
  error: null,
});

/**
 * Save data in store
 * @param {object} action response from API
 */
const updateEntityOnLoad = (action) => (state) => {
  const {
    byId,
  } = state.entity;
  let updId = [];
  const normalize = {};

  action.result.filters.forEach((item) => {
    if (!checkItemInArray(updId, item.id)) {
      updId = [...updId, item.id];
    }

    normalize[item.id] = {
      ...item,
      type: action.filterType,
    };
  });

  return ({
    ...state,
    entity: {
      ...state.entity,
      [action.filterType]: updId,
      byId: {
        ...byId,
        ...normalize,
      }
    },
  });
};

/**
 * Add property to store
 * @param {Object} action response from API
 */
const addFilterSuccess = (action) => (state) => {
  const { filter } = action.result;
  return ({
    ...state,
    entity: {
      ...state.entity,
      [action.filterType]: [
        ...state.entity[action.filterType],
        filter.id,
      ],
      byId: {
        ...state.entity.byId,
        [filter.id]: {
          ...filter,
          type: action.filterType,
        }
      }
    },
  });
};

const removeFilterSuccess = (action) => (state) => {
  const { byId } = state.entity;
  const { filter } = action;

  const filteredAllIds = state.entity[filter.type].filter((id) => id !== filter.id);

  delete byId[filter.id];

  return ({
    ...state,
    entity: {
      ...state.entity,
      [filter.type]: filteredAllIds,
      byId,
    },
  });
};

const updateRequestOnLoadFail = (action, value) => (state) => ({
  ...state,
  loading: value,
  loaded: value,
  entity: {
    worker: [],
    report: [],
    review: [],
    worklog: [],
    agreement: [],
    organization: [],
    generate_invoice: [],
    invoice: [],
    gig: [],
    project: [],
    byId: {},
  },
  error: action.error,
});

const updateSuccess = (action) => (state) => {
  const { byId } = state.entity;
  const { filter } = action.result;

  return ({
    ...state,
    entity: {
      ...state.entity,
      byId: {
        ...byId,
        [filter.id]: {
          ...filter,
          type: action.data.type,
        },
      }
    },
  });
};

const setDefaultSuccess = () => initialState;

const actionsLookup = {
  [LOAD_FILTERS]: (state) => updateRequestOnLoad(true)(state),
  [LOAD_FILTERS_SUCCESS]: (state, action) => flowRight(
    updateRequestOnLoadSuccess(false),
    updateEntityOnLoad(action),
  )(state),
  [LOAD_FILTERS_FAIL]: (state, action) => updateRequestOnLoadFail(action, false)(state),
  [UPDATE_SUCCESS]: (state, action) => updateSuccess(action)(state),
  [ADD_FILTER_SUCCESS]: (state, action) => addFilterSuccess(action)(state),
  [REMOVE_FILTER_SUCCESS]: (state, action) => removeFilterSuccess(action)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}
