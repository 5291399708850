import isFunction from 'lodash/isFunction';
import flowRight from 'lodash/flowRight';
import globalConst from '../../constants';
import { checkItemInArray } from '../../utils/utils';

const LOAD = 'redux-ducks/review/LOAD';
const LOAD_SUCCESS = 'redux-ducks/review/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-ducks/review/LOAD_FAIL';

const UPDATE = 'redux-ducks/review/UPDATE';
const UPDATE_SUCCESS = 'redux-ducks/review/UPDATE_SUCCESS';
const UPDATE_FAIL = 'redux-ducks/review/UPDATE_FAIL';

const SET_REVIEW = 'redux-duck/review/SET_REVIEW';
const SET_REVIEW_SUCCESS = 'redux-duck/review/SET_REVIEW_SUCCESS';
const SET_REVIEW_FAIL = 'redux-duck/review/SET_REVIEW_FAIL';

const SET_DEFAULT = 'redux-ducks/review/SET_DEFAULT';

const initialState = {
  loading: false,
  loaded: false,
  entity: {
    allIds: [],
    byId: {},
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    },
  },
  error: null,
};

/**
 * ACTIONS
 */
const load = (params) => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get('v3/planners/reviews', { params }),
  params,
});

const bulkUpdate = (data) => ({
  types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
  promise: (client) => client.patch('v3/planners/reviews/bulk_update', { data }),
});

export const updateWorkerReview = (data) => ({
  types: [SET_REVIEW, SET_REVIEW_SUCCESS, SET_REVIEW_FAIL],
  promise: (client) => client.post('v3/planners/reviews', { data }),
});

/**
 * Set default state
 */
export const setDefault = () => ({ type: SET_DEFAULT });

/**
 * Handle load reviews
 * @param {object} params
 */
export const loadReviews = (params = {}) => (dispatch) => dispatch(load(params));

export const updateReviews = (params = {}) => (dispatch) => dispatch(bulkUpdate(params));

/**
 * MUTATIONS
 */
const updateRequestOnLoad = (value) => (state) => ({
  ...state,
  loading: value
});

const updateRequestOnLoadSuccess = (value) => (state) => ({
  ...state,
  loading: value,
  loaded: true,
  error: null
});

const loadReviewsSuccess = (action) => (state) => {
  const { data, included, meta } = action.result;
  let updId = [];
  const normalize = {};

  data.forEach((item) => {
    const review = item.attributes;
    if (!checkItemInArray(updId, review.id)) {
      updId = [...updId, review.id];
    }

    const workerId = item.relationships.worker.data.id;
    const workerData = included
      .find((includedItem) => includedItem.type === 'worker_index' && includedItem.id === workerId)
    || { attributes: null };

    const roleIds = workerData.relationships?.roles?.data.map((roleItem) => roleItem.id) || [];
    const rolesData = included
      .filter((includedItem) => includedItem.type === 'role' && roleIds.indexOf(includedItem.id) !== -1)
      .map((roleData) => roleData.attributes.title);

    normalize[review.id] = {
      ...review,
      worker: {
        ...workerData.attributes,
        initial: `${workerData.attributes?.first_name[0] || ''}${workerData.attributes?.last_name[0] || ''}`
      },
      roles: rolesData,
    };
  });

  return ({
    ...state,
    entity: {
      allIds: updId,
      byId: normalize,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      }
    }
  });
};

const updateRequestOnLoadFail = (action, value) => (state) => ({
  ...state,
  loading: value,
  loaded: value,
  entity: {
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    },
    allIds: [],
    byId: {},
  },
  error: action.error
});

const setDefaultSuccess = () => initialState;

const actionsLookup = {
  [LOAD]: (state) => updateRequestOnLoad(true)(state),
  [LOAD_SUCCESS]: (state, action) => flowRight(
    updateRequestOnLoadSuccess(false),
    loadReviewsSuccess(action),
  )(state),
  [LOAD_FAIL]: (state, action) => updateRequestOnLoadFail(action, false)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}