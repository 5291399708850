import isFunction from 'lodash/isFunction';
import flowRight from 'lodash/flowRight';
import globalConst from '../../constants';

const LOAD = 'redux-ducks/notifications/LOAD';
const LOAD_SUCCESS = 'redux-ducks/notifications/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-ducks/notifications/LOAD_FAIL';

const DELETE = 'redux-ducks/notifications/DELETE';
const DELETE_SUCCESS = 'redux-ducks/notifications/DELETE_SUCCESS';
const DELETE_FAIL = 'redux-ducks/notifications/DELETE_FAIL';

const ALL_READ = 'redux-ducks/notifications/ALL_READ';
const ALL_READ_SUCCESS = 'redux-ducks/notifications/ALL_READ_SUCCESS';
const ALL_READ_FAIL = 'redux-ducks/notifications/ALL_READ_FAIL';

const ALL_READ_FOR_ENTITY = 'redux-ducks/notifications/ALL_READ_FOR_ENTITY';
const ALL_READ_FOR_ENTITY_SUCCESS = 'redux-ducks/notifications/ALL_READ_FOR_ENTITY_SUCCESS';
const ALL_READ_FOR_ENTITY_FAIL = 'redux-ducks/notifications/ALL_READ_FOR_ENTITY_FAIL';

const OPEN_POPUP = 'redux-ducks/notifications/OPEN_POPUP';

const SET_DEFAULT = 'redux-ducks/notifications/SET_DEFAULT';

const initialState = {
  loading: false,
  loaded: false,
  entity: {
    allIds: [],
    byId: {},
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    }
  },
  greetingPopup: false,
  error: null,
};

// Actions
/**
 * Load notifications from BD
 * @param {string} url
 */
export const loadNotifications = (paramsUrl) => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get(`v3/planners/notifications?${paramsUrl}`),
});

/**
 * Delete notification from BD
 * @param {string} url
 */
export const deleteNotification = (id) => ({
  types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
  promise: (client) => client.del(`v3/planners/notifications/${id}`),
});

export const markAsReadForEntity = (paramsUrl) => ({
  types: [ALL_READ_FOR_ENTITY, ALL_READ_FOR_ENTITY_SUCCESS, ALL_READ_FOR_ENTITY_FAIL],
  promise: (client) => client.post(`v3/planners/notifications/mark_as_read?${paramsUrl}`),
});

/**
 * Mark ad read
 */
export const markAsRead = () => ({
  types: [ALL_READ, ALL_READ_SUCCESS, ALL_READ_FAIL],
  promise: (client) => client.post('v3/planners/notifications/mark_as_read'),
});

/**
 * Set popup state
 * @param {string} name Popup name
 */
export const setPopupState = (name) => ({
  type: OPEN_POPUP,
  name,
});

/**
 * Set default state
 */
export const setDefault = () => ({ type: SET_DEFAULT });

//
// Mutations
//
const updateRequestOnLoad = (value) => (state) => ({
  ...state,
  loading: value,
});

const updateRequestOnLoadSuccess = (value) => (state) => ({
  ...state,
  loading: value,
  loaded: true,
  error: null,
});

/**
 * Save data in store
 * @param {object} action response from API
 */
const updateEntityOnLoad = (action) => (state) => {
  const { data, meta } = action.result;

  const updId = data.map((item) => +item.id);
  const normalize = {};
  data.forEach((item) => {
    normalize[item.id] = {
      ...item.attributes,
    };
  });

  return ({
    ...state,
    entity: {
      allIds: updId,
      byId: normalize,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      },
    },
  });
};

const updateRequestOnLoadFail = (action, value) => (state) => ({
  ...state,
  loading: value,
  loaded: value,
  entity: {
    allIds: [],
    byId: {},
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    }
  },
  error: action.error,
});

const markAsReadSuccess = () => (state) => ({
  ...state,
  entity: {
    allIds: [],
    byId: {},
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    }
  },
});

const setPopupStateSuccess = (action) => (state) => {
  const namePopup = action.name;
  const prevStatePopup = state[action.name];

  return ({
    ...state,
    [namePopup]: !prevStatePopup,
  });
};

const setDefaultSuccess = () => initialState;

const actionsLookup = {
  [LOAD]: (state) => updateRequestOnLoad(true)(state),
  [LOAD_SUCCESS]: (state, action) => flowRight(
    updateRequestOnLoadSuccess(false),
    updateEntityOnLoad(action),
  )(state),
  [LOAD_FAIL]: (state, action) => updateRequestOnLoadFail(action, false)(state),
  [ALL_READ_SUCCESS]: (state) => markAsReadSuccess()(state),
  [OPEN_POPUP]: (state, action) => setPopupStateSuccess(action)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}
