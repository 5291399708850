import isFunction from 'lodash/isFunction';
import flowRight from 'lodash/flowRight';
import isEmpty from 'lodash/isEmpty';
import { getWorkerTemplate } from '../../utils/utils';
import { serializeParamsToQueryString } from '../../utils/serialize';
import globalConst from '../../constants';

export const LOAD = 'redux-ducks/worker/LOAD';
export const LOAD_SUCCESS = 'redux-ducks/worker/LOAD_SUCCESS';
export const LOAD_FAIL = 'redux-ducks/worker/LOAD_FAIL';
export const INCREASE_COUNT = 'redux-ducks/worker/INCREASE_COUNT';

const LOAD_BLOCKED = 'redux-ducks/worker/LOAD_BLOCKED';
const LOAD_BLOCKED_SUCCESS = 'redux-ducks/worker/LOAD_BLOCKED_SUCCESS';
const LOAD_BLOCKED_FAIL = 'redux-ducks/worker/LOAD_BLOCKED_FAIL';
const INCREASE_BLOCKED_COUNT = 'redux-ducks/worker/INCREASE_BLOCKED_COUNT';

const LOAD_INACTIVE = 'redux-ducks/worker/LOAD_INACTIVE';
const LOAD_INACTIVE_SUCCESS = 'redux-ducks/worker/LOAD_INACTIVE_SUCCESS';
const LOAD_INACTIVE_FAIL = 'redux-ducks/worker/LOAD_INACTIVE_FAIL';
const INCREASE_INACTIVE_COUNT = 'redux-ducks/worker/INCREASE_INACTIVE_COUNT';

const LOAD_SOURCED = 'redux-ducks/worker/LOAD_SOURCED';
const LOAD_SOURCED_SUCCESS = 'redux-ducks/worker/LOAD_SOURCED_SUCCESS';
const LOAD_SOURCED_FAIL = 'redux-ducks/worker/LOAD_SOURCED_FAIL';
const INCREASE_SOURCED_COUNT = 'redux-ducks/worker/INCREASE_SOURCED_COUNT';

const LOAD_CREATED = 'redux-ducks/worker/LOAD_CREATED';
const LOAD_CREATED_SUCCESS = 'redux-ducks/worker/LOAD_CREATED_SUCCESS';
const LOAD_CREATED_FAIL = 'redux-ducks/worker/LOAD_CREATED_FAIL';

export const LOAD_WORKER = 'redux-ducks/worker/LOAD_WORKER';
export const LOAD_WORKER_SUCCESS = 'redux-ducks/worker/LOAD_WORKER_SUCCESS';
export const LOAD_WORKER_FAIL = 'redux-ducks/worker/LOAD_WORKER_FAIL';

export const GET_WORKER = 'redux-ducks/worker/GET_WORKER';
export const GET_WORKER_SUCCESS = 'redux-ducks/worker/GET_WORKER_SUCCESS';
export const GET_WORKER_FAIL = 'redux-ducks/worker/GET_WORKER_FAIL';
export const INCREASE_CURRENT_COUNT = 'redux-ducks/worker/INCREASE_CURRENT_COUNT';

const UPDATE_CURRENT_WORKER = 'redux-ducks/worker/UPDATE_CURRENT_WORKER';
const UPDATE_CURRENT_WORKER_SUCCESS = 'redux-ducks/worker/UPDATE_CURRENT_WORKER_SUCCESS';
const UPDATE_CURRENT_WORKER_FAIL = 'redux-ducks/worker/UPDATE_CURRENT_WORKER_FAIL';

const REVIEW_CURRENT_WORKER = 'redux-ducks/worker/REVIEW_CURRENT_WORKER';
const REVIEW_CURRENT_WORKER_SUCCESS = 'redux-ducks/worker/REVIEW_CURRENT_WORKER_SUCCESS';
const REVIEW_CURRENT_WORKER_FAIL = 'redux-ducks/worker/REVIEW_CURRENT_WORKER_FAIL';

export const CLEAR_CURRENT_WORKER = 'redux-ducks/worker/CLEAR_CURRENT_WORKER';

export const UPDATE = 'redux-ducks/worker/UPDATE';
export const UPDATE_SUCCESS = 'redux-ducks/worker/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'redux-ducks/worker/UPDATE_FAIL';

const INVITE = 'redux-ducks/worker/INVITE';
const INVITE_SUCCESS = 'redux-ducks/worker/INVITE_SUCCESS';
const INVITE_FAIL = 'redux-ducks/worker/INVITE_FAIL';

export const UPLOAD = 'redux-ducks/worker/UPLOAD';
export const UPLOAD_SUCCESS = 'redux-ducks/worker/UPLOAD_SUCCESS';
export const UPLOAD_FAIL = 'redux-ducks/worker/UPLOAD_FAIL';
export const REMOVE_PHOTO = 'redux-ducks/worker/REMOVE_PHOTO';
export const REMOVE_PHOTO_SUCCESS = 'redux-ducks/worker/REMOVE_PHOTO_SUCCESS';
export const REMOVE_PHOTO_FAIL = 'redux-ducks/worker/REMOVE_PHOTO_FAIL';

export const UPLOAD_DOCUMENT = 'redux-ducks/worker/UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_SUCCESS = 'redux-ducks/worker/UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAIL = 'redux-ducks/worker/UPLOAD_DOCUMENT_FAIL';
export const REMOVE_DOCUMENT = 'redux-ducks/worker/REMOVE_DOCUMENT';
export const REMOVE_DOCUMENT_SUCCESS = 'redux-ducks/worker/REMOVE_DOCUMENT_SUCCESS';
export const REMOVE_DOCUMENT_FAIL = 'redux-ducks/worker/REMOVE_DOCUMENT_FAIL';
export const DOWNLOAD_DOCUMENT = 'redux-ducks/worker/DOWNLOAD_DOCUMENT';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'redux-ducks/worker/DOWNLOAD_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_FAIL = 'redux-ducks/worker/DOWNLOAD_DOCUMENT_FAIL';

const ONBOARD_WORKER = 'redux-ducks/worker/ONBOARD_WORKER';
const ONBOARD_WORKER_SUCCESS = 'redux-ducks/worker/ONBOARD_WORKER_SUCCESS';
const ONBOARD_WORKER_FAIL = 'redux-ducks/worker/ONBOARD_WORKER_FAIL';

// CRUD for locations
const SAVE_LOC = 'redux-ducks/worker/SAVE_LOC';
const SAVE_LOC_SUCCESS = 'redux-ducks/worker/SAVE_LOC_SUCCESS';
const SAVE_LOC_FAIL = 'redux-ducks/worker/SAVE_LOC_FAIL';
const UPDATE_LOC = 'redux-ducks/worker/UPDATE_LOC';
const UPDATE_LOC_SUCCESS = 'redux-ducks/worker/UPDATE_LOC_SUCCESS';
const UPDATE_LOC_FAIL = 'redux-ducks/worker/UPDATE_LOC_FAIL';
const DELETE_LOC = 'redux-ducks/worker/DELETE_LOC';
const DELETE_LOC_SUCCESS = 'redux-ducks/worker/DELETE_LOC_SUCCESS';
const DELETE_LOC_FAIL = 'redux-ducks/worker/DELETE_LOC_FAIL';

const EXPORT_WORKERS = 'redux-ducks/worker/EXPORT_WORKERS';
const EXPORT_WORKERS_SUCCESS = 'redux-ducks/worker/EXPORT_WORKERS_SUCCESS';
const EXPORT_WORKERS_FAIL = 'redux-ducks/worker/EXPORT_WORKERS_FAIL';

const EXPORT_WORKERS_ACTIVITY = 'redux-ducks/worker/EXPORT_WORKERS_ACTIVITY';
const EXPORT_WORKERS_ACTIVITY_SUCCESS = 'redux-ducks/worker/EXPORT_WORKERS_ACTIVITY_SUCCESS';
const EXPORT_WORKERS_ACTIVITY_FAIL = 'redux-ducks/worker/EXPORT_WORKERS_ACTIVITY_FAIL';

const EXPORT_WORKERS_SCHEDULE = 'redux-ducks/worker/EXPORT_WORKERS_SCHEDULE';
const EXPORT_WORKERS_SCHEDULE_SUCCESS = 'redux-ducks/worker/EXPORT_WORKERS_SCHEDULE_SUCCESS';
const EXPORT_WORKERS_SCHEDULE_FAIL = 'redux-ducks/worker/EXPORT_WORKERS_SCHEDULE_FAIL';

const DELETE = 'redux-ducks/worker/DELETE';
const DELETE_SUCCESS = 'redux-ducks/worker/DELETE_SUCCESS';
const DELETE_FAIL = 'redux-ducks/worker/DELETE_FAIL';

export const INVITES_WORKERS = 'redux-ducks/worker/INVITES_WORKERS';
export const INVITES_WORKERS_SUCCESS = 'redux-ducks/worker/INVITES_WORKERS_SUCCESS';
export const INVITES_WORKERS_FAIL = 'redux-ducks/worker/INVITES_WORKERS_FAIL';

export const SET_DEFAULT = 'redux-ducks/worker/SET_DEFAULT';

const CREATE_WORKER = 'redux-duck/worker/CREATE_WORKER';
const CREATE_WORKER_SUCCESS = 'redux-duck/worker/CREATE_WORKER_SUCCESS';
const CREATE_WORKER_FAIL = 'redux-duck/worker/CREATE_WORKER_FAIL';

const INVITE_WORKER = 'redux-duck/worker/INVITE_WORKER';
const INVITE_WORKER_SUCCESS = 'redux-duck/worker/INVITE_WORKER_SUCCESS';
const INVITE_WORKER_FAIL = 'redux-duck/worker/INVITE_WORKER_FAIL';

const IMPORT_WORKERS = 'redux-duck/worker/IMPORT_WORKERS';
const IMPORT_WORKERS_SUCCESS = 'redux-duck/worker/IMPORT_WORKERS_SUCCESS';
const IMPORT_WORKERS_FAIL = 'redux-duck/worker/IMPORT_WORKERS_FAIL';

const ENRICH_WORKERS = 'redux-duck/worker/ENRICH_WORKERS';
const ENRICH_WORKERS_SUCCESS = 'redux-duck/worker/ENRICH_WORKERS_SUCCESS';
const ENRICH_WORKERS_FAIL = 'redux-duck/worker/ENRICH_WORKERS_FAIL';

const REMOVE_IMPORT_WORKER = 'redux-duck/worker/REMOVE_IMPORT_WORKER';
const ADD_IMPORT_WORKER = 'redux-duck/worker/ADD_IMPORT_WORKER';
const CLEAR_IMPORT = 'redux-duck/worker/CLEAR_IMPORT';

const GENERATE_URL = 'redux-duck/worker/GENERATE_URL';
const GENERATE_URL_SUCCESS = 'redux-duck/worker/GENERATE_URL_SUCCESS';
const GENERATE_URL_FAIL = 'redux-duck/worker/GENERATE_URL_FAIL';

const IMPORT_TEMPLATE = 'redux-duck/worker/IMPORT_TEMPLATE';
const IMPORT_TEMPLATE_SUCCESS = 'redux-duck/worker/IMPORT_TEMPLATE_SUCCESS';
const IMPORT_TEMPLATE_FAIL = 'redux-duck/worker/IMPORT_TEMPLATE_FAIL';

const ADD_AVAILABILITY = 'redux-duck/worker/ADD_AVAILABILITY';
const ADD_AVAILABILITY_SUCCESS = 'redux-duck/worker/ADD_AVAILABILITY_SUCCESS';
const ADD_AVAILABILITY_FAIL = 'redux-duck/worker/ADD_AVAILABILITY_FAIL';

const DELETE_AVAILABILITY = 'redux-duck/worker/DELETE_AVAILABILITY';
const DELETE_AVAILABILITY_SUCCESS = 'redux-duck/worker/DELETE_AVAILABILITY_SUCCESS';
const DELETE_AVAILABILITY_FAIL = 'redux-duck/worker/DELETE_AVAILABILITY_FAIL';

const UPDATE_AVAILABILITY = 'redux-duck/worker/UPDATE_AVAILABILITY';
const UPDATE_AVAILABILITY_SUCCESS = 'redux-duck/worker/UPDATE_AVAILABILITY_SUCCESS';
const UPDATE_AVAILABILITY_FAIL = 'redux-duck/worker/UPDATE_AVAILABILITY_FAIL';

const LOAD_UNAVAILABILITIES = 'redux-duck/worker/LOAD_UNAVAILABILITIES';
const LOAD_UNAVAILABILITIES_SUCCESS = 'redux-duck/worker/LOAD_UNAVAILABILITIES_SUCCESS';
const LOAD_UNAVAILABILITIES_FAIL = 'redux-duck/worker/LOAD_UNAVAILABILITIES_FAIL';

const LOAD_UNAVAILABILITY = 'redux-duck/worker/LOAD_UNAVAILABILITY';
const LOAD_UNAVAILABILITY_SUCCESS = 'redux-duck/worker/LOAD_UNAVAILABILITY_SUCCESS';
const LOAD_UNAVAILABILITY_FAIL = 'redux-duck/worker/LOAD_UNAVAILABILITY_FAIL';

const CREATE_UNAVAILABILITY = 'redux-duck/worker/CREATE_UNAVAILABILITY';
const CREATE_UNAVAILABILITY_SUCCESS = 'redux-duck/worker/CREATE_UNAVAILABILITY_SUCCESS';
const CREATE_UNAVAILABILITY_FAIL = 'redux-duck/worker/CREATE_UNAVAILABILITY_FAIL';

const UPDATE_UNAVAILABILITY = 'redux-duck/worker/UPDATE_UNAVAILABILITY';
const UPDATE_UNAVAILABILITY_SUCCESS = 'redux-duck/worker/UPDATE_UNAVAILABILITY_SUCCESS';
const UPDATE_UNAVAILABILITY_FAIL = 'redux-duck/worker/UPDATE_UNAVAILABILITY_FAIL';

const DELETE_UNAVAILABILITY = 'redux-duck/worker/DELETE_UNAVAILABILITY';
const DELETE_UNAVAILABILITY_SUCCESS = 'redux-duck/worker/DELETE_UNAVAILABILITY_SUCCESS';
const DELETE_UNAVAILABILITY_FAIL = 'redux-duck/worker/DELETE_UNAVAILABILITY_FAIL';

const LOAD_BILLING_INFO = 'redux-duck/worker/LOAD_BILLING_INFO';
const LOAD_BILLING_INFO_SUCCESS = 'redux-duck/worker/LOAD_BILLING_INFO_SUCCESS';
const LOAD_BILLING_INFO_FAIL = 'redux-duck/worker/LOAD_BILLING_INFO_FAIL';

const UPDATE_BILLING_INFO = 'redux-duck/worker/UPDATE_BILLING_INFO';
const UPDATE_BILLING_INFO_SUCCESS = 'redux-duck/worker/UPDATE_BILLING_INFO_SUCCESS';
const UPDATE_BILLING_INFO_FAIL = 'redux-duck/worker/UPDATE_BILLING_INFO_FAIL';

const LOAD_PASSWORD = 'redux-duck/worker/LOAD_PASSWORD';
const LOAD_PASSWORD_SUCCESS = 'redux-duck/worker/LOAD_PASSWORD_SUCCESS';
const LOAD_PASSWORD_FAIL = 'redux-duck/worker/LOAD_PASSWORD_FAIL';

const SAVE_PASSWORD = 'redux-duck/worker/SAVE_PASSWORD';
const SAVE_PASSWORD_SUCCESS = 'redux-duck/worker/SAVE_PASSWORD_SUCCESS';
const SAVE_PASSWORD_FAIL = 'redux-duck/worker/SAVE_PASSWORD_FAIL';

const RESET_PASSWORD = 'redux-duck/worker/RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'redux-duck/worker/RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAIL = 'redux-duck/worker/RESET_PASSWORD_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  isPasswordExist: false,
  workersList: [],
  entity: {
    loading: false,
    current: {
      location: [],
    },
    currentRequest: 0,
    allIds: [],
    byId: {},
    byRoleId: {},
    pagination: {
      page: 1,
      total: 0,
      total_pages: 1,
    },
  },
  blocked: {
    allIds: [],
    byId: {},
    byRoleId: {},
    pagination: {
      page: 1,
      total: 0,
      total_pages: 1,
    },
    countRequest: 0,
  },
  inactive: {
    allIds: [],
    byId: {},
    byRoleId: {},
    pagination: {
      page: 1,
      total: 0,
      total_pages: 1,
    },
    countRequest: 0,
  },
  sourced: {
    allIds: [],
    byId: {},
    byRoleId: {},
    pagination: {
      page: 1,
      total: 0,
      total_pages: 1,
    },
    countRequest: 0,
  },
  import: {
    file: '',
    successful: 0,
    allIds: [],
    byId: {},
  },
  billingInfo: {},
  error: {},
  search: '',
  countRequest: 0,
};

/**
 * Load list of workers
 * @param {object} params filter, includes
 * @param {number} count request count
 */
export const load = (paramsUrl, count) => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get(`v3/planners/workers?${paramsUrl}`),
  count,
});

export const loadBlocked = (query, count) => ({
  types: [LOAD_BLOCKED, LOAD_BLOCKED_SUCCESS, LOAD_BLOCKED_FAIL],
  promise: (client) => client.get(`v3/planners/workers/blocked?${query}`),
  count,
});

export const loadInactive = (query, count) => ({
  types: [LOAD_INACTIVE, LOAD_INACTIVE_SUCCESS, LOAD_INACTIVE_FAIL],
  promise: (client) => client.get(`v3/planners/workers/inactive?${query}`),
  count,
});

export const loadSourced = (query, count) => ({
  types: [LOAD_SOURCED, LOAD_SOURCED_SUCCESS, LOAD_SOURCED_FAIL],
  promise: (client) => client.get(`v3/planners/workers/sourced?${query}`),
  count,
});

/**
 * Load just created workers
 * @param {string} paramsUrl filter
 */
export const loadAllWorkers = () => ({
  types: [LOAD_CREATED, LOAD_CREATED_SUCCESS, LOAD_CREATED_FAIL],
  promise: (client) => client.get('v3/planners/workers/all'),
});

/**
 * Get worker by id
 * @param {number} id
 * @param {string} url Query
 */
const loadById = (id, url, count) => ({
  types: [LOAD_WORKER, LOAD_WORKER_SUCCESS, LOAD_WORKER_FAIL],
  promise: (client) => client.get(`v3/planners/workers/${id}?${url}`),
  count,
});

export const updateCurrentWorkerById = (id) => ({
  types: [UPDATE_CURRENT_WORKER, UPDATE_CURRENT_WORKER_SUCCESS, UPDATE_CURRENT_WORKER_FAIL],
  promise: (client) => client.get(`v3/planners/workers/${id}`),
});

export const getReviewHistory = (id) => ({
  types: [REVIEW_CURRENT_WORKER, REVIEW_CURRENT_WORKER_SUCCESS, REVIEW_CURRENT_WORKER_FAIL],
  promise: (client) => client.get(`v3/planners/workers/${id}?include=reviews`),
});

/**
 * Get worker by id
 * @param {number} id
 * @param {string} url Query
 */
const loadAvailabilityById = (id, url, count) => ({
  types: [LOAD_WORKER, LOAD_WORKER_SUCCESS, LOAD_WORKER_FAIL],
  promise: (client) => client.get(`v3/planners/workers/${id}/availability?${url}`),
  count,
});

/**
 * Create worker request
 * @param {object} data
 */
export const create = (data) => ({
  types: [CREATE_WORKER, CREATE_WORKER_SUCCESS, CREATE_WORKER_FAIL],
  promise: (client) => client.post('v3/planners/workers', { data })
});

/**
 * Delete worker by id
 * @param {number} id workerId
 */
export const deleteWorker = (id) => ({
  types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
  promise: (client) => client.del(`v3/planners/workers/${id}`),
  id,
});

/**
 * Load unavailabilities
 */
export const loadUnavailabilities = () => ({
  types: [LOAD_UNAVAILABILITIES, LOAD_UNAVAILABILITIES_SUCCESS, LOAD_UNAVAILABILITIES_FAIL],
  promise: (client) => client.get('v3/planners/unavailabilities')
});
/**
 * Load unavailability by id
 */
export const loadUnavailabilityById = (id) => ({
  types: [LOAD_UNAVAILABILITY, LOAD_UNAVAILABILITY_SUCCESS, LOAD_UNAVAILABILITY_FAIL],
  promise: (client) => client.get(`v3/planners/unavailabilities/${id}`)
});

/**
 * Create unavailability
 */
export const createUnavailability = (data) => ({
  types: [CREATE_UNAVAILABILITY, CREATE_UNAVAILABILITY_SUCCESS, CREATE_UNAVAILABILITY_FAIL],
  promise: (client) => client.post('v3/planners/unavailabilities', { data })
});
/**
 * Update unavailability
 */
export const updateUnavailability = (data) => ({
  types: [UPDATE_UNAVAILABILITY, UPDATE_UNAVAILABILITY_SUCCESS, UPDATE_UNAVAILABILITY_FAIL],
  promise: (client) => client.patch(`v3/planners/unavailabilities/${data.id}`, { data })
});
/**
 * Delete unavailability by id
 */
export const deleteUnavailability = (id) => ({
  types: [DELETE_UNAVAILABILITY, DELETE_UNAVAILABILITY_SUCCESS, DELETE_UNAVAILABILITY_FAIL],
  promise: (client) => client.del(`v3/planners/unavailabilities/${id}`)
});

/**
 * Add worker to imports
 */
export const addToImportSlot = (worker) => ({
  type: ADD_IMPORT_WORKER,
  result: worker,
});

/**
 * Remove worker from imports
 * @param {string} id
 */
export const removeImportById = (id) => ({
  type: REMOVE_IMPORT_WORKER,
  id,
});

/**
 * Clear import shift results
 */
export const clearImport = () => ({
  type: CLEAR_IMPORT,
});

/**
 * Set to default state
 */
export const setDefault = () => ({
  type: SET_DEFAULT,
});

/**
 * Clear current worker
 */
export const clearCurrentWorker = () => ({
  type: CLEAR_CURRENT_WORKER,
});

/**
 * Update current worker
 * @param {object} data
 */
export const updateWorker = (data) => {
  const includes = 'roles,custom_worker_attributes,custom_worker_attributes.custom_attribute';
  const listInclude = 'favorite_organizations,blacklist_organizations';
  const url = `include=${includes},${listInclude}`;
  return ({
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: (client) => client.patch(`v3/planners/workers/${data.worker.id}?${url}`, { data }),
  });
};

/**
 * Add current worker availability
 * @param {object} data
 */

export const addWorkerAvailability = (data) => ({
  types: [ADD_AVAILABILITY, ADD_AVAILABILITY_SUCCESS, ADD_AVAILABILITY_FAIL],
  promise: (client) => client.post(`v3/planners/workers/${data.id}/time_offs`, { data })
});

/**
 * Delete current worker availability
 * @param {object} data
 */

export const deleteWorkerAvailability = (data) => ({
  types: [DELETE_AVAILABILITY, DELETE_AVAILABILITY_SUCCESS, DELETE_AVAILABILITY_FAIL],
  promise: (client) => client.del(`v3/planners/workers/${data.workerId}/time_offs/${data.timeId}`, { data })
});

/**
 * Update current worker availability
 * @param {object} data
 */

export const updateWorkerAvailability = (data) => ({
  types: [UPDATE_AVAILABILITY, UPDATE_AVAILABILITY_SUCCESS, UPDATE_AVAILABILITY_FAIL],
  promise: (client) => client.patch(`v3/planners/workers/${data.workerId}/time_offs/${data.eventId}`, { data })
});

/**
 * Save location
 * @param {object} data
 */
export const saveLocation = (data) => ({
  types: [SAVE_LOC, SAVE_LOC_SUCCESS, SAVE_LOC_FAIL],
  promise: (client) => client.post(`v3/planners/workers/${data.worker_id}/locations`, { data }),
  data,
});

/**
 * Update location by id,
 * @param {object} data
 */
export const updateLocation = (data) => ({
  types: [UPDATE_LOC, UPDATE_LOC_SUCCESS, UPDATE_LOC_FAIL],
  promise: (client) => client.patch(`v3/planners/workers/${data.workerId}/locations/${data.id}`, { data }),
  data,
});

/**
 * Remove location
 * @param {*} id
 * @param {*} orgId
 */
export const deleteLocation = (id, workerId = '') => {
  const params = {
    locationId: id,
    workerId,
  };

  return {
    types: [DELETE_LOC, DELETE_LOC_SUCCESS, DELETE_LOC_FAIL],
    promise: (client) => client.del(`v3/planners/workers/${workerId}/locations/${id}`),
    params
  };
};

/**
 * Upload photo
 * @param {object} data
 */
export const uploadPhoto = (data) => ({
  types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL],
  promise: (client) => client.patch(`workers/${data.id}/photo`, { data })
});

/**
 * Remove photo
 * @param {number} id current worker id
 */
export const removePhoto = (id) => ({
  types: [REMOVE_PHOTO, REMOVE_PHOTO_SUCCESS, REMOVE_PHOTO_FAIL],
  promise: (client) => client.del(`workers/${id}/photo`)
});

/**
 * Upload document
 * @param {object} data
 */
export const uploadDocument = (data) => ({
  types: [UPLOAD_DOCUMENT, UPLOAD_DOCUMENT_SUCCESS, UPLOAD_DOCUMENT_FAIL],
  promise: (client) => client.patch(`v3/planners/workers/${data.id}/document?include=documents`, { data })
});

/**
 * Remove photo
 * @param {object} data
 */
export const removeDocument = (data) => ({
  types: [REMOVE_DOCUMENT, REMOVE_DOCUMENT_SUCCESS, REMOVE_DOCUMENT_FAIL],
  promise: (client) => client.del(`v3/planners/workers/${data.id}/document`, { data }),
  data,
});

/**
 * Download photo
 * @param {object} data
 */
export const downloadDocument = (data) => ({
  types: [DOWNLOAD_DOCUMENT, DOWNLOAD_DOCUMENT_SUCCESS, DOWNLOAD_DOCUMENT_FAIL],
  promise: (client) => client.get(
    `v3/planners/workers/${data.entityId}/document?document_id=${data.docId}&password=${data.password}`,
    { responseType: 'blob' },
  ),
});

/**
 * Get password
 */
export const loadPassword = () => ({
  types: [LOAD_PASSWORD, LOAD_PASSWORD_SUCCESS, LOAD_PASSWORD_FAIL],
  promise: (client) => client.get('v3/planners/password_protection')
});

/**
 * Save password
 * @params {object} data
 */
export const savePassword = (data) => ({
  types: [SAVE_PASSWORD, SAVE_PASSWORD_SUCCESS, SAVE_PASSWORD_FAIL],
  promise: (client) => client.patch('v3/planners/password_protection', { data })
});

/**
 * Reset password
 */
export const resetPassword = () => ({
  types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
  promise: (client) => client.del('v3/planners/password_protection')
});

/**
 * Download data
 */
export const exportWorkers = () => ({
  types: [EXPORT_WORKERS, EXPORT_WORKERS_SUCCESS, EXPORT_WORKERS_FAIL],
  promise: (client) => client.get('v3/planners/workers/export'),
});

export const exportWorkersActivity = () => ({
  types: [EXPORT_WORKERS_ACTIVITY, EXPORT_WORKERS_ACTIVITY_SUCCESS, EXPORT_WORKERS_ACTIVITY_FAIL],
  promise: (client) => client.get('v3/planners/workers/export_activity'),
});

export const exportWorkerSchedule = (paramsUrl) => ({
  types: [EXPORT_WORKERS_SCHEDULE, EXPORT_WORKERS_SCHEDULE_SUCCESS, EXPORT_WORKERS_SCHEDULE_FAIL],
  promise: (client) => client.get(`v3/planners/workers/export_schedule?${paramsUrl}`),
});

export const enrichWorkers = (data) => ({
  types: [ENRICH_WORKERS, ENRICH_WORKERS_SUCCESS, ENRICH_WORKERS_FAIL],
  promise: (client) => client.post('v3/planners/workers/enrich', { data }),
});

export const createImportTemplate = () => ({
  types: [IMPORT_TEMPLATE, IMPORT_TEMPLATE_SUCCESS, IMPORT_TEMPLATE_FAIL],
  promise: (client) => client.get('v3/planners/workers/generate_creation_template'),
});

/**
 * Import workers from file (create)
 * @param {object} data
 */
export const importWorkers = (data) => ({
  types: [IMPORT_WORKERS, IMPORT_WORKERS_SUCCESS, IMPORT_WORKERS_FAIL],
  promise: (client) => client.post('v3/planners/workers/import_creation_template', { data }),
});

export const inviteToShifts = (id) => ({
  types: [INVITE_WORKER, INVITE_WORKER_SUCCESS, INVITE_WORKER_FAIL],
  promise: (client) => client.post(`gig_workers/${id}/invite`),
});

export const generateUrl = (id) => ({
  types: [GENERATE_URL, GENERATE_URL_SUCCESS, GENERATE_URL_FAIL],
  promise: (client) => client.get(`v3/planners/workers/${id}/generate_calendar_url`),
});

export const inviteWorker = (data) => ({
  types: [INVITE, INVITE_SUCCESS, INVITE_FAIL],
  promise: (client) => client.post('invites', { data }),
});

export const onboardingWorker = (data) => ({
  types: [ONBOARD_WORKER, ONBOARD_WORKER_SUCCESS, ONBOARD_WORKER_FAIL],
  promise: (client) => client.post('v3/planners/workers/enroll', { data }),
});

export const loadBillingInfo = (id) => ({
  types: [LOAD_BILLING_INFO, LOAD_BILLING_INFO_SUCCESS, LOAD_BILLING_INFO_FAIL],
  promise: (client) => client.get(`v3/planners/workers/${id}/billing_info`),
});

export const updateBillingInfo = (data) => ({
  types: [UPDATE_BILLING_INFO, UPDATE_BILLING_INFO_SUCCESS, UPDATE_BILLING_INFO_FAIL],
  promise: (client) => client.patch(`v3/planners/workers/${data.id}/billing_info`, { data }),
});

export const addToImport = () => (dispatch) => {
  const worker = getWorkerTemplate();
  dispatch(addToImportSlot(worker));

  return Promise.resolve(worker);
};

/**
 * Load approved workers
 * @param {object} params
 */
export const loadApprovedWorkers = (params) => (dispatch, getState) => {
  const paramsUrl = serializeParamsToQueryString(params, true);

  // uses at organization detail
  if (params.all) {
    return dispatch(loadAllWorkers(paramsUrl));
  }

  // save number of requests
  const count = getState().worker.countRequest + 1;
  dispatch({ type: INCREASE_COUNT, count });

  return dispatch(load(paramsUrl, count));
};

export const loadBlockedWorkers = (paramsUrl) => (dispatch, getState) => {
  // save number of requests
  const count = getState().worker.blocked.countRequest + 1;
  dispatch({ type: INCREASE_BLOCKED_COUNT, count });

  return dispatch(loadBlocked(paramsUrl, count));
};
export const loadInactiveWorkers = (paramsUrl) => (dispatch, getState) => {
  // save number of requests
  const count = getState().worker.blocked.countRequest + 1;
  dispatch({ type: INCREASE_INACTIVE_COUNT, count });

  return dispatch(loadInactive(paramsUrl, count));
};
export const loadSourcedWorkers = (paramsUrl) => (dispatch, getState) => {
  // save number of requests
  const count = getState().worker.blocked.countRequest + 1;
  dispatch({ type: INCREASE_SOURCED_COUNT, count });

  return dispatch(loadSourced(paramsUrl, count));
};

export const loadWorkerById = (id, url = '') => (dispatch, getState) => {
  if (!id) {
    return Promise.reject(new Error('Id is required'));
  }
  // save number of requests
  const { currentRequest } = getState().worker.entity;
  const count = currentRequest + 1;
  dispatch({ type: INCREASE_CURRENT_COUNT, count });

  return dispatch(loadById(id, url, count));
};

export const loadWorkerAvailabilityById = (id, url = '') => (dispatch, getState) => {
  if (!id) {
    return Promise.reject(new Error('Id is required'));
  }
  // save number of requests
  const { currentRequest } = getState().worker.entity;
  const count = currentRequest + 1;
  dispatch({ type: INCREASE_CURRENT_COUNT, count });

  return dispatch(loadAvailabilityById(id, url, count));
};

// Mutations
// *************************************

const updateRequestOnLoad = (value) => (state) => ({
  ...state,
  loading: value
});
const updateRequestOnLoading = (value) => (state) => ({
  ...state,
  loading: true,
  loaded: value,
  error: null
});

const updateRequestOnLoadSuccess = (value) => (state) => ({
  ...state,
  loading: value,
  loaded: true,
  error: null
});

const updateWorkerSuccess = (action) => (state) => {
  if (state.countRequest > action.count) {
    return ({
      ...state,
    });
  }

  const { data, included, meta } = action.result;
  const updId = data.map((item) => +item.id);
  const normalize = {};

  data.forEach((item) => {
    const roleIds = item.relationships.roles.data.map((role) => +role.id);
    const attrIds = item.relationships.custom_worker_attributes.data.map((attr) => +attr.id);

    normalize[item.id] = {
      ...item.attributes,
      roleIds,
      attrIds,
      initial: `${item.attributes.first_name.charAt(0)}${item.attributes.last_name.charAt(0)}`
    };
  });

  const byRoleId = {};
  const byAttrId = {};
  included.forEach((item) => {
    if (item.type === 'role') {
      byRoleId[item.id] = {
        ...item.attributes,
      };
    } else {
      byAttrId[item.id] = {
        ...item.attributes,
        attribute: included.find((el) => el.id === item.relationships?.custom_attribute.data.id)
      };
    }
  });

  return {
    ...state,
    loading: false,
    loaded: true,
    error: null,
    entity: {
      ...state.entity,
      allIds: updId,
      byId: normalize,
      byRoleId,
      byAttrId,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      },
    },
  };
};

const setBlockedCountSuccess = (action) => (state) => ({
  ...state,
  blocked: {
    ...state.blocked,
    countRequest: action.count
  }
});

const loadBlockedSuccess = (action) => (state) => {
  if (state.blocked.countRequest > action.count) {
    return ({
      ...state,
    });
  }

  const { data, included, meta } = action.result;
  const updId = data.map((item) => +item.id);
  const normalize = {};

  data.forEach((item) => {
    const roleIds = item.relationships.roles.data.map((role) => +role.id);
    const attrIds = item.relationships.custom_worker_attributes.data.map((attr) => +attr.id);

    normalize[item.id] = {
      ...item.attributes,
      roleIds,
      attrIds,
      initial: `${item.attributes.first_name.charAt(0)}${item.attributes.last_name.charAt(0)}`
    };
  });

  const byRoleId = {};
  const byAttrId = {};
  included.forEach((item) => {
    if (item.type === 'role') {
      byRoleId[item.id] = {
        ...item.attributes,
      };
    } else {
      byAttrId[item.id] = {
        ...item.attributes,
        attribute: included.find((el) => el.id === item.relationships?.custom_attribute.data.id)
      };
    }
  });

  return {
    ...state,
    blocked: {
      ...state.blocked,
      allIds: updId,
      byId: normalize,
      byRoleId,
      byAttrId,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      },
    },
  };
};

const setInactiveCountSuccess = (action) => (state) => ({
  ...state,
  inactive: {
    ...state.inactive,
    countRequest: action.count
  }
});
const loadInactiveSuccess = (action) => (state) => {
  if (state.inactive.countRequest > action.count) {
    return ({
      ...state,
    });
  }

  const { data, included, meta } = action.result;
  const updId = data.map((item) => +item.id);
  const normalize = {};

  data.forEach((item) => {
    const roleIds = item.relationships.roles.data.map((role) => +role.id);
    const attrIds = item.relationships.custom_worker_attributes.data.map((attr) => +attr.id);

    normalize[item.id] = {
      ...item.attributes,
      roleIds,
      attrIds,
      initial: `${item.attributes.first_name.charAt(0)}${item.attributes.last_name.charAt(0)}`
    };
  });

  const byRoleId = {};
  const byAttrId = {};
  included.forEach((item) => {
    if (item.type === 'role') {
      byRoleId[item.id] = {
        ...item.attributes,
      };
    } else {
      byAttrId[item.id] = {
        ...item.attributes,
        attribute: included.find((el) => el.id === item.relationships?.custom_attribute.data.id)
      };
    }
  });

  return {
    ...state,
    inactive: {
      ...state.inactive,
      allIds: updId,
      byId: normalize,
      byRoleId,
      byAttrId,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      },
    },
  };
};

const setSourcedCountSuccess = (action) => (state) => ({
  ...state,
  sourced: {
    ...state.sourced,
    countRequest: action.count
  }
});
const loadSourcedSuccess = (action) => (state) => {
  if (state.sourced.countRequest > action.count) {
    return ({
      ...state,
    });
  }

  const { data, included, meta } = action.result;
  const updId = data.map((item) => +item.id);
  const normalize = {};

  data.forEach((item) => {
    const roleIds = item.relationships.roles.data.map((role) => +role.id);
    const attrIds = item.relationships.custom_worker_attributes.data.map((attr) => +attr.id);

    normalize[item.id] = {
      ...item.attributes,
      roleIds,
      attrIds,
      initial: `${item.attributes.first_name.charAt(0)}${item.attributes.last_name.charAt(0)}`
    };
  });

  const byRoleId = {};
  const byAttrId = {};
  included.forEach((item) => {
    if (item.type === 'role') {
      byRoleId[item.id] = {
        ...item.attributes,
      };
    } else {
      byAttrId[item.id] = {
        ...item.attributes,
        attribute: included.find((el) => el.id === item.relationships?.custom_attribute.data.id)
      };
    }
  });

  return {
    ...state,
    sourced: {
      ...state.sourced,
      allIds: updId,
      byId: normalize,
      byRoleId,
      byAttrId,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      },
    },
  };
};

const updateRequestOnLoadFail = (action, value) => (state) => ({
  ...state,
  loading: false,
  loaded: value,
  entity: {
    ...state.entity,
    allIds: [],
    byId: {},
    byRoleId: {},
    pagination: {
      page: 1,
      total: 0,
      total_pages: 1,
    }
  },
  error: action.error
});

/**
 * Loading process
 * @param {boolean} flag
 */
const loadingWorker = (flag) => (state) => ({
  ...state,
  entity: {
    ...state.entity,
    loading: flag,
  },
});

/**
 * Load worker success
 * @param {object} action
 */
const loadWorkerSuccess = (action) => (state) => {
  if (state.entity.currentRequest > action.count) {
    return ({
      ...state,
    });
  }

  const { data, included } = action.result;

  const documents = included ? included.filter((item) => item.type === 'document')
    .map((item) => ({
      ...item.attributes,
    })) : [];
  const favoriteOrganization = included ? included.filter((item) => item.type === 'favorite_organization')
    .map((item) => ({
      ...item.attributes,
    })) : [];
  const blacklistOrganization = included ? included.filter((item) => item.type === 'blacklist_organization')
    .map((item) => ({
      ...item.attributes,
    })) : [];
  const roles = included ? included.filter((item) => item.type === 'role')
    .map((item) => ({
      ...item.attributes,
    })) : [];

  const location = included ? included.filter((item) => item.type === 'location')
    .map((item) => ({
      ...item.attributes
    })) : [];

  const customAttribute = included ? included.filter((item) => item.type === 'custom_attribute') : [];
  const normalizeAttribute = customAttribute.reduce((obj, item) => ({ ...obj, [item.id]: item.attributes }), {});

  const customWorkerAttributes = included ? included.filter((item) => item.type === 'custom_worker_attribute')
    .map((item) => ({
      ...item.attributes,
      custom_attribute: normalizeAttribute[item.relationships.custom_attribute.data.id],
    })) : [];

  // for availability
  const timeOffAvailability = included ? included.filter((item) => item.type === 'time_off_availability')
    .map((item) => ({
      ...item.attributes,
      unavailabilityId: item.relationships.unavailability?.data?.id || '',
    })) : [];
  const gigAvailability = included ? included.filter((item) => item.type === 'gig_availability')
    .map((item) => ({
      ...item.attributes,
    })) : [];
  const foreignGigAvailability = included ? included.filter((item) => item.type === 'foreign_gig_availability')
    .map((item) => ({
      ...item.attributes,
    })) : [];

  const firstNameLetter = data.attributes.first_name ? data.attributes.first_name[0] : '';
  const lastNameLetter = data.attributes.last_name ? data.attributes.last_name[0] : '';
  const initials = `${firstNameLetter}${lastNameLetter}`;

  return ({
    ...state,
    entity: {
      ...state.entity,
      loading: false,
      current: {
        ...data.attributes,
        short_description: data.attributes.short_description || '',
        status: data.attributes.status || '',
        phone: data.attributes.phone || '',
        birthdate: data.attributes.birthdate || '',
        has_birthday: data.attributes.has_birthday || false,
        has_expired_document: data.attributes.has_expired_document || false,
        blacklist_organizations: blacklistOrganization,
        favorite_organizations: favoriteOrganization,
        custom_worker_attributes: customWorkerAttributes,
        time_offs: timeOffAvailability,
        gigs: gigAvailability,
        foreign_gigs: foreignGigAvailability,
        documents,
        location,
        roles,
        initials,
      }
    }
  });
};

const updateCurrentWorkerSuccess = (action) => (state) => {
  const { data } = action.result;

  if (state.entity.current.id !== data.attributes.id) {
    return;
  }

  return ({
    ...state,
    entity: {
      ...state.entity,
      current: {
        ...state.entity.current,
        ...data.attributes,
      },
    }
  });
};

const deleteWorkerSuccess = () => (state) => ({
  ...state,
  entity: {
    ...state.entity,
    current: {},
  }
});

const updateWorkerUrlSuccess = () => (state) => ({
  ...state,
  entity: {
    ...state.entity,
    current: {
      ...state.entity.current,
      calendar_url: true,
    },
  }
});

const loadCreatedSuccess = (action) => (state) => {
  const { data } = action.result;
  const workersList = data.map((item) => ({
    ...item.attributes,
  }));
  return ({
    ...state,
    workersList,
  });
};

const clearWorker = () => (state) => ({
  ...state,
  entity: {
    ...state.entity,
    loading: false,
    current: {},
  },
  billingInfo: {},
});

const updateSuccess = (action) => (state) => {
  const { data, included } = action.result;
  const { current } = state.entity;

  const documentList = included.filter((item) => item.type === 'document')
    .map((item) => ({
      ...item.attributes,
    }));
  const favoriteOrganization = included.filter((item) => item.type === 'favorite_organization')
    .map((item) => ({
      ...item.attributes,
    }));
  const blacklistOrganization = included.filter((item) => item.type === 'blacklist_organization')
    .map((item) => ({
      ...item.attributes,
    }));
  const roleList = included.filter((item) => item.type === 'role')
    .map((item) => ({
      ...item.attributes,
    }));

  const customAttribute = included.filter((item) => item.type === 'custom_attribute');
  const normalizeAttribute = customAttribute.reduce((obj, item) => ({ ...obj, [item.id]: item.attributes }), {});

  const customWorkerAttributes = included.filter((item) => item.type === 'custom_worker_attribute')
    .map((item) => ({
      ...item.attributes,
      custom_attribute: normalizeAttribute[item.relationships.custom_attribute.data.id],
    }));

  const documents = !isEmpty(documentList) ? documentList : current.documents;

  return ({
    ...state,
    entity: {
      ...state.entity,
      loading: false,
      current: {
        ...current,
        ...data.attributes,
        short_description: data.attributes.short_description || '',
        phone: data.attributes.phone || '',
        birthdate: data.attributes.birthdate || '',
        has_birthday: data.attributes.has_birthday || false,
        has_expired_document: data.attributes.has_expired_document || false,
        blacklist_organizations: blacklistOrganization,
        favorite_organizations: favoriteOrganization,
        custom_worker_attributes: customWorkerAttributes,
        documents,
        roles: roleList,
      }
    }
  });
};

const uploadSuccess = (action) => (state) => {
  const { worker } = action.result;

  return ({
    ...state,
    entity: {
      ...state.entity,
      loading: false,
      current: {
        ...state.entity.current,
        photo: worker.photo,
      },
    },
  });
};

const removePhotoSuccess = () => (state) => ({
  ...state,
  entity: {
    ...state.entity,
    loading: false,
    current: {
      ...state.entity.current,
      photo: ''
    }
  },
});

const uploadDocumentSuccess = (action) => (state) => {
  const { current } = state.entity;
  const { included } = action.result;

  const documents = included ? included.filter((item) => item.type === 'document')
    .map((item) => ({
      ...item.attributes,
    })) : [];

  return ({
    ...state,
    entity: {
      ...state.entity,
      loading: false,
      current: {
        ...current,
        documents,
      },
    },
  });
};

const removeDocumentSuccess = (action) => (state) => {
  const { current } = state.entity;
  const { data } = action;
  const filtered = current.documents.filter((item) => data.remove_ids.indexOf(item.id) === -1);

  return ({
    ...state,
    entity: {
      ...state.entity,
      loading: false,
      current: {
        ...current,
        documents: filtered,
      }
    },
  });
};

const setCountSuccess = (action) => (state) => ({
  ...state,
  countRequest: action.count
});

const setCurrentCountSuccess = (action) => (state) => ({
  ...state,
  entity: {
    ...state.entity,
    currentRequest: action.count
  }
});

const addImportSuccess = (action) => (state) => {
  const worker = action.result;

  return ({
    ...state,
    import: {
      ...state.import,
      byId: {
        ...state.import.byId,
        [worker.id]: worker,
      },
      allIds: [
        ...state.import.allIds,
        worker.id,
      ]
    }
  });
};

const removeImportSuccess = (action) => (state) => {
  const filteredAllIds = state.import.allIds.filter((id) => id !== action.id);

  delete state.import.byId[action.id];

  return ({
    ...state,
    import: {
      ...state.import,
      allIds: filteredAllIds,
      byId: {
        ...state.import.byId,
      },
    }
  });
};

const clearImportSuccess = () => (state) => ({
  ...state,
  import: {
    file: '',
    successful: 0,
    allIds: [],
    byId: {},
  },
});

/**
 * Save location
 * @param {object} action
 */
const saveLocationSuccess = (action) => (state) => {
  const { current } = state.entity;
  const { data, result } = action;

  // if save location for shift, not need to save
  if (data.location.type) {
    return ({
      ...state,
    });
  }

  const location = result.data.attributes;
  return ({
    ...state,
    entity: {
      ...state.entity,
      current: {
        ...current,
        location: [
          ...current.location,
          location,
        ],
      },
    }
  });
};

/**
 * Update location
 * @param {object} action
 */
const updateLocationSuccess = (action) => (state) => {
  const { current } = state.entity;
  const { id } = action.data;

  const location = action.result.data.attributes;

  const updated = current.location.map((obj) => {
    if (obj.id === id) {
      return location;
    }

    return obj;
  });

  return ({
    ...state,
    entity: {
      ...state.entity,
      current: {
        ...current,
        location: updated,
      },
    }
  });
};

/**
 * Remove location
 * @param {object} action
 */
const removeLocationSuccess = (action) => (state) => {
  const { current } = state.entity;
  const { locationId } = action.params;
  const filteredLocations = current.location.filter((loc) => loc.id !== locationId);

  return ({
    ...state,
    entity: {
      ...state.entity,
      current: {
        ...current,
        location: filteredLocations,
      },
    }
  });
};

const updatePasswordSuccess = (action) => (state) => ({
  ...state,
  isPasswordExist: action.result.data.attributes['exist?']
});

const resetPasswordSuccess = () => (state) => ({
  ...state,
  isPasswordExist: false
});

const updateBillingInfoSuccess = (action) => (state) => {
  const { data } = action.result;

  return ({
    ...state,
    billingInfo: {
      ...state.billingInfo,
      companyName: data.attributes.company_name || '',
      address: data.attributes.address || '',
      code: data.attributes.postal_code || '',
      city: data.attributes.city || '',
      KVKNumber: data.attributes.kvk || '',
      btwNumber: data.attributes.vat || '',
      btwException: data.attributes.vat_exception || false,
      btwRate: (typeof data.attributes.vat_rate === 'number') ? data.attributes.vat_rate : '',
      IBANNumber: data.attributes.iban || '',
      email: data.attributes.email || '',
      phone: data.attributes.phone || '',
      invoiceTo: data.attributes.to_field || '',
      paymentTermInfo: data.attributes.payment_term_info || '',
      correctness: data.attributes.correctness,
    }
  });
};

const setDefaultSuccess = () => initialState;

const actionsLookup = {
  [LOAD]: (state) => updateRequestOnLoad(true)(state),
  [INCREASE_COUNT]: (state, action) => setCountSuccess(action)(state),
  [LOAD_CREATED_SUCCESS]: (state, action) => loadCreatedSuccess(action)(state),
  [INVITES_WORKERS]: (state) => flowRight(updateRequestOnLoading(false))(state),
  [INCREASE_BLOCKED_COUNT]: (state, action) => setBlockedCountSuccess(action)(state),
  [LOAD_BLOCKED_SUCCESS]: (state, action) => loadBlockedSuccess(action)(state),
  [INCREASE_INACTIVE_COUNT]: (state, action) => setInactiveCountSuccess(action)(state),
  [LOAD_INACTIVE_SUCCESS]: (state, action) => loadInactiveSuccess(action)(state),
  [INCREASE_SOURCED_COUNT]: (state, action) => setSourcedCountSuccess(action)(state),
  [LOAD_SOURCED_SUCCESS]: (state, action) => loadSourcedSuccess(action)(state),
  [UPDATE_SUCCESS]: (state, action) => updateSuccess(action)(state),
  [UPLOAD_SUCCESS]: (state, action) => uploadSuccess(action)(state),
  [REMOVE_PHOTO_SUCCESS]: (state) => removePhotoSuccess()(state),
  [UPLOAD_DOCUMENT_SUCCESS]: (state, action) => uploadDocumentSuccess(action)(state),
  [REMOVE_DOCUMENT_SUCCESS]: (state, action) => removeDocumentSuccess(action)(state),
  [LOAD_SUCCESS]: (state, action) => flowRight(
    updateRequestOnLoadSuccess(false),
    updateWorkerSuccess(action),
  )(state),
  [LOAD_FAIL]: (state, action) => updateRequestOnLoadFail(action, false)(state),
  [SAVE_LOC_SUCCESS]: (state, action) => saveLocationSuccess(action)(state),
  [UPDATE_LOC_SUCCESS]: (state, action) => updateLocationSuccess(action)(state),
  [DELETE_LOC_SUCCESS]: (state, action) => removeLocationSuccess(action)(state),
  [SAVE_PASSWORD_SUCCESS]: (state, action) => updatePasswordSuccess(action)(state),
  [LOAD_PASSWORD_SUCCESS]: (state, action) => updatePasswordSuccess(action)(state),
  [RESET_PASSWORD_SUCCESS]: (state) => resetPasswordSuccess()(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
  [LOAD_WORKER]: (state) => loadingWorker(true)(state),
  [LOAD_WORKER_SUCCESS]: (state, action) => loadWorkerSuccess(action)(state),
  [LOAD_WORKER_FAIL]: (state) => loadingWorker(false)(state),
  [UPDATE_CURRENT_WORKER_SUCCESS]: (state, action) => updateCurrentWorkerSuccess(action)(state),
  [CLEAR_CURRENT_WORKER]: (state) => clearWorker()(state),
  [INCREASE_CURRENT_COUNT]: (state, action) => setCurrentCountSuccess(action)(state),
  [ADD_IMPORT_WORKER]: (state, action) => addImportSuccess(action)(state),
  [REMOVE_IMPORT_WORKER]: (state, action) => removeImportSuccess(action)(state),
  [DELETE_SUCCESS]: (state) => deleteWorkerSuccess()(state),
  [GENERATE_URL_SUCCESS]: (state) => updateWorkerUrlSuccess()(state),
  [CLEAR_IMPORT]: (state) => clearImportSuccess()(state),
  [LOAD_BILLING_INFO_SUCCESS]: (state, action) => updateBillingInfoSuccess(action)(state),
  [UPDATE_BILLING_INFO_SUCCESS]: (state, action) => updateBillingInfoSuccess(action)(state),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}