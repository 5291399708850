import isFunction from 'lodash/isFunction';

const LOAD = 'redux-ducks/invoiceNumbers/LOAD';
const LOAD_SUCCESS = 'redux-ducks/invoiceNumbers/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-ducks/invoiceNumbers/LOAD_FAIL';

const UPDATE = 'redux-ducks/invoiceNumbers/UPDATE';
const UPDATE_SUCCESS = 'redux-ducks/invoiceNumbers/UPDATE_SUCCESS';
const UPDATE_FAIL = 'redux-ducks/invoiceNumbers/UPDATE_FAIL';

const LOAD_WORKER = 'redux-ducks/invoiceNumbers/LOAD_WORKER';
const LOAD_WORKER_SUCCESS = 'redux-ducks/invoiceNumbers/LOAD_WORKER_SUCCESS';
const LOAD_WORKER_FAIL = 'redux-ducks/invoiceNumbers/LOAD_WORKER_FAIL';

const UPDATE_WORKER = 'redux-ducks/invoiceNumbers/UPDATE_WORKER';
const UPDATE_WORKER_SUCCESS = 'redux-ducks/invoiceNumbers/UPDATE_WORKER_SUCCESS';
const UPDATE_WORKER_FAIL = 'redux-ducks/invoiceNumbers/UPDATE_WORKER_FAIL';

const LOAD_ORGANIZATION = 'redux-ducks/invoiceNumbers/LOAD_ORGANIZATION';
const LOAD_ORGANIZATION_SUCCESS = 'redux-ducks/invoiceNumbers/LOAD_ORGANIZATION_SUCCESS';
const LOAD_ORGANIZATION_FAIL = 'redux-ducks/invoiceNumbers/LOAD_ORGANIZATION_FAIL';

const UPDATE_ORGANIZATION = 'redux-ducks/invoiceNumbers/UPDATE_ORGANIZATION';
const UPDATE_ORGANIZATION_SUCCESS = 'redux-ducks/invoiceNumbers/UPDATE_ORGANIZATION_SUCCESS';
const UPDATE_ORGANIZATION_FAIL = 'redux-ducks/invoiceNumbers/UPDATE_ORGANIZATION_FAIL';

const RESET = 'redux-ducks/invoiceNumbers/RESET';
const RESET_SUCCESS = 'redux-ducks/invoiceNumbers/RESET_SUCCESS';
const RESET_FAIL = 'redux-ducks/invoiceNumbers/RESET_FAIL';

const initialState = {
  entity: {
    client: {
      selling: {},
      buying: {}
    },
    worker: {},
    organization: {}
  }
};

/**
 * ACTIONS
 */
export const load = () => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get('v3/planners/invoice_number_sequences'),
});

export const update = (data) => ({
  types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
  promise: (client) => client.patch('v3/planners/invoice_number_sequences', { data }),
  invoice: data
});

export const loadWorkerSequence = (id) => ({
  types: [LOAD_WORKER, LOAD_WORKER_SUCCESS, LOAD_WORKER_FAIL],
  promise: (client) => client.get(`v3/planners/workers/${id}/invoice_number_sequences`),
  id
});

export const updateWorkerSequence = (data) => ({
  types: [UPDATE_WORKER, UPDATE_WORKER_SUCCESS, UPDATE_WORKER_FAIL],
  promise: (client) => client
    .patch(`v3/planners/workers/${data.buying_template.id}/invoice_number_sequences`, { data }),
  id: data.buying_template.id,
});

export const loadOrgSequence = (id) => ({
  types: [LOAD_ORGANIZATION, LOAD_ORGANIZATION_SUCCESS, LOAD_ORGANIZATION_FAIL],
  promise: (client) => client.get(`v3/planners/organizations/${id}/invoice_number_sequences`),
  id
});

export const updateOrgSequence = (data) => ({
  types: [UPDATE_ORGANIZATION, UPDATE_ORGANIZATION_SUCCESS, UPDATE_ORGANIZATION_FAIL],
  promise: (client) => client
    .patch(`v3/planners/organizations/${data.selling_template.id}/invoice_number_sequences`, { data }),
  id: data.selling_template.id
});

export const resetAll = () => ({
  types: [RESET, RESET_SUCCESS, RESET_FAIL],
  promise: (client) => client.post('v3/planners/invoice_number_sequences/reset')
});

const loadInvoiceNumbersSuccess = (action) => (state) => {
  const { data } = action.result;

  const buyingData = data.find((el) => el.attributes.invoice_type === 'buying');
  const sellingData = data.find((el) => el.attributes.invoice_type === 'selling');
  return {
    ...state,
    entity: {
      ...state.entity,
      client: {
        buying: buyingData.attributes,
        selling: sellingData.attributes
      },
      worker: {},
      organization: {}
    }
  };
};

const updateInvoiceNumbersSuccess = (action) => (state) => {
  const { data } = action.result;
  const { invoice } = action;
  if (invoice.selling_template) {
    const sellingData = data.find((el) => el.attributes.invoice_type === 'selling');
    return {
      ...state,
      entity: {
        ...state.entity,
        client: {
          ...state.entity.client,
          selling: sellingData.attributes
        }
      }
    };
  }
  if (invoice.buying_template) {
    const buyingData = data.find((el) => el.attributes.invoice_type === 'buying');
    return {
      ...state,
      entity: {
        ...state.entity,
        client: {
          ...state.entity.client,
          buying: buyingData.attributes
        }
      }
    };
  }
};

const loadWorkerInvoiceNumbersSuccess = (action) => (state) => {
  const { data } = action.result;

  return {
    ...state,
    entity: {
      ...state.entity,
      worker: { ...data[0].attributes, id: action.id }
    }
  };
};

const loadOrgInvoiceNumbersSuccess = (action) => (state) => {
  const { data } = action.result;

  return {
    ...state,
    entity: {
      ...state.entity,
      organization: { ...data[0].attributes, id: action.id },
    }
  };
};

const actionsLookup = {
  [LOAD_SUCCESS]: (state, action) => loadInvoiceNumbersSuccess(action)(state),
  [LOAD_WORKER_SUCCESS]: (state, action) => loadWorkerInvoiceNumbersSuccess(action)(state),
  [LOAD_ORGANIZATION_SUCCESS]: (state, action) => loadOrgInvoiceNumbersSuccess(action)(state),
  [UPDATE_SUCCESS]: (state, action) => updateInvoiceNumbersSuccess(action)(state),
  [UPDATE_WORKER_SUCCESS]: (state, action) => loadWorkerInvoiceNumbersSuccess(action)(state),
  [UPDATE_ORGANIZATION_SUCCESS]: (state, action) => loadOrgInvoiceNumbersSuccess(action)(state),
  [RESET_SUCCESS]: (state, action) => loadInvoiceNumbersSuccess(action)(state),

};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}