import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import {
  format,
  addDays,
  isSameDay,
} from 'date-fns';
import globalConst from '../../constants';
import { serializeParamsToQueryString } from '../../utils/serialize';
import {
  prepareShiftToFixing,
  checkItemInArray,
} from '../../utils/utils';

export const SET_FORM_DATE = 'redux-ducks/gig/SET_FORM_DATE';
export const CHANGE_VIEW = 'redux-ducks/gig/CHANGE_VIEW';
export const INCREASE_COUNT = 'redux-ducks/gig/INCREASE_COUNT';

export const LOAD = 'redux-ducks/gig/LOAD';
export const LOAD_SUCCESS = 'redux-ducks/gig/LOAD_SUCCESS';
export const LOAD_FAIL = 'redux-ducks/gig/LOAD_FAIL';

export const LOAD_UPDATED_GIGS = 'redux-ducks/gig/LOAD_UPDATED_GIGS';
export const LOAD_UPDATED_GIGS_SUCCESS = 'redux-ducks/gig/LOAD_UPDATED_GIGS_SUCCESS';
export const LOAD_UPDATED_GIGS_FAIL = 'redux-ducks/gig/LOAD_UPDATED_GIGS_FAIL';

export const LOAD_GIG = 'redux-ducks/gig/LOAD_GIG';
export const LOAD_GIG_SUCCESS = 'redux-ducks/gig/LOAD_GIG_SUCCESS';
export const LOAD_GIG_FAIL = 'redux-ducks/gig/LOAD_GIG_FAIL';

export const REMOVE_BEGIN = 'redux-ducks/gig/REMOVE_GIG';
export const REMOVE_SUCCESS = 'redux-ducks/gig/REMOVE_GIG_SUCCESS';
export const REMOVE_FAIL = 'redux-ducks/gig/REMOVE_GIG_FAIL';

const REMOVE_MULTIPLE = 'redux-ducks/gig/REMOVE_MULTIPLE';
const REMOVE_MULTIPLE_SUCCESS = 'redux-ducks/gig/REMOVE_MULTIPLE_SUCCESS';
const REMOVE_MULTIPLE_FAIL = 'redux-ducks/gig/REMOVE_MULTIPLE_FAIL';

const REMOVE_MULTIPLE_WORKER_VIEW = 'redux-ducks/gig/REMOVE_MULTIPLE_WORKER_VIEW';
const REMOVE_MULTIPLE_WORKER_VIEW_SUCCESS = 'redux-ducks/gig/REMOVE_MULTIPLE_WORKER_VIEW_SUCCESS';
const REMOVE_MULTIPLE_WORKER_VIEW_FAIL = 'redux-ducks/gig/REMOVE_MULTIPLE_WORKER_VIEW_FAIL';

export const PUBLISH_BEGIN = 'redux-ducks/gig/PUBLISH_BEGIN';
export const PUBLISH_SUCCESS = 'redux-ducks/gig/PUBLISH_SUCCESS';
export const PUBLISH_FAIL = 'redux-ducks/gig/PUBLISH_FAIL';

export const PUBLISH_GIGS_BEGIN = 'redux-ducks/gig/PUBLISH_GIGS_BEGIN';
export const PUBLISH_GIGS_SUCCESS = 'redux-ducks/gig/PUBLISH_GIGS_SUCCESS';
export const PUBLISH_GIGS_FAIL = 'redux-ducks/gig/PUBLISH_GIGS_FAIL';

export const LOAD_WORKERS = 'redux-ducks/gig/LOAD_WORKERS';
export const LOAD_WORKERS_SUCCESS = 'redux-ducks/gig/LOAD_WORKERS_SUCCESS';
export const LOAD_WORKERS_FAIL = 'redux-ducks/gig/LOAD_WORKERS_FAIL';
export const INCREASE_REQ_WORKERS_COUNT = 'redux-ducks/gig/INCREASE_REQ_WORKERS_COUNT';

export const FETCH_WORKERS = 'redux-ducks/gig/FETCH_WORKERS';
export const FETCH_WORKERS_SUCCESS = 'redux-ducks/gig/FETCH_WORKERS_SUCCESS';
export const FETCH_WORKERS_FAIL = 'redux-ducks/gig/FETCH_WORKERS_FAIL';

export const FETCH_WORKERS_BA = 'redux-ducks/gig/FETCH_WORKERS_BA';
export const FETCH_WORKERS_BA_SUCCESS = 'redux-ducks/gig/FETCH_WORKERS_BA_SUCCESS';
export const FETCH_WORKERS_BA_FAIL = 'redux-ducks/gig/FETCH_WORKERS_BA_FAIL';

export const FETCH_WORKERS_AVAILABILITY = 'redux-ducks/gig/FETCH_WORKERS_AVAILABILITY';
export const FETCH_WORKERS_AVAILABILITY_SUCCESS = 'redux-ducks/gig/FETCH_WORKERS_AVAILABILITY_SUCCESS';
export const FETCH_WORKERS_AVAILABILITY_FAIL = 'redux-ducks/gig/FETCH_WORKERS_AVAILABILITY_FAIL';

export const REJECT_WORKER = 'redux-ducks/gig/REJECT_WORKER';
export const REJECT_WORKER_SUCCESS = 'redux-ducks/gig/REJECT_WORKER_SUCCESS';
export const REJECT_WORKER_FAIL = 'redux-ducks/gig/REJECT_WORKER_FAIL';

export const REJECT_NOT_RESERVE_WORKER = 'redux-ducks/gig/REJECT_NOT_RESERVE_WORKER';
export const REJECT_NOT_RESERVE_WORKER_SUCCESS = 'redux-ducks/gig/REJECT_NOT_RESERVE_WORKER_SUCCESS';
export const REJECT_NOT_RESERVE_WORKER_FAIL = 'redux-ducks/gig/REJECT_NOT_RESERVE_WORKER_FAIL';

export const ASSIGN_WORKER = 'redux-ducks/gig/ASSIGN_WORKER';
export const ASSIGN_WORKER_SUCCESS = 'redux-ducks/gig/ASSIGN_WORKER_SUCCESS';
export const ASSIGN_WORKER_FAIL = 'redux-ducks/gig/ASSIGN_WORKER_FAIL';

export const APPROVE_WORKER = 'redux-ducks/gig/APPROVE_WORKER';
export const APPROVE_WORKER_SUCCESS = 'redux-ducks/gig/APPROVE_WORKER_SUCCESS';
export const APPROVE_WORKER_FAIL = 'redux-ducks/gig/APPROVE_WORKER_FAIL';

const RESERVE_WORKER = 'redux-ducks/gig/RESERVE_WORKER';
const RESERVE_WORKER_SUCCESS = 'redux-ducks/gig/RESERVE_WORKER_SUCCESS';
const RESERVE_WORKER_FAIL = 'redux-ducks/gig/RESERVE_WORKER_FAIL';

export const REMOVE_DECLINED_WORKER = 'redux-ducks/gig/REMOVE_DECLINED_WORKER';
export const REMOVE_DECLINED_WORKER_SUCCESS = 'redux-ducks/gig/REMOVE_DECLINED_WORKER_SUCCESS';
export const REMOVE_DECLINED_WORKER_FAIL = 'redux-ducks/gig/REMOVE_DECLINED_WORKER_FAIL';

export const AVAILABILITY_WORKER = 'redux-ducks/gig/AVAILABILITY_WORKER';
export const AVAILABILITY_WORKER_SUCCESS = 'redux-ducks/gig/AVAILABILITY_WORKER_SUCCESS';
export const AVAILABILITY_WORKER_FAIL = 'redux-ducks/gig/AVAILABILITY_WORKER_FAIL';

export const SEND_NOTIFY = 'redux-ducks/gig/SEND_NOTIFY';
export const SEND_NOTIFY_SUCCESS = 'redux-ducks/gig/SEND_NOTIFY_SUCCESS';
export const SEND_NOTIFY_FAIL = 'redux-ducks/gig/SEND_NOTIFY_FAIL';

export const SEND_COMMENT = 'redux-ducks/gig/SEND_COMMENT';
export const SEND_COMMENT_SUCCESS = 'redux-ducks/gig/SEND_COMMENT_SUCCESS';
export const SEND_COMMENT_FAIL = 'redux-ducks/gig/SEND_COMMENT_FAIL';

export const SET_DEFAULT = 'redux-ducks/gig/SET_DEFAULT';
export const SET_DEFAULT_CREATE = 'redux-ducks/gig/SET_DEFAULT_CREATE';
export const CLEAR_CURRENT = 'redux-ducks/gig/CLEAR_CURRENT';

export const CREATE_GIG = 'redux-ducks/gig/CREATE_GIG';
export const CREATE_GIG_SUCCESS = 'redux-ducks/gig/CREATE_GIG_SUCCESS';
export const CREATE_GIG_FAIL = 'redux-ducks/gig/CREATE_GIG_FAIL';

export const UPDATE_GIG = 'redux-ducks/gig/UPDATE_GIG';
export const UPDATE_GIG_SUCCESS = 'redux-ducks/gig/UPDATE_GIG_SUCCESS';
export const UPDATE_GIG_FAIL = 'redux-ducks/gig/UPDATE_GIG_FAIL';

export const SET_QUERY = 'redux-duck/gig/SET_QUERY';
export const SET_DEFAULT_GIG_ENTITY = 'redux-duck/gig/SET_DEFAULT_GIG_ENTITY';

export const IMPORT_GIGS = 'redux-duck/gig/IMPORT_GIGS';
export const IMPORT_GIGS_SUCCESS = 'redux-duck/gig/IMPORT_GIGS_SUCCESS';
export const IMPORT_GIGS_FAIL = 'redux-duck/gig/IMPORT_GIGS_FAIL';
export const REMOVE_IMPORT_GIG = 'redux-duck/gig/REMOVE_IMPORT_GIG';
export const ADD_IMPORT_GIG = 'redux-duck/gig/ADD_IMPORT_GIG';
export const COPY_IMPORT_SLOT = 'redux-duck/gig/COPY_IMPORT_SLOT';
export const CLEAR_IMPORT = 'redux-duck/gig/CLEAR_IMPORT';

export const INFORM = 'redux-duck/gig/INFORM';
export const INFORM_SUCCESS = 'redux-duck/gig/INFORM_SUCCESS';
export const INFORM_FAIL = 'redux-duck/gig/INFORM_FAIL';

export const LOAD_WORKERS_ENTITY = 'redux-ducks/gig/LOAD_WORKERS_ENTITY';
export const LOAD_WORKERS_ENTITY_SUCCESS = 'redux-ducks/gig/LOAD_WORKERS_ENTITY_SUCCESS';
export const LOAD_WORKERS_ENTITY_FAIL = 'redux-ducks/gig/LOAD_WORKERS_ENTITY_FAIL';
export const INCREASE_WORKER_COUNT = 'redux-duck/gig/INCREASE_WORKER_COUNT';

export const LOAD_TEMPLATE = 'redux-ducks/gig/LOAD_TEMPLATE';
export const LOAD_TEMPLATE_SUCCESS = 'redux-ducks/gig/LOAD_TEMPLATE_SUCCESS';
export const LOAD_TEMPLATE_FAIL = 'redux-ducks/gig/LOAD_TEMPLATE_FAIL';

export const SAVE_TEMPLATE = 'redux-ducks/gig/SAVE_TEMPLATE';
export const SAVE_TEMPLATE_SUCCESS = 'redux-ducks/gig/SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_FAIL = 'redux-ducks/gig/SAVE_TEMPLATE_FAIL';

export const DELETE_TEMPLATE = 'redux-ducks/gig/DELETE_TEMPLATE';
export const DELETE_TEMPLATE_SUCCESS = 'redux-ducks/gig/DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAIL = 'redux-ducks/gig/DELETE_TEMPLATE_FAIL';

const CREATE_TEMPLATE = 'redux-ducks/gig/CREATE_TEMPLATE';
const CREATE_TEMPLATE_SUCCESS = 'redux-ducks/gig/CREATE_TEMPLATE_SUCCESS';
const CREATE_TEMPLATE_FAIL = 'redux-ducks/gig/CREATE_TEMPLATE_FAIL';

const initialState = {
  creatingGigInfo: {
    title: '',
    date: '',
    startTime: '',
    finishTime: '',
    hasStartAt: true,
    hasFinishAt: true,
    isNight: false,
    organization: '',
    location: {
      id: '',
      address: '',
    },
    rolesEntity: [],
    scheduleType: '',
    open: false,
    description: '',
    hiddenDesc: '',
    dateUntil: '',
    attributes: {},
    documentsData: [],
    favorite: false,
    rating: '',
    filterAttributes: {},
    timebreak: '',
    gigCategoryInfo: {},
    project_id: null
  },
  workersForGig: {
    workers: [],
    params: {},
    countRequest: 0,
  },
  workersEntity: {
    allIds: [],
    byId: {},
    pagination: {
      page: 1,
      total: 0,
      total_pages: 1,
    },
    countRequest: 0,
  },
  entities: {
    loading: false,
    loaded: false,
    list: [],
    pagination: {
      page: 1,
      total: 0,
      total_pages: 1,
    },
  },
  shiftTemplates: [],
  current: {
    workers: [],
    location: {},
    invited_workers: [],
    declined_workers: [],
    rejected_workers: [],
  },
  calendar: {
    view: globalConst.WEEK_STR,
    date: '',
    time: '12:00'
  },
  query: {},
  isUpdatedGigs: false,
  countRequest: 0,
  error: {},
  import: {
    file: '',
    successful: 0,
    allIds: [],
    byId: {},
  },
};

export function load(params, count) {
  const request = {};
  request.by_daterange = [];
  request.by_daterange['start_date'] = params.start; // eslint-disable-line dot-notation
  request.by_daterange['finish_date'] = params.end; // eslint-disable-line dot-notation

  if (params.page) {
    request.page = params.page;
  }

  if (params.bookmarked) {
    request.bookmarked = params.bookmarked;
  }

  if (params.with_comments) {
    request.with_comments = params.with_comments;
  }

  const extraParams = {};
  extraParams.by_organizations = params.by_organizations;
  extraParams.by_organizations_groups = params.by_organizations_groups;
  extraParams.by_locations = params.by_locations;
  extraParams.by_roles = params.by_roles;
  extraParams.by_workers = params.by_workers;
  extraParams.by_statuses = params.by_statuses;
  extraParams.by_project = params.by_project;

  if (!isEmpty(params.by_custom_attrs)) {
    extraParams.by_custom_attrs = params.by_custom_attrs;
  }

  if (!isEmpty(params.by_string_custom_attrs)) {
    extraParams.by_string_custom_attrs = params.by_string_custom_attrs;
  }

  if (!isEmpty(params.by_org_custom_attrs)) {
    extraParams.by_org_custom_attrs = params.by_org_custom_attrs;
  }

  if (!isEmpty(params.by_org_string_custom_attrs)) {
    extraParams.by_org_string_custom_attrs = params.by_org_string_custom_attrs;
  }

  if (!isEmpty(params.by_gig_category)) {
    extraParams.by_gig_category = params.by_gig_category;
  }

  // this is done on purpose for API, sent &inlclude=
  extraParams.include = params.include;

  const paramsUrl = serializeParamsToQueryString(request);
  const paramsExtraUrl = serializeParamsToQueryString(extraParams, true);

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get(`v3/planners/gigs?${paramsUrl}&${paramsExtraUrl}`),
    count,
  };
}

export const loadUpdatedGig = (params) => {
  const request = {};
  request.by_daterange = [];
  request.by_daterange['start_date'] = params.start; // eslint-disable-line dot-notation
  request.by_daterange['finish_date'] = params.end; // eslint-disable-line dot-notation

  if (params.bookmarked) {
    request.bookmarked = params.bookmarked;
  }

  if (params.with_comments) {
    request.with_comments = params.with_comments;
  }

  const extraParams = {};
  extraParams.by_organizations = params.by_organizations;
  extraParams.by_locations = params.by_locations;
  extraParams.by_roles = params.by_roles;
  extraParams.by_workers = params.by_workers;
  extraParams.by_statuses = params.by_statuses;

  if (!isEmpty(params.by_custom_attrs)) {
    extraParams.by_custom_attrs = params.by_custom_attrs;
  }

  if (!isEmpty(params.by_string_custom_attrs)) {
    extraParams.by_string_custom_attrs = params.by_string_custom_attrs;
  }

  if (!isEmpty(params.by_gig_category)) {
    extraParams.by_gig_category = params.by_gig_category;
  }

  // this is done on purpose for API, sent &inlclude=
  extraParams.include = params.include;

  const paramsUrl = serializeParamsToQueryString(request);
  const paramsExtraUrl = serializeParamsToQueryString(extraParams, true);
  return {
    types: [LOAD_UPDATED_GIGS, LOAD_UPDATED_GIGS_SUCCESS, LOAD_UPDATED_GIGS_FAIL],
    promise: (client) => client.get(`v3/planners/gigs?${paramsUrl}&${paramsExtraUrl}`),
    params,
  };
};

/**
 * Create gig
 * @param {object} data
 */
export const createGig = (data) => ({
  types: [CREATE_GIG, CREATE_GIG_SUCCESS, CREATE_GIG_FAIL],
  promise: (client) => client.post('gigs', { data }),
});

export function updateGig(data, id) {
  return {
    types: [UPDATE_GIG, UPDATE_GIG_SUCCESS, UPDATE_GIG_FAIL],
    promise: (client) => client.patch(`gigs/${id}`, { data }),
  };
}

export const sendComment = (data) => ({
  types: [SEND_COMMENT, SEND_COMMENT_SUCCESS, SEND_COMMENT_FAIL],
  promise: (client) => client.post('gig_comments', { data }),
  data,
});

/**
 * Load shift by id
 * @param {number} id shift_id
 * @param {*} query includes
 */
export const loadGigById = (id, query = '') => ({
  types: [LOAD_GIG, LOAD_GIG_SUCCESS, LOAD_GIG_FAIL],
  promise: (client) => client.get(`gigs/${id}${query && `?${query}`}`)
});

/**
 * Handle remove shift
 * @param {object} params
 */
export const remove = (params) => ({
  types: [REMOVE_BEGIN, REMOVE_SUCCESS, REMOVE_FAIL],
  promise: (client) => client.del(`gigs/${params.id}`, { data: params }),
  id: parseInt(params.id, globalConst.RADIX_DECIMAL),
});

/**
 * Handle remove bulk shifts
 * @param {object} params
 */
export const removeMultiple = (data) => ({
  types: [REMOVE_MULTIPLE, REMOVE_MULTIPLE_SUCCESS, REMOVE_MULTIPLE_FAIL],
  promise: (client) => client.del('gigs/delete_multiple', { data }),
});

/**
 * Handle remove bulk shifts on worker view
 * @param {object} params
 */
export const multiDeleteWorkerView = (data) => ({
  types: [REMOVE_MULTIPLE_WORKER_VIEW, REMOVE_MULTIPLE_WORKER_VIEW_SUCCESS, REMOVE_MULTIPLE_WORKER_VIEW_FAIL],
  promise: (client) => client.del('workers_gigs/delete_multiple', { data }),
});

/**
 * Handle publish shift by id
 */
export const publish = (data, id) => ({
  types: [PUBLISH_BEGIN, PUBLISH_SUCCESS, PUBLISH_FAIL],
  promise: (client) => client.post(`gigs/${id}/publish`, { data }),
});

export const publishGigs = (data) => ({
  types: [PUBLISH_GIGS_BEGIN, PUBLISH_GIGS_SUCCESS, PUBLISH_GIGS_FAIL],
  promise: (client) => client.post('gigs/publish_all', { data }),
});

// Get workers for shift
export const getWorkers = (paramsUrl, count) => ({
  types: [LOAD_WORKERS, LOAD_WORKERS_SUCCESS, LOAD_WORKERS_FAIL],
  promise: (client) => client.get(`gig_workers?${paramsUrl}`),
  count,
});

const loadWorkersEntity = (params, count) => ({
  types: [LOAD_WORKERS_ENTITY, LOAD_WORKERS_ENTITY_SUCCESS, LOAD_WORKERS_ENTITY_FAIL],
  promise: (client) => client.get(`workers_gigs?${params}`),
  count,
});

/**
 * Fetch workers for gig without saving in store
 * @param {string} url
 */
export const fetchWorkers = (url) => ({
  types: [FETCH_WORKERS, FETCH_WORKERS_SUCCESS, FETCH_WORKERS_FAIL],
  promise: (client) => client.get(`gig_workers?${url}`),
});

export const fetchWorkersBa = (url) => ({
  types: [FETCH_WORKERS_BA, FETCH_WORKERS_BA_SUCCESS, FETCH_WORKERS_BA_FAIL],
  promise: (client) => client.get(`v3/planners/workers/all?${url}`),
});

export const fetchWorkersAvailability = (url) => ({
  types: [FETCH_WORKERS_AVAILABILITY, FETCH_WORKERS_AVAILABILITY_SUCCESS, FETCH_WORKERS_AVAILABILITY_FAIL],
  promise: (client) => client.get(`gig_workers/availability_list?${url}`),
});

export const rejectWorker = (data) => {
  const include = 'include=roles_workers,roles_workers.all_involved_workers,roles_workers.roles';
  return ({
    types: [REJECT_WORKER, REJECT_WORKER_SUCCESS, REJECT_WORKER_FAIL],
    promise: (client) => client.post(`gig_workers/${data.rejectId}/reject?${include}`, { data }),
    data,
  });
};

export const assignWorker = (data) => {
  const include = 'include=roles_workers,roles_workers.all_involved_workers,roles_workers.roles';
  return ({
    types: [ASSIGN_WORKER, ASSIGN_WORKER_SUCCESS, ASSIGN_WORKER_FAIL],
    promise: (client) => client.post(`gig_workers/${data.worker_id}/assign?${include}`, { data }),
    data,
  });
};

export const approveWorker = (data) => {
  const include = 'include=roles_workers,roles_workers.all_involved_workers,roles_workers.roles';
  return ({
    types: [APPROVE_WORKER, APPROVE_WORKER_SUCCESS, APPROVE_WORKER_FAIL],
    promise: (client) => client.post(`gig_workers/${data.worker_id}/approve?${include}`, { data }),
    data,
  });
};

export const reserveWorker = (data) => {
  const include = 'include=roles_workers,roles_workers.all_involved_workers,roles_workers.roles';
  return ({
    types: [RESERVE_WORKER, RESERVE_WORKER_SUCCESS, RESERVE_WORKER_FAIL],
    promise: (client) => client.patch(`v3/planners/gig_workers/${data.worker_id}/reserve?${include}`, { data }),
  });
};

export const rejectNotReserveAction = (data) => {
  const include = 'include=roles_workers,roles_workers.all_involved_workers,roles_workers.roles';
  return ({
    types: [REJECT_NOT_RESERVE_WORKER, REJECT_NOT_RESERVE_WORKER_SUCCESS, REJECT_NOT_RESERVE_WORKER_FAIL],
    promise: (client) => client.patch(`v3/planners/gig_workers/reject_not_reserved?${include}`, { data }),
  });
};

export const removeDeclinedWorker = (data) => {
  const include = 'include=roles_workers,roles_workers.all_involved_workers,roles_workers.roles';
  return ({
    types: [REMOVE_DECLINED_WORKER, REMOVE_DECLINED_WORKER_SUCCESS, REMOVE_DECLINED_WORKER_FAIL],
    promise: (client) => client.post(`gig_workers/${data.worker_id}/remove_declined?${include}`, { data }),
    data,
  });
};

/**
 * Load worker availability by id
 * @param {number} params
 */
export const loadWorkerAvailabilityById = (params) => ({
  types: [AVAILABILITY_WORKER, AVAILABILITY_WORKER_SUCCESS, AVAILABILITY_WORKER_FAIL],
  promise: (client) => client.get(`gig_workers/${params.workerId}/calculate_availability?${params.query}`),
});

export const sendReminder = (data) => ({
  types: [SEND_NOTIFY, SEND_NOTIFY_SUCCESS, SEND_NOTIFY_FAIL],
  promise: (client) => client.post(`gigs/${data.gig_id}/notify_workers`, { data }),
});

/**
 * Import gigs from file
 * @param {object} data
 */
export const importGigs = (data) => ({
  types: [IMPORT_GIGS, IMPORT_GIGS_SUCCESS, IMPORT_GIGS_FAIL],
  promise: (client) => client.post('v3/planners/gigs_imports', { data }),
  data,
});

export const inform = () => ({
  types: [INFORM, INFORM_SUCCESS, INFORM_FAIL],
  promise: (client) => client.post('gigs/inform'),
});

/**
 * Load shift templates
 */

export const loadTemplates = () => {
  const gigAttributes = 'custom_gig_template_attributes,custom_gig_template_attributes.custom_attribute';
  const includes = `?include=${gigAttributes},gig_template_gig_category`;
  return ({
    types: [LOAD_TEMPLATE, LOAD_TEMPLATE_SUCCESS, LOAD_TEMPLATE_FAIL],
    promise: (client) => client.get(
      `v3/planners/gig_templates${includes}`
    ),
  });
};

export const saveTemplate = (data) => {
  const gigAttributes = 'custom_gig_template_attributes,custom_gig_template_attributes.custom_attribute';
  const includes = `?include=${gigAttributes},gig_template_gig_category`;
  return ({
    types: [SAVE_TEMPLATE, SAVE_TEMPLATE_SUCCESS, SAVE_TEMPLATE_FAIL],
    promise: (client) => client.post(`v3/planners/gig_templates${includes}`, { data }),
  });
};

export const deleteTemplate = (id) => ({
  types: [DELETE_TEMPLATE, DELETE_TEMPLATE_SUCCESS, DELETE_TEMPLATE_FAIL],
  promise: (client) => client.del(`v3/planners/gig_templates/${id}`),
  id,
});

export const createGigImportTemplate = () => ({
  types: [CREATE_TEMPLATE, CREATE_TEMPLATE_SUCCESS, CREATE_TEMPLATE_FAIL],
  promise: (client) => client.post('v3/planners/gigs_imports/generate_creation_template'),
});

/**
 * Add gig to imports
 */
export const addToImport = (data) => ({
  type: ADD_IMPORT_GIG,
  data
});

/**
 * Remove gig from imports
 * @param {string} id
 */
export const removeImportById = (id) => ({
  type: REMOVE_IMPORT_GIG,
  id,
});

/**
 * Copy shift in multiple
 * @param {object} shift
 */
export const copySlot = (shift) => ({
  type: COPY_IMPORT_SLOT,
  slot: shift,
});

/**
 * Clear import shift results
 */
export const clearImport = () => ({
  type: CLEAR_IMPORT,
});

export const saveCalendarQuireParams = (params) => ({
  type: CHANGE_VIEW,
  params,
});

export const clearCurrent = () => ({
  type: CLEAR_CURRENT,
});

/**
 * Set data to gig create
 * @param {object} params
 */
export const setFormData = (params) => ({
  type: SET_FORM_DATE,
  params
});

/**
 * Save query
 * @param {string} query
 */
export const saveQuery = (query) => ({
  type: SET_QUERY,
  query,
});

/**
 * Set default gig entity
 */
export const setDefaultGigEntity = () => ({
  type: SET_DEFAULT_GIG_ENTITY,
});

export const setDefault = () => (dispatch) => {
  dispatch({ type: SET_DEFAULT });
  return Promise.resolve(true);
};

export const setDefaultForCreate = () => (dispatch) => {
  dispatch({ type: SET_DEFAULT_CREATE });
  return Promise.resolve(true);
};

/**
 * Action change calendar view
 * @param {object} params
 */
export const handleChangeView = (params) => async (dispatch, getState) => {
  await dispatch(saveCalendarQuireParams(params));

  return Promise.resolve(getState().gig.calendar);
};

/**
 * Action for set data for shift
 * @param {object} params
 */
export const setDataToForm = (params) => async (dispatch) => {
  await dispatch(setFormData(params));

  return Promise.resolve(true);
};

/**
 * Dispatch publish action and return updated shift
 * @param {number} id shift id;
 */
export const publishShift = (params, id) => (dispatch, getState) => dispatch(publish(params, id))
  .then(() => getState().gig.current);

/**
 * Load shifts for new planning
 * @param {object} params
 */
export const loadShifts = (params) => (dispatch, getState) => {
  // save number of requests
  const count = getState().gig.countRequest + 1;
  dispatch({ type: INCREASE_COUNT, count });

  return dispatch(load(params, count));
};

export const checkUpdatedShifts = (params) => (dispatch) => dispatch(loadUpdatedGig(params));

export const loadWorkers = (paramsUrl = '') => (dispatch, getState) => {
  // save number of load workers for gig requests
  const count = getState().gig.workersForGig.countRequest + 1;
  dispatch({ type: INCREASE_REQ_WORKERS_COUNT, count });

  return dispatch(getWorkers(paramsUrl, count));
};

export const loadWorkersWithGig = (params) => (dispatch, getState) => {
  // save number of requests
  const count = getState().gig.workersEntity.countRequest + 1;
  dispatch({ type: INCREASE_WORKER_COUNT, count });

  return dispatch(loadWorkersEntity(params, count));
};

export const checkAvailability = (params) => (dispatch) => {
  const request = {};
  request.schedule = [];
  request.schedule.start_at = format(params.startTime, 'HH:mm');
  request.schedule.finish_at = format(params.finishTime, 'HH:mm');

  if (params.isNight) {
    request.schedule.night = true;
    request.schedule.finish_at = '23:59';
    request.schedule.night_start_at = '00:00';
    request.schedule.night_finish_at = format(params.finishTime, 'HH:mm');
  }

  const datesParams = {};
  datesParams.schedule = {};
  datesParams.schedule.dates = [];
  datesParams.schedule.dates[0] = {};
  datesParams.schedule.dates[0].date = format(params.date, 'yyyy-MM-dd');

  if (params.isNight) {
    const nightDate = addDays(params.date, 1);
    datesParams.schedule.dates[0].night_date = format(nightDate, 'yyyy-MM-dd');
  }

  const isAssignType = params.scheduleType === 'assign';
  if (isAssignType && params.isMultiDay && !isEmpty(params.multiDays)) {
    params.multiDays.filter((multiDay) => !isSameDay(multiDay, params.date))
      .forEach((item, index) => {
        const nightMultiDate = addDays(item, 1);
        datesParams.schedule.dates[index + 1] = [];
        datesParams.schedule.dates[index + 1].date = format(item, 'yyyy-MM-dd');
        if (params.isNight) {
          datesParams.schedule.dates[index + 1].night_date = format(nightMultiDate, 'yyyy-MM-dd');
        }
      });
  }

  const datesParamsQuery = serializeParamsToQueryString(datesParams);

  // For shift detail
  if (params.gig_group && !params.gig_group.single_gig_acceptability) {
    request.repeat = [];
    request.repeat.type = params.gig_group.repeat_params.type;

    if (params.gig_group.start_at) {
      request.repeat.start_at = params.gig_group.start_at;
    }
    if (params.gig_group.finish_at) {
      request.repeat.finish_at = params.gig_group.finish_at;
    }

    if (params.gig_group.repeat_params.days) {
      request.repeat.days = params.gig_group.repeat_params.days;
    }
  }

  // For create shift
  if (params.repeat && params.repeat.show && !params.repeat.acceptability) {
    request.repeat = [];
    request.repeat.type = params.repeat.type;

    const finishDate = format(params.repeat.finish, 'yyyy-MM-dd');

    if (params.repeat.type === globalConst.DAY_STR) {
      if (params.repeat.endType === globalConst.END_TYPE_ON) {
        request.repeat.finish_at = finishDate;
      }

      if (params.repeat.endType === globalConst.END_TYPE_AFTER) {
        request.repeat.occurrences = params.repeat.occurrences;
      }
    }

    if (params.repeat.type === globalConst.WEEK_STR || params.repeat.type === globalConst.BIWEEKLY_STR) {
      request.repeat.days = params.repeat.days;
      request.repeat.finish_at = finishDate;
    }

    if (params.repeat.type === globalConst.WEEKDAY_STR) {
      request.repeat.type = globalConst.WEEK_STR;
      request.repeat.days = globalConst.WEEKDAY_ARR;
      request.repeat.finish_at = finishDate;
    }
  }

  const paramsUrl = serializeParamsToQueryString(request);
  const data = {
    workerId: params.workerId,
    query: `${paramsUrl}&${datesParamsQuery}`,
  };

  return dispatch(loadWorkerAvailabilityById(data));
};

/**
 * MUTATIONS
 */

const setDataToFormSuccess = (action) => (state) => ({
  ...state,
  creatingGigInfo: {
    ...initialState.creatingGigInfo,
    ...action.params,
  },
});

const updateRequestOnLoad = (value) => (state) => ({
  ...state,
  entities: {
    ...state.entities,
    loading: value,
  }
});

const loadGigSuccess = (action) => (state) => {
  const { data, included, meta } = action.result;
  const locationsIncl = included?.filter((item) => item.type === 'location')
    .map((item) => ({
      ...item.attributes,
    }));
  const rolesIncl = included?.filter((item) => item.type === 'role')
    .map((role) => role.attributes);
  const involvedWorkerIncl = included?.filter((item) => item.type === 'involved_worker')
    .map((worker) => worker.attributes);

  const rolesWorkerIncl = included?.filter((item) => item.type === 'roles_worker')
    .map((item) => {
      const rolesIds = item.relationships.roles?.data.map((role) => +role.id) || [];
      const roles = rolesIncl.filter((includeRole) => rolesIds.includes(includeRole.id));

      const allInvolvedWorkersIds = item.relationships.all_involved_workers?.data.map((worker) => +worker.id) || [];
      const allInvolvedWorkers = involvedWorkerIncl
        .filter((includeWorker) => allInvolvedWorkersIds.includes(includeWorker.id));

      return {
        ...item.attributes,
        roles,
        all_involved_workers: allInvolvedWorkers,
      };
    });

  const customAttribute = included?.filter((item) => item.type === 'custom_attribute') || [];
  const normalizeAttribute = customAttribute.reduce((obj, item) => ({ ...obj, [item.id]: item.attributes }), {});
  const customGigAttributeIncl = included?.filter((item) => item.type === 'custom_gig_attribute')
    .map((item) => ({
      ...item.attributes,
      custom_attribute: normalizeAttribute[item.relationships.custom_attribute.data.id],
    }));

  const prepareGigs = data.map((item) => {
    const locationId = item.relationships.location?.data.id || '';
    const locationItem = locationsIncl?.find((loc) => loc.id === +locationId) || {};

    const rolesWorkersIds = item.relationships.roles_workers?.data.map((roleWorker) => +roleWorker.id) || [];
    const rolesItems = rolesWorkerIncl.filter((includeRole) => rolesWorkersIds.includes(includeRole.id));

    const attributesIds = item.relationships.custom_gig_attributes?.data.map((gigAttribute) => +gigAttribute.id) || [];
    const attributesItems = customGigAttributeIncl.filter((includeAttr) => attributesIds.includes(includeAttr.id));

    return {
      ...item.attributes,
      location: locationItem,
      roles_workers: rolesItems,
      attributes: attributesItems,
    };
  });

  // check, if last request's number greater than response's number,
  // then skip it
  if (state.countRequest > action.count) {
    return ({
      ...state,
      isUpdatedGigs: false,
    });
  }

  return ({
    ...state,
    isUpdatedGigs: false,
    entities: {
      loading: false,
      loaded: true,
      list: prepareGigs,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : prepareGigs.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      }
    },
  });
};

const updateRequestOnLoadFail = (action, value) => (state) => ({
  ...state,
  entities: {
    ...state.entities,
    loading: value,
    error: action.error
  },
});

const loadUpdatedGigsSuccess = (action) => (state) => {
  const { data } = action.result;

  const prepareGigs = data.map((item) => item.attributes);
  let isUpdatedGigs = false;

  state.entities.list.filter((item) => {
    for (let i = 0, arrLength = prepareGigs.length; i < arrLength; i++) {
      if (prepareGigs[i].id === item.id && prepareGigs[i].status !== item.status) {
        isUpdatedGigs = true;
      }
    }

    return true;
  });

  return {
    ...state,
    isUpdatedGigs,
  };
};

const loadGigByIdSuccess = (action) => (state) => {
  const { gig } = action.result;
  const expireDueDate = gig.expire_due_date ? new Date(gig.expire_due_date.substring(0, 16)) : '';

  // if exist current gig, and second load is different gig, set def for current
  if (state.current.id && state.current.id !== gig.id) {
    return ({
      ...state,
      current: {
        location: {},
        workers: [],
        invited_workers: [],
        declined_workers: [],
        rejected_workers: [],
      }
    });
  }

  const roles = gig.roles ? gig.roles : state.current.roles;
  const location = gig.location ? gig.location : state.current.location;

  return ({
    ...state,
    current: {
      ...state.current,
      ...gig,
      location, // if remove location from org
      roles,
      expire_due_date: expireDueDate,
    }
  });
};

const sendCommentSuccess = (action) => (state) => {
  const updatedList = state.entities.list.map((shift) => {
    if (shift.id === action.data.gig_id) {
      return {
        ...shift,
        commentable_count: ++shift.commentable_count,
      };
    }

    return shift;
  });

  return ({
    ...state,
    entities: {
      ...state.entities,
      list: updatedList,
    }
  });
};

const removeSuccess = (action) => (state) => {
  const filterArray = state.entities.list.filter((obj) => obj.id !== action.id);

  return ({
    ...state,
    entities: {
      ...state.entities,
      list: filterArray
    },
    current: {
      workers: [],
      location: {},
      invited_workers: [],
      declined_workers: [],
      rejected_workers: [],
    }
  });
};

const loadWorkersSuccess = (action) => (state) => {
  // check, if last request's number greater than response's number,
  // then skip it
  if (state.workersForGig.countRequest > action.count) {
    return ({
      ...state,
    });
  }

  return ({
    ...state,
    workersForGig: {
      ...state.workersForGig,
      workers: [
        ...action.result.workers,
      ],
    },
  });
};

const availabilityWorkerSuccess = (action) => (state) => {
  const { workers } = state.workersForGig;
  const { worker } = action.result;

  const updatedFiltered = workers.map((item) => {
    if (item.id === worker.id) {
      return {
        ...item,
        ...worker,
      };
    }

    return item;
  });

  return ({
    ...state,
    workersForGig: {
      ...state.workersForGig,
      workers: [
        ...updatedFiltered,
      ],
    }
  });
};

const loadWorkersEntitySuccess = (action) => (state) => {
  // check, if last request's number greater than response's number,
  // then skip it
  if (state.workersEntity.countRequest > action.count) {
    return ({
      ...state,
    });
  }

  const {
    workers,
    meta,
  } = action.result;

  let updId = [];
  const normalize = {};

  workers.forEach((item) => {
    if (!checkItemInArray(updId, item.id)) {
      updId = [...updId, item.id];
    }

    const updateForeignGigs = !isEmpty(item.foreign_gigs) && item.foreign_gigs.map((foreign) => (
      {
        ...foreign,
        status: globalConst.BUSY_FOREIGN_STATUS
      }
    ));

    const updateTimeOffs = !isEmpty(item.time_offs) && item.time_offs.map((timeOff) => (
      {
        ...timeOff,
        status: globalConst.TIME_OFF
      }
    ));

    normalize[item.id] = {
      ...item,
      title: `${item.first_name} ${item.last_name}`,
      initial: `${item.first_name.charAt(0)}${item.last_name.charAt(0)}`,
      foreign_gigs: updateForeignGigs || [],
      time_offs: updateTimeOffs || [],
      availability: item.availability || [],
    };
  });

  return ({
    ...state,
    workersEntity: {
      ...state.workersEntity,
      allIds: updId,
      byId: normalize,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      }
    },
  });
};

const rejectWorkerSuccess = (action) => (state) => {
  const filterArray = state.current.workers.filter((obj) => obj.id !== action.data.rejectId);

  return ({
    ...state,
    current: {
      ...state.current,
      workers: filterArray
    },
  });
};

/**
 * Start import gigs
 * @param {object} action
 */
export const startImportGigs = () => (state) => ({
  ...state,
  import: {
    ...state.import,
    file: 'document.xlsx',
  }
});

const importGigsSuccess = (action) => (state) => {
  const { successful } = action.result.data.attributes;
  const list = action.result.data.attributes.invalid_list;
  const { byId, allIds } = prepareShiftToFixing(list);

  return ({
    ...state,
    import: {
      ...state.import,
      successful,
      byId,
      allIds,
    }
  });
};

const importGigsFail = () => (state) => ({
  ...state,
  import: {
    ...state.import,
  }
});

const addImportSuccess = (action) => (state) => {
  const { data } = action;
  return ({
    ...state,
    import: {
      ...state.import,
      byId: {
        ...state.import.byId,
        [data.id]: data,
      },
      allIds: [
        ...state.import.allIds,
        data.id,
      ]
    }
  });
};
/**
 * Actions success with templates
 */

const loadTemplatesSuccess = (action) => (state) => {
  const { data, included } = action.result;

  const templates = data
    .map((temp) => ({
      ...temp.attributes,
      roles: temp.relationships.roles,
      category: included
        .filter((el) => el.type === 'gig_template_gig_category'
      && temp.relationships.gig_template_gig_category.data?.id === el.id),
      custom_gig_template_attribute: included
        .filter((el) => el.type === 'custom_gig_template_attribute'
        && temp.relationships.custom_gig_template_attributes.data
          .map((attr) => attr.id).includes(el.id))
        .map((template) => ({
          ...template,
          relationships: included
            .find((incl) => template.relationships.custom_attribute.data.id === incl.id
            && incl.type === 'custom_attribute')
        }))
    }));

  return ({
    ...state,
    shiftTemplates: [...templates]
  });
};

const deleteTemplteSuccess = (action) => (state) => {
  const { id } = action;
  const filtered = state.shiftTemplates.filter((el) => el.id !== id);
  return ({
    ...state,
    shiftTemplates: [...filtered]
  });
};

const saveTemplateSuccess = (action) => (state) => {
  const { data, included } = action.result;
  const newTemplate = {
    ...data.attributes,
    roles: data.relationships.roles,
    category: included
      .filter((el) => el.type === 'gig_template_gig_category'
  && data.relationships.gig_template_gig_category.data?.id === el.id),
    custom_gig_template_attribute: included
      .filter((el) => el.type === 'custom_gig_template_attribute'
    && data.relationships.custom_gig_template_attributes.data
      .map((attr) => attr.id).includes(el.id))
      .map((template) => ({
        ...template,
        relationships: included
          .find((incl) => template.relationships.custom_attribute.data.id === incl.id
        && incl.type === 'custom_attribute')
      }))
  };
  return ({
    ...state,
    shiftTemplates: [...state.shiftTemplates, newTemplate]
  });
};

const copySlotSuccess = (action) => (state) => {
  const newId = uniqueId('copyShift');
  const shift = {
    ...action.slot,
    id: newId,
    workers: action.slot.schedule_type === globalConst.ASSIGN_TYPE ? [] : action.slot.workers,
  };

  return ({
    ...state,
    import: {
      ...state.import,
      byId: {
        ...state.import.byId,
        [shift.id]: shift,
      },
      allIds: [...state.import.allIds, shift.id],
    }
  });
};

const removeImportSuccess = (action) => (state) => {
  const filteredAllIds = state.import.allIds.filter((id) => id !== action.id);

  delete state.import.byId[action.id];

  return ({
    ...state,
    import: {
      ...state.import,
      allIds: filteredAllIds,
      byId: {
        ...state.import.byId,
      },
    }
  });
};

const clearImportSuccess = () => (state) => ({
  ...state,
  import: {
    file: '',
    successful: 0,
    allIds: [],
    byId: {},
  },
});

/**
 * Assign worker
 */
const assignWorkerSuccess = () => (state) => ({
  ...state,
});

const changeViewSuccess = (action) => (state) => ({
  ...state,
  calendar: {
    ...state.calendar,
    ...action.params,
  },
});

const setDefaultCreateSuccess = () => (state) => ({
  ...state,
  creatingGigInfo: {
    ...initialState.creatingGigInfo,
  }
});

const setCountSuccess = (action) => (state) => ({
  ...state,
  countRequest: action.count
});

const setWorkerCountSuccess = (action) => (state) => ({
  ...state,
  workersEntity: {
    ...state.workersEntity,
    countRequest: action.count
  },
});

const setCountWorkersSuccess = (action) => (state) => ({
  ...state,
  workersForGig: {
    ...state.workersForGig,
    countRequest: action.count
  },
});

const clearCurrentSuccess = () => (state) => ({
  ...state,
  current: {
    workers: [],
    location: {},
    invited_workers: [],
    declined_workers: [],
    rejected_workers: [],
  },
  workersForGig: {
    workers: [],
    params: {},
    countRequest: 0,
  },
});

const setQuerySuccess = (action) => (state) => ({
  ...state,
  query: {
    ...action.query,
  }
});

const setDefaultSuccess = () => initialState;

const setDefaultGigEntitySuccess = () => (state) => ({
  ...state,
  entities: {
    ...initialState.entities,
  }
});

const actionsLookup = {
  [SET_FORM_DATE]: (state, action) => setDataToFormSuccess(action)(state),
  [INCREASE_COUNT]: (state, action) => setCountSuccess(action)(state),
  [INCREASE_WORKER_COUNT]: (state, action) => setWorkerCountSuccess(action)(state),
  [INCREASE_REQ_WORKERS_COUNT]: (state, action) => setCountWorkersSuccess(action)(state),
  [CHANGE_VIEW]: (state, action) => changeViewSuccess(action)(state),
  [LOAD]: (state) => updateRequestOnLoad(true)(state),
  [LOAD_SUCCESS]: (state, action) => loadGigSuccess(action)(state),
  [LOAD_FAIL]: (state, action) => updateRequestOnLoadFail(action, false)(state),
  [LOAD_UPDATED_GIGS_SUCCESS]: (state, action) => loadUpdatedGigsSuccess(action)(state),
  [LOAD_GIG_SUCCESS]: (state, action) => loadGigByIdSuccess(action)(state),
  [LOAD_TEMPLATE_SUCCESS]: (state, action) => loadTemplatesSuccess(action)(state),
  [DELETE_TEMPLATE_SUCCESS]: (state, action) => deleteTemplteSuccess(action)(state),
  [SAVE_TEMPLATE_SUCCESS]: (state, action) => saveTemplateSuccess(action)(state),
  [UPDATE_GIG_SUCCESS]: (state, action) => loadGigByIdSuccess(action)(state),
  [SEND_COMMENT_SUCCESS]: (state, action) => sendCommentSuccess(action)(state),
  [REMOVE_SUCCESS]: (state, action) => removeSuccess(action)(state),
  [LOAD_WORKERS_SUCCESS]: (state, action) => loadWorkersSuccess(action)(state),
  [LOAD_WORKERS_ENTITY_SUCCESS]: (state, action) => loadWorkersEntitySuccess(action)(state),
  [REJECT_WORKER_SUCCESS]: (state, action) => rejectWorkerSuccess(action)(state),
  [ASSIGN_WORKER_SUCCESS]: (state) => assignWorkerSuccess()(state),
  [AVAILABILITY_WORKER_SUCCESS]: (state, action) => availabilityWorkerSuccess(action)(state),
  [SET_DEFAULT_CREATE]: (state) => setDefaultCreateSuccess()(state),
  [CLEAR_CURRENT]: (state) => clearCurrentSuccess()(state),
  [IMPORT_GIGS]: (state) => startImportGigs()(state),
  [IMPORT_GIGS_SUCCESS]: (state, action) => importGigsSuccess(action)(state),
  [IMPORT_GIGS_FAIL]: (state) => importGigsFail()(state),
  [ADD_IMPORT_GIG]: (state, action) => addImportSuccess(action)(state),
  [REMOVE_IMPORT_GIG]: (state, action) => removeImportSuccess(action)(state),
  [COPY_IMPORT_SLOT]: (state, action) => copySlotSuccess(action)(state),
  [CLEAR_IMPORT]: (state) => clearImportSuccess()(state),
  [SET_QUERY]: (state, action) => setQuerySuccess(action)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
  [SET_DEFAULT_GIG_ENTITY]: (state) => setDefaultGigEntitySuccess()(state),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}
