import { combineReducers } from 'redux';
import auth from './auth';
import dashboard from './dashboard';
import filter from './filter';
import gig from './gig';
import invitee from './invitee';
import info from './info';
import localization from './localization';
import manager from './manager';
import notifications from './notifications';
import organization from './organization';
import project from './project';
import report from './report';
import roles from './roles';
import settings from './settings';
import timetracker from './timetracker';
import worker from './worker';
import review from './review';
import billing from './billing';
import invoiceNumbers from './invoiceNumbers';
import agreements from './agreements';

const rootReducer = () => combineReducers({
  auth,
  dashboard,
  filter,
  gig,
  invitee,
  info,
  localization,
  manager,
  notifications,
  organization,
  project,
  report,
  review,
  roles,
  settings,
  timetracker,
  invoiceNumbers,
  worker,
  billing,
  agreements,
});

export default rootReducer;
