import isFunction from 'lodash/isFunction';
import globalConst from '../../constants';
import { checkItemInArray } from '../../utils/utils';

const LOAD = 'redux-ducks/report/LOAD';
const LOAD_SUCCESS = 'redux-ducks/report/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-ducks/report/LOAD_FAIL';

const CREATE = 'redux-ducks/report/CREATE';
const CREATE_SUCCESS = 'redux-ducks/report/CREATE_SUCCESS';
const CREATE_FAIL = 'redux-ducks/report/CREATE_FAIL';

const UPDATE = 'redux-ducks/report/UPDATE';
const UPDATE_SUCCESS = 'redux-ducks/report/UPDATE_SUCCESS';
const UPDATE_FAIL = 'redux-ducks/report/UPDATE_FAIL';

const DELETE = 'redux-ducks/report/DELETE';
const DELETE_SUCCESS = 'redux-ducks/report/DELETE_SUCCESS';
const DELETE_FAIL = 'redux-ducks/report/DELETE_FAIL';

const DELETE_MULTI = 'redux-ducks/report/DELETE_MULTI';
const DELETE_MULTI_SUCCESS = 'redux-ducks/report/DELETE_MULTI_SUCCESS';
const DELETE_MULTI_FAIL = 'redux-ducks/report/DELETE_MULTI_FAIL';

// BILLING
const LOAD_BILLING = 'redux-ducks/report/LOAD_BILLING';
const LOAD_BILLING_SUCCESS = 'redux-ducks/report/LOAD_BILLING_SUCCESS';
const LOAD_BILLING_FAIL = 'redux-ducks/report/LOAD_BILLING_FAIL';

const CREATE_BILLING = 'redux-ducks/report/CREATE_BILLING';
const CREATE_BILLING_SUCCESS = 'redux-ducks/report/CREATE_BILLING_SUCCESS';
const CREATE_BILLING_FAIL = 'redux-ducks/report/CREATE_BILLING_FAIL';

const UPDATE_BILLING = 'redux-ducks/report/UPDATE_BILLING';
const UPDATE_BILLING_SUCCESS = 'redux-ducks/report/UPDATE_BILLING_SUCCESS';
const UPDATE_BILLING_FAIL = 'redux-ducks/report/UPDATE_BILLING_FAIL';

const DELETE_BILLING = 'redux-ducks/report/DELETE_BILLING';
const DELETE_BILLING_SUCCESS = 'redux-ducks/report/DELETE_BILLING_SUCCESS';
const DELETE_BILLING_FAIL = 'redux-ducks/report/DELETE_BILLING_FAIL';

const DELETE_MULTI_BILLING = 'redux-ducks/report/DELETE_MULTI_BILLING';
const DELETE_MULTI_BILLING_SUCCESS = 'redux-ducks/report/DELETE_MULTI_BILLING_SUCCESS';
const DELETE_MULTI_BILLING_FAIL = 'redux-ducks/report/DELETE_MULTI_BILLING_FAIL';

// ORT
const GET_ORT_SCHEMAS = 'redux-ducks/report/GET_ORT_SCHEMAS';
const GET_ORT_SCHEMAS_SUCCESS = 'redux-ducks/report/GET_ORT_SCHEMAS_SUCCESS';
const GET_ORT_SCHEMAS_FAIL = 'redux-ducks/report/GET_ORT_SCHEMAS_FAIL';

const ADD_ORT_SCHEMA = 'redux-ducks/report/ADD_ORT_SCHEMA';
const ADD_ORT_SCHEMA_SUCCESS = 'redux-ducks/report/ADD_ORT_SCHEMA_SUCCESS';
const ADD_ORT_SCHEMA_FAIL = 'redux-ducks/report/ADD_ORT_SCHEMA_FAIL';

const UPDATE_ORT_SCHEMA = 'redux-ducks/report/UPDATE_ORT_SCHEMA';
const UPDATE_ORT_SCHEMA_SUCCESS = 'redux-ducks/report/UPDATE_ORT_SCHEMA_SUCCESS';
const UPDATE_ORT_SCHEMA_FAIL = 'redux-ducks/report/UPDATE_ORT_SCHEMA_FAIL';

const REMOVE_ORT_SCHEMA = 'redux-ducks/report/REMOVE_ORT_SCHEMA';
const REMOVE_ORT_SCHEMA_SUCCESS = 'redux-ducks/report/REMOVE_ORT_SCHEMA_SUCCESS';
const REMOVE_ORT_SCHEMA_FAIL = 'redux-ducks/report/REMOVE_ORT_SCHEMA_FAIL';

const ADD_ORT_RULE = 'redux-ducks/report/ADD_ORT_RULE';
const ADD_ORT_RULE_SUCCESS = 'redux-ducks/report/ADD_ORT_RULE_SUCCESS';
const ADD_ORT_RULE_FAIL = 'redux-ducks/report/ADD_ORT_RULE_FAIL';

const UPDATE_ORT_RULE = 'redux-ducks/report/UPDATE_ORT_RULE';
const UPDATE_ORT_RULE_SUCCESS = 'redux-ducks/report/UPDATE_ORT_RULE_SUCCESS';
const UPDATE_ORT_RULE_FAIL = 'redux-ducks/report/UPDATE_ORT_RULE_FAIL';

const REMOVE_ORT_RULE = 'redux-ducks/report/REMOVE_ORT_RULE';
const REMOVE_ORT_RULE_SUCCESS = 'redux-ducks/report/REMOVE_ORT_RULE_SUCCESS';
const REMOVE_ORT_RULE_FAIL = 'redux-ducks/report/REMOVE_ORT_RULE_FAIL';

// BILLING
const GET_BILLING_SCHEMAS = 'redux-ducks/report/GET_BILLING_SCHEMAS';
const GET_BILLING_SCHEMAS_SUCCESS = 'redux-ducks/report/GET_BILLING_SCHEMAS_SUCCESS';
const GET_BILLING_SCHEMAS_FAIL = 'redux-ducks/report/GET_BILLING_SCHEMAS_FAIL';

const ADD_BILLING_SCHEMA = 'redux-ducks/report/ADD_BILLING_SCHEMA';
const ADD_BILLING_SCHEMA_SUCCESS = 'redux-ducks/report/ADD_BILLING_SCHEMA_SUCCESS';
const ADD_BILLING_SCHEMA_FAIL = 'redux-ducks/report/ADD_BILLING_SCHEMA_FAIL';

const UPDATE_BILLING_SCHEMA = 'redux-ducks/report/UPDATE_BILLING_SCHEMA';
const UPDATE_BILLING_SCHEMA_SUCCESS = 'redux-ducks/report/UPDATE_BILLING_SCHEMA_SUCCESS';
const UPDATE_BILLING_SCHEMA_FAIL = 'redux-ducks/report/UPDATE_BILLING_SCHEMA_FAIL';

const REMOVE_BILLING_SCHEMA = 'redux-ducks/report/REMOVE_BILLING_SCHEMA';
const REMOVE_BILLING_SCHEMA_SUCCESS = 'redux-ducks/report/REMOVE_BILLING_SCHEMA_SUCCESS';
const REMOVE_BILLING_SCHEMA_FAIL = 'redux-ducks/report/REMOVE_BILLING_SCHEMA_FAIL';

const ADD_BILLING_RULE = 'redux-ducks/report/ADD_BILLING_RULE';
const ADD_BILLING_RULE_SUCCESS = 'redux-ducks/report/ADD_BILLING_RULE_SUCCESS';
const ADD_BILLING_RULE_FAIL = 'redux-ducks/report/ADD_BILLING_RULE_FAIL';

const UPDATE_BILLING_RULE = 'redux-ducks/report/UPDATE_BILLING_RULE';
const UPDATE_BILLING_RULE_SUCCESS = 'redux-ducks/report/UPDATE_BILLING_RULE_SUCCESS';
const UPDATE_BILLING_RULE_FAIL = 'redux-ducks/report/UPDATE_BILLING_RULE_FAIL';

const REMOVE_BILLING_RULE = 'redux-ducks/report/REMOVE_BILLING_RULE';
const REMOVE_BILLING_RULE_SUCCESS = 'redux-ducks/report/REMOVE_BILLING_RULE_SUCCESS';
const REMOVE_BILLING_RULE_FAIL = 'redux-ducks/report/REMOVE_BILLING_RULE_FAIL';

const LOAD_BILLING_ROLE = 'redux-ducks/report/LOAD_BILLING_ROLE';
const LOAD_BILLING_ROLE_SUCCESS = 'redux-ducks/report/LOAD_BILLING_ROLE_SUCCESS';
const LOAD_BILLING_ROLE_FAIL = 'redux-ducks/report/LOAD_BILLING_ROLE_FAIL';

const UPDATE_BILLING_ROLE = 'redux-ducks/report/UPDATE_BILLING_ROLE';
const UPDATE_BILLING_ROLE_SUCCESS = 'redux-ducks/report/UPDATE_BILLING_ROLE_SUCCESS';
const UPDATE_BILLING_ROLE_FAIL = 'redux-ducks/report/UPDATE_BILLING_ROLE_FAIL';

const REMOVE_BILLING_ROLE = 'redux-ducks/report/REMOVE_BILLING_ROLE';
const REMOVE_BILLING_ROLE_SUCCESS = 'redux-ducks/report/REMOVE_BILLING_ROLE_SUCCESS';
const REMOVE_BILLING_ROLE_FAIL = 'redux-ducks/report/REMOVE_BILLING_ROLE_FAIL';

const SAVE_PREFILLED_EXPENSE = 'redux-ducks/settings/SAVE_PREFILLED_EXPENSE';
const SAVE_PREFILLED_EXPENSE_SUCCESS = 'redux-ducks/settings/SAVE_PREFILLED_EXPENSE_SUCCESS';
const SAVE_PREFILLED_EXPENSE_FAIL = 'redux-ducks/settings/SAVE_PREFILLED_EXPENSE_FAIL';

const REMOVE_PREFILLED_EXPENSE = 'redux-ducks/settings/REMOVE_PREFILLED_EXPENSE';
const REMOVE_PREFILLED_EXPENSE_SUCCESS = 'redux-ducks/settings/REMOVE_PREFILLED_EXPENSE_SUCCESS';
const REMOVE_PREFILLED_EXPENSE_FAIL = 'redux-ducks/settings/REMOVE_PREFILLED_EXPENSE_FAIL';

const EDIT_PREFILLED_EXPENSE = 'redux-ducks/settings/EDIT_PREFILLED_EXPENSE';
const EDIT_PREFILLED_EXPENSE_SUCCESS = 'redux-ducks/settings/EDIT_PREFILLED_EXPENSE_SUCCESS';
const EDIT_PREFILLED_EXPENSE_FAIL = 'redux-ducks/settings/EDIT_PREFILLED_EXPENSE_FAIL';

export const SET_DEFAULT = 'redux-ducks/report/SET_DEFAULT';

const initialState = {
  reportEntity: {
    allIds: [],
    byId: {},
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    },
  },
  billingReportEntity: {
    allIds: [],
    byId: {},
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    },
  },
  ortSchemaEntity: {
    allIds: [],
    byId: {},
    byRuleId: {},
    loading: false,
  },
  billingEntity: {
    allIds: [],
    byId: {},
    byRuleId: {},
  },
};

/**
 * ACTIONS
 */

//
// Reports
// --------------------------------

/**
 * Load reports
 * @param {object} params
 */
export const loadReports = (params) => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get('v3/planners/reports', { params }),
  params,
});

/**
 * Generate report
 * @param {object} params
 */
export const generateReport = (data) => ({
  types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
  promise: (client) => client.post('v3/planners/reports', { data }),
});

/**
 * Update report
 * @param {object} params
 */
export const updateReport = (data) => ({
  types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
  promise: (client) => client.patch(`v3/planners/reports/${data.id}`, { data }),
  data,
});

/**
 * Delete report by id
 * @param {number} id reportId
 */
export const deleteReportById = (id) => ({
  types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
  promise: (client) => client.del(`v3/planners/reports/${id}`),
  id,
});

/**
 * Remove multiple
 */
export const deleteReports = (data) => ({
  types: [DELETE_MULTI, DELETE_MULTI_SUCCESS, DELETE_MULTI_FAIL],
  promise: (client) => client.del('v3/planners/reports/delete_multiple', { data }),
});

//
// Billing reports
// --------------------------------

/**
 * Load billing reports
 * @param {object} params
 */
export const loadBillingReports = (params) => ({
  types: [LOAD_BILLING, LOAD_BILLING_SUCCESS, LOAD_BILLING_FAIL],
  promise: (client) => client.get('v3/planners/billing_reports', { params }),
  params,
});

/**
 * Generate report
 * @param {object} params
 */
export const generateBillingReport = (data) => ({
  types: [CREATE_BILLING, CREATE_BILLING_SUCCESS, CREATE_BILLING_FAIL],
  promise: (client) => client.post('v3/planners/billing_reports', { data }),
});

/**
 * Update report
 * @param {object} params
 */
export const updateBillingReport = (data) => ({
  types: [UPDATE_BILLING, UPDATE_BILLING_SUCCESS, UPDATE_BILLING_FAIL],
  promise: (client) => client.patch(`v3/planners/billing_reports/${data.id}`, { data }),
  data,
});

/**
 * Delete report by id
 * @param {number} id reportId
 */
export const deleteBillingReportById = (id) => ({
  types: [DELETE_BILLING, DELETE_BILLING_SUCCESS, DELETE_BILLING_FAIL],
  promise: (client) => client.del(`v3/planners/billing_reports/${id}`),
  id,
});

/**
 * Remove multiple
 */
export const deleteBillingReports = (data) => ({
  types: [DELETE_MULTI_BILLING, DELETE_MULTI_BILLING_SUCCESS, DELETE_MULTI_BILLING_FAIL],
  promise: (client) => client.del('v3/planners/billing_reports/delete_multiple', { data }),
});

//
// Org schema
// --------------------------------

/**
 * Get ort schemas
 */
export const getOrtSchemas = (include = '') => ({
  types: [GET_ORT_SCHEMAS, GET_ORT_SCHEMAS_SUCCESS, GET_ORT_SCHEMAS_FAIL],
  promise: (client) => client.get(`v3/planners/ort_schemas?${include}`),
});

/**
 * Add ort schema
 * @param {data} data ort schema
 */
export const addOrtSchema = (data) => ({
  types: [ADD_ORT_SCHEMA, ADD_ORT_SCHEMA_SUCCESS, ADD_ORT_SCHEMA_FAIL],
  promise: (client) => client.post('v3/planners/ort_schemas', { data }),
});

/**
 * Update ort schema
 * @param {data} params ort schema
 */
export const updateOrtSchema = (data) => {
  const include = 'include=ort_schema_custom_attributes_workers';
  return ({
    types: [UPDATE_ORT_SCHEMA, UPDATE_ORT_SCHEMA_SUCCESS, UPDATE_ORT_SCHEMA_FAIL],
    promise: (client) => client.patch(`v3/planners/ort_schemas/${data.ort_schema_id}?${include}`, { data }),
    data,
  });
};

/**
 * Remove ort schema by id
 * @param {number} id ort schema id
 */
export const removeOrtSchema = (id) => ({
  types: [REMOVE_ORT_SCHEMA, REMOVE_ORT_SCHEMA_SUCCESS, REMOVE_ORT_SCHEMA_FAIL],
  promise: (client) => client.del(`v3/planners/ort_schemas/${id}`),
  id,
});

/**
 * Save rule for ort schema
 * @param {object} data
 */
export const saveOrtSchemaRule = (data) => ({
  types: [ADD_ORT_RULE, ADD_ORT_RULE_SUCCESS, ADD_ORT_RULE_FAIL],
  promise: (client) => client.post('v3/planners/ort_rules', { data }),
  data,
});

/**
 * Update rule in ort schema
 * @param {object} data
 */
export const updateOrtSchemaRule = (data) => ({
  types: [UPDATE_ORT_RULE, UPDATE_ORT_RULE_SUCCESS, UPDATE_ORT_RULE_FAIL],
  promise: (client) => client.patch(`v3/planners/ort_rules/${data.ort_rule.id}`, { data }),
});

/**
 * Remove rule from ort schema
 * @param {object} params
 */
export const removeOrtRule = (params) => ({
  types: [REMOVE_ORT_RULE, REMOVE_ORT_RULE_SUCCESS, REMOVE_ORT_RULE_FAIL],
  promise: (client) => client.del(`v3/planners/ort_rules/${params.ruleId}`),
  params,
});

//
// BILLING
// --------------------------------
export const getBillingSchemas = (data) => ({
  types: [GET_BILLING_SCHEMAS, GET_BILLING_SCHEMAS_SUCCESS, GET_BILLING_SCHEMAS_FAIL],
  promise: (client) => client.get(`v3/planners/billing_agreements?locale=${data.locale}&${data.include}`),
});

export const createBillingSchema = (data) => ({
  types: [ADD_BILLING_SCHEMA, ADD_BILLING_SCHEMA_SUCCESS, ADD_BILLING_SCHEMA_FAIL],
  promise: (client) => client.post('v3/planners/billing_agreements', { data }),
});

export const updateBillingSchema = (data) => {
  const defInclude = 'ort_schema_custom_attributes_workers,';
  const ortSchemaRoles = 'ort_schema_roles,ort_schema_roles.rule_role_rates';
  const expensesInclude = data.billing_agreement?.include_expenses ? ',pre_filled_expenses' : '';
  const billingInfoInclude = ',billing_info';
  const include = `include=${defInclude}${ortSchemaRoles}${expensesInclude}${billingInfoInclude}`;
  return ({
    types: [UPDATE_BILLING_SCHEMA, UPDATE_BILLING_SCHEMA_SUCCESS, UPDATE_BILLING_SCHEMA_FAIL],
    promise: (client) => client.patch(`v3/planners/billing_agreements/${data.ort_schema_id}?${include}`, { data }),
    data,
  });
};

export const removeBillingSchema = (id) => ({
  types: [REMOVE_BILLING_SCHEMA, REMOVE_BILLING_SCHEMA_SUCCESS, REMOVE_BILLING_SCHEMA_FAIL],
  promise: (client) => client.del(`v3/planners/billing_agreements/${id}`),
  id,
});

/**
 * Save rule for billing schema
 * @param {object} data
 */
export const saveBillingSchemaRule = (data) => ({
  types: [ADD_BILLING_RULE, ADD_BILLING_RULE_SUCCESS, ADD_BILLING_RULE_FAIL],
  promise: (client) => client.post('v3/planners/ort_rules', { data }),
  data,
});

/**
 * Update rule in billing schema
 * @param {object} data
 */
export const updateBillingSchemaRule = (data) => ({
  types: [UPDATE_BILLING_RULE, UPDATE_BILLING_RULE_SUCCESS, UPDATE_BILLING_RULE_FAIL],
  promise: (client) => client.patch(`v3/planners/ort_rules/${data.ort_rule.id}`, { data }),
});

/**
 * Remove rule from billing schema
 * @param {object} params
 */
export const removeBillingSchemaRule = (params) => ({
  types: [REMOVE_BILLING_RULE, REMOVE_BILLING_RULE_SUCCESS, REMOVE_BILLING_RULE_FAIL],
  promise: (client) => client.del(`v3/planners/ort_rules/${params.ruleId}`),
  params,
});

export const loadBillingRoleById = (params) => {
  const include = 'include=worker_role_exceptions,worker_role_exceptions.rule_role_rates';
  return ({
    types: [LOAD_BILLING_ROLE, LOAD_BILLING_ROLE_SUCCESS, LOAD_BILLING_ROLE_FAIL],
    promise: (client) => client.get(
      `v3/planners/billing_agreements/${params.billingId}/roles/${params.ortSchemaRoleId}?${include}`
    ),
  });
};

export const updateBillingRole = (data) => ({
  types: [UPDATE_BILLING_ROLE, UPDATE_BILLING_ROLE_SUCCESS, UPDATE_BILLING_ROLE_FAIL],
  promise: (client) => client.patch(
    `v3/planners/billing_agreements/${data.billingId}/roles/${data.ortSchemaRoleId}?include=worker_role_exceptions`,
    { data },
  ),
  data,
});

export const removeBillingSchemaRole = (data) => ({
  types: [REMOVE_BILLING_ROLE, REMOVE_BILLING_ROLE_SUCCESS, REMOVE_BILLING_ROLE_FAIL],
  promise: (client) => client.del(`v3/planners/billing_agreements/${data.billingId}/roles/${data.ortSchemaRoleId}`),
  data,
});

/**
 * Save Prefilled expenses
 * @param {object}
 */
export const savePrefilledExpense = (data) => ({
  types: [SAVE_PREFILLED_EXPENSE, SAVE_PREFILLED_EXPENSE_SUCCESS, SAVE_PREFILLED_EXPENSE_FAIL],
  promise: (client) => client.post(
    `v3/planners/billing_agreements/${data.billingId}/pre_filled_expenses`,
    { data }
  ),
  data,
});

/**
 * Remove Prefilled expenses
 * @param {object}
 */
export const removePrefilledExpense = (data) => ({
  types: [REMOVE_PREFILLED_EXPENSE, REMOVE_PREFILLED_EXPENSE_SUCCESS, REMOVE_PREFILLED_EXPENSE_FAIL],
  promise: (client) => client.del(
    `v3/planners/billing_agreements/${data.billingId}/pre_filled_expenses/${data.expenseId}`,
    { data }
  ),
  data,
});
/**
 * Edit prefilled expenses
 * @param {object}
 */
export const editPrefilledExpense = (data) => ({
  types: [EDIT_PREFILLED_EXPENSE, EDIT_PREFILLED_EXPENSE_SUCCESS, EDIT_PREFILLED_EXPENSE_FAIL],
  promise: (client) => client.patch(
    `v3/planners/billing_agreements/${data.billingId}/pre_filled_expenses/${data.expenseId}`,
    { data }
  ),
});

export const setDefault = () => ({
  type: SET_DEFAULT
});

/**
 * MUTATIONS
 */

// REPORTS
const loadReportSuccess = (action) => (state) => {
  const { data, meta } = action.result;
  let updId = [];
  const normalize = {};

  data.forEach((item) => {
    const report = item.attributes;
    if (!checkItemInArray(updId, report.id)) {
      updId = [...updId, report.id];
    }

    normalize[report.id] = report;
  });

  return ({
    ...state,
    reportEntity: {
      allIds: updId,
      byId: normalize,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      }
    }
  });
};

const updateSuccess = (action) => (state) => {
  const { byId } = state.reportEntity;
  const { data } = action.result;
  const report = data.attributes;

  return ({
    ...state,
    reportEntity: {
      ...state.reportEntity,
      byId: {
        ...byId,
        [report.id]: report,
      }
    },
  });
};

const deleteSuccess = (action) => (state) => {
  const { allIds, byId } = state.reportEntity;

  const filteredAllIds = allIds.filter((id) => id !== action.id);

  return ({
    ...state,
    reportEntity: {
      ...state.reportEntity,
      byId,
      allIds: filteredAllIds
    }
  });
};

// BILLING REPORTS
const loadBillingReportSuccess = (action) => (state) => {
  const { data, meta } = action.result;
  let updId = [];
  const normalize = {};

  data.forEach((item) => {
    const report = item.attributes;
    if (!checkItemInArray(updId, report.id)) {
      updId = [...updId, report.id];
    }

    normalize[report.id] = report;
  });

  return ({
    ...state,
    billingReportEntity: {
      allIds: updId,
      byId: normalize,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      }
    }
  });
};

const updateBillingReportSuccess = (action) => (state) => {
  const { byId } = state.billingReportEntity;
  const { data } = action.result;
  const report = data.attributes;

  return ({
    ...state,
    billingReportEntity: {
      ...state.billingReportEntity,
      byId: {
        ...byId,
        [report.id]: report,
      }
    },
  });
};

const deleteBillingReportSuccess = (action) => (state) => {
  const { allIds, byId } = state.billingReportEntity;

  const filteredAllIds = allIds.filter((id) => id !== action.id);

  return ({
    ...state,
    billingReportEntity: {
      ...state.billingReportEntity,
      byId,
      allIds: filteredAllIds
    }
  });
};

// ORT
const startRequestOrtSchemas = (action) => (state) => ({
  ...state,
  ortSchemaEntity: {
    ...state.ortSchemaEntity,
    loading: action,
  }
});

const getOrtSchemasSuccess = (action) => (state) => {
  const { data, included } = action.result;

  const updId = data.map((item) => +item.id);
  const normalize = {};

  data.forEach((item) => {
    const ruleIds = item.relationships.ort_rules?.data.map((rule) => +rule.id) || [];
    const organizationIds = item.relationships.organizations?.data.map((organization) => +organization.id) || [];
    const organizationsGroupsIds = item.relationships
      .organizations_groups?.data.map((organizationGroup) => +organizationGroup.id) || [];

    const roleIds = item.relationships.roles?.data.map((role) => +role.id) || [];
    const customAttr = item.relationships.ort_schema_custom_attributes_workers?.data || [];
    const attributesIds = customAttr.map((attribute) => +attribute.id);
    const getCustomAttributes = included?.filter(
      (includeItem) => (includeItem.type === 'ort_schema_custom_attributes_worker')
        && attributesIds.includes(+includeItem.id)
    ) || [];
    const attributesList = getCustomAttributes.map((attrItem) => {
      const prepareItem = {
        id: attrItem.attributes.custom_attributes_id,
        ...attrItem.attributes,
      };

      return prepareItem;
    });

    normalize[item.id] = {
      ...item.attributes,
      ruleIds,
      roleIds,
      organizationIds,
      organizationsGroupsIds,
      attributesList,
    };
  });

  const byRuleId = {};
  action.result.included?.forEach((item) => {
    byRuleId[item.id] = {
      ...item.attributes,
    };
  });

  return ({
    ...state,
    ortSchemaEntity: {
      ...state.ortSchemaEntity,
      byId: normalize,
      allIds: updId,
      loading: false,
      byRuleId,
    }
  });
};

const loadOrtSchemasFail = (action) => (state) => ({
  ...state,
  ortSchemaEntity: {
    ...state.ortSchemaEntity,
    loading: action,
  }
});

const addOrtSchemaSuccess = (action) => (state) => {
  const { allIds, byId } = state.ortSchemaEntity;
  const { result } = action;
  const ortSchema = result.data.attributes;

  return ({
    ...state,
    ortSchemaEntity: {
      ...state.ortSchemaEntity,
      byId: {
        ...byId,
        [ortSchema.id]: {
          ...ortSchema,
          organizationIds: [],
          roleIds: [],
          ruleIds: [],
          attributesList: [],
        }
      },
      allIds: [
        ...allIds,
        ortSchema.id,
      ]
    }
  });
};

const updateOrtSchemaSuccess = (action) => (state) => {
  const { byId } = state.ortSchemaEntity;
  const { result, data } = action;

  const ortSchema = {
    ...result.data.attributes,
  };
  const currentOrtSchema = byId[ortSchema.id];

  if (data.ort_schema?.organizations_ids) {
    ortSchema.organizationIds = data.ort_schema.organizations_ids;
  }

  if (data.ort_schema?.organization_groups_ids) {
    ortSchema.organizationsGroupsIds = data.ort_schema.organization_groups_ids;
  }

  if (data.ort_schema.roles_ids) {
    ortSchema.roleIds = data.ort_schema.roles_ids;
  }

  const attributesList = result.included
    .filter((includeItem) => (includeItem.type === 'ort_schema_custom_attributes_worker'))
    .map((attrItem) => {
      const prepareItem = {
        id: attrItem.attributes.custom_attributes_id,
        ...attrItem.attributes,
      };
      return prepareItem;
    });

  return ({
    ...state,
    ortSchemaEntity: {
      ...state.ortSchemaEntity,
      byId: {
        ...byId,
        [ortSchema.id]: {
          ...currentOrtSchema,
          ...ortSchema,
          attributesList,
        },
      },
    }
  });
};

const removeOrtSchemaSuccess = (action) => (state) => {
  const { allIds, byId } = state.ortSchemaEntity;
  const filteredAllIds = allIds.filter((id) => id !== action.id);

  return ({
    ...state,
    ortSchemaEntity: {
      ...state.ortSchemaEntity,
      allIds: filteredAllIds,
      byId,
    }
  });
};

const addOrtSchemaRuleSuccess = (action) => (state) => {
  const { byId, byRuleId } = state.ortSchemaEntity;
  const { result, data } = action;
  const ortRule = result.data.attributes;
  const currentOrtSchema = byId[data.ort_schema_id];
  const rules = currentOrtSchema.ruleIds || [];

  return ({
    ...state,
    ortSchemaEntity: {
      ...state.ortSchemaEntity,
      byId: {
        ...byId,
        [data.ort_schema_id]: {
          ...currentOrtSchema,
          ruleIds: [
            ...rules,
            ortRule.id,
          ]
        },
      },
      byRuleId: {
        ...byRuleId,
        [ortRule.id]: ortRule,
      }
    },
  });
};

const updateOrtSchemaRuleSuccess = (action) => (state) => {
  const { byRuleId } = state.ortSchemaEntity;
  const ortRule = action.result.data.attributes;

  return ({
    ...state,
    ortSchemaEntity: {
      ...state.ortSchemaEntity,
      byRuleId: {
        ...byRuleId,
        [ortRule.id]: ortRule,
      },
    }
  });
};

const removeOrtRuleSuccess = (action) => (state) => {
  const { byId, byRuleId } = state.ortSchemaEntity;
  const { params } = action;
  const currentOrtSchema = byId[params.ort_schema_id];
  const filtered = currentOrtSchema.ruleIds.filter((id) => id !== params.ruleId);

  delete byRuleId[params.ruleId];

  return ({
    ...state,
    ortSchemaEntity: {
      ...state.ortSchemaEntity,
      byId: {
        ...byId,
        [params.ort_schema_id]: {
          ...currentOrtSchema,
          ruleIds: filtered
        },
      },
      byRuleId,
    },
  });
};

const loadBillingSchemaSuccess = (action) => (state) => {
  const { data, included } = action.result;

  const updId = data.map((item) => +item.id);
  const normalize = {};

  data.forEach((item) => {
    const relationships = item.relationships || {};

    const ruleIds = relationships.ort_rules?.data.map((rule) => +rule.id) || [];
    const organizationIds = relationships.organizations?.data.map((organization) => +organization.id) || [];
    const organizationsGroupsIds = relationships.organizations_groups?.data
      .map((organizationGroup) => +organizationGroup.id) || [];

    const schemaRoleIds = relationships.ort_schema_roles?.data.map((ortRole) => +ortRole.id) || [];
    const getSchemaRoleItems = included?.filter((includeItem) => (includeItem.type === 'ort_schema_role')
      && schemaRoleIds.includes(+includeItem.id)) || [];

    const rolesList = getSchemaRoleItems.map((roleItem) => {
      const getRuleRoleRatesIds = roleItem.relationships.rule_role_rates?.data.map((rate) => +rate.id) || [];
      const getRuleRoleRatesItems = included?.filter((includeItem) => (includeItem.type === 'rule_role_rate')
      && getRuleRoleRatesIds.includes(+includeItem.id)) || [];

      return ({
        id: roleItem.attributes.role_id,
        ortSchemaRoleId: roleItem.attributes.id,
        title: roleItem.attributes.role_title,
        exception_number: roleItem.attributes.exception_number,
        buying_rate: roleItem.attributes.buying_rate || '',
        selling_rate: roleItem.attributes.selling_rate || '',
        margin_value: roleItem.attributes.margin_value || '',
        rule_role_rates: getRuleRoleRatesItems.map((ruleRoleRate) => ruleRoleRate.attributes),
      });
    });

    const customAttr = relationships.ort_schema_custom_attributes_workers?.data || [];
    const attributesIds = customAttr.map((attribute) => +attribute.id);
    const getCustomAttributes = included?.filter(
      (includeItem) => (includeItem.type === 'ort_schema_custom_attributes_worker')
        && attributesIds.includes(+includeItem.id)
    ) || [];
    const attributesList = getCustomAttributes.map((attrItem) => {
      const prepareItem = {
        id: attrItem.attributes.custom_attributes_id,
        ...attrItem.attributes,
      };

      return prepareItem;
    });

    const prefilledExpenses = relationships.pre_filled_expenses?.data || [];
    const prefilledExpensesIds = prefilledExpenses.map((expense) => +expense.id);
    const getPrefilledExpenses = included?.filter(
      (includeItem) => (includeItem.type === 'pre_filled_expense')
      && prefilledExpensesIds.includes(+includeItem.id)
    ) || [];
    const prefilledExpensesList = getPrefilledExpenses.map((expenses) => expenses.attributes);

    const billingInfo = relationships.billing_info?.data || '';
    const billingInfoId = billingInfo ? +billingInfo.id : '';

    normalize[item.id] = {
      ...item.attributes,
      billing_info_id: billingInfoId,
      ruleIds,
      organizationIds,
      organizationsGroupsIds,
      attributesList,
      rolesList,
      prefilledExpensesList
    };
  });

  const byRuleId = {};
  action.result.included?.filter((includeItem) => (includeItem.type === 'rule'))
    .forEach((item) => {
      byRuleId[item.id] = {
        ...item.attributes,
      };
    });

  return ({
    ...state,
    billingEntity: {
      ...state.billingEntity,
      allIds: updId,
      byId: normalize,
      byRuleId,
    }
  });
};

const addBillingSchemaSuccess = (action) => (state) => {
  const { allIds, byId } = state.billingEntity;
  const { result } = action;
  const billingSchema = result.data.attributes;

  return ({
    ...state,
    billingEntity: {
      ...state.billingEntity,
      allIds: [
        ...allIds,
        billingSchema.id,
      ],
      byId: {
        ...byId,
        [billingSchema.id]: {
          ...billingSchema,
          organizationIds: [],
          rolesList: [],
          ruleIds: [],
          attributesList: [],
        }
      },
    }
  });
};

const updateBillingSchemaSuccess = (action) => (state) => {
  const { byId } = state.billingEntity;
  const { result, data } = action;

  const billingSchema = {
    ...result.data.attributes,
  };
  const currentBillingSchema = byId[billingSchema.id];

  if (data.ort_schema?.organizations_ids) {
    billingSchema.organizationIds = data.ort_schema.organizations_ids;
  }

  if (data.ort_schema?.organization_groups_ids) {
    billingSchema.organizationsGroupsIds = data.ort_schema.organization_groups_ids;
  }

  const rolesList = result.included?.filter((includeItem) => (includeItem.type === 'ort_schema_role'))
    .map((roleItem) => {
      const getRuleRoleRatesIds = roleItem.relationships.rule_role_rates?.data.map((rate) => +rate.id) || [];
      const getRuleRoleRatesItems = result.included?.filter((includeItem) => (includeItem.type === 'rule_role_rate')
      && getRuleRoleRatesIds.includes(+includeItem.id)) || [];

      return ({
        id: roleItem.attributes.role_id,
        ortSchemaRoleId: roleItem.attributes.id,
        title: roleItem.attributes.role_title,
        exception_number: roleItem.attributes.exception_number,
        buying_rate: roleItem.attributes.buying_rate || '',
        selling_rate: roleItem.attributes.selling_rate || '',
        margin_value: roleItem.attributes.margin_value || '',
        rule_role_rates: getRuleRoleRatesItems.map((ruleRoleRate) => ruleRoleRate.attributes),
      });
    });

  const attributesList = result.included?.filter(
    (includeItem) => (includeItem.type === 'ort_schema_custom_attributes_worker')
  ).map((attrItem) => {
    const prepareItem = {
      id: attrItem.attributes.custom_attributes_id,
      ...attrItem.attributes,
    };
    return prepareItem;
  });

  const prefilledExpensesList = result.included?.filter((item) => item.type === 'pre_filled_expense')
    .map((el) => el.attributes);

  const billingInfo = result.data.relationships?.billing_info?.data || '';
  const billingInfoId = billingInfo ? +billingInfo.id : '';

  return ({
    ...state,
    billingEntity: {
      ...state.billingEntity,
      byId: {
        ...byId,
        [billingSchema.id]: {
          ...currentBillingSchema,
          ...billingSchema,
          billing_info_id: billingInfoId,
          prefilledExpensesList,
          attributesList,
          rolesList,
        },
      },
    }
  });
};

const removeBillingSchemaSuccess = (action) => (state) => {
  const { allIds } = state.billingEntity;
  const filteredAllIds = allIds.filter((id) => id !== action.id);

  return ({
    ...state,
    billingEntity: {
      ...state.billingEntity,
      allIds: filteredAllIds,
    }
  });
};

const addBillingSchemaRuleSuccess = (action) => (state) => {
  const { byId, byRuleId } = state.billingEntity;
  const { result, data } = action;
  const ortRule = result.data.attributes;
  const billingSchema = byId[data.id];
  const rules = billingSchema.ruleIds || [];

  return ({
    ...state,
    billingEntity: {
      ...state.billingEntity,
      byId: {
        ...byId,
        [data.id]: {
          ...billingSchema,
          ruleIds: [
            ...rules,
            ortRule.id,
          ]
        },
      },
      byRuleId: {
        ...byRuleId,
        [ortRule.id]: ortRule,
      }
    },
  });
};

const removeBillingSchemaRuleSuccess = (action) => (state) => {
  const { byId, byRuleId } = state.billingEntity;
  const { params } = action;
  const currentSchema = byId[params.id];
  const filtered = currentSchema.ruleIds.filter((id) => id !== params.ruleId);

  delete byRuleId[params.ruleId];

  return ({
    ...state,
    billingEntity: {
      ...state.billingEntity,
      byId: {
        ...byId,
        [params.id]: {
          ...currentSchema,
          ruleIds: filtered
        },
      },
      byRuleId,
    },
  });
};

const updateBillingRoleSchemaRuleSuccess = (action) => (state) => {
  const { byId } = state.billingEntity;
  const { data, result } = action;
  const billingRole = result.data.attributes;

  const currentSchema = byId[data.billingId];

  const updatedRoles = currentSchema.rolesList.map((roleItem) => {
    if (roleItem.id === data.roleId) {
      return {
        id: billingRole.role_id,
        ortSchemaRoleId: billingRole.id,
        title: billingRole.role_title,
        exception_number: data.role.role_exceptions.length,
        buying_rate: billingRole.buying_rate || '',
        selling_rate: billingRole.selling_rate || '',
        margin_value: billingRole.margin_value || '',
        rule_role_rates: data.role.rule_role_rates || [],
      };
    }

    return roleItem;
  });

  return ({
    ...state,
    billingEntity: {
      ...state.billingEntity,
      byId: {
        ...byId,
        [data.billingId]: {
          ...currentSchema,
          rolesList: updatedRoles,
        },
      },
    }
  });
};

const removeBillingRoleSchemaRuleSuccess = (action) => (state) => {
  const { byId } = state.billingEntity;
  const { data } = action;
  const currentSchema = byId[data.billingId];
  const filterRoles = currentSchema.rolesList.filter((roleItem) => roleItem.id !== data.roleId);

  return ({
    ...state,
    billingEntity: {
      ...state.billingEntity,
      byId: {
        ...byId,
        [data.billingId]: {
          ...currentSchema,
          rolesList: filterRoles,
        },
      },
    }
  });
};

const savePrefilledExpenseSuccess = (action) => (state) => {
  const { byId } = state.billingEntity;
  const { data } = action;
  const currentSchema = byId[data.billingId];
  const prefilledExpensesList = currentSchema.prefilledExpensesList || [];
  return ({
    ...state,
    billingEntity: {
      ...state.billingEntity,
      byId: {
        ...byId,
        [data.billingId]: {
          ...currentSchema,
          prefilledExpensesList: [
            ...prefilledExpensesList,
            action.result.data.attributes
          ],
        },
      },
    }
  });
};

const removePrefilledExpenseSuccess = (action) => (state) => {
  const { byId } = state.billingEntity;
  const { data } = action;
  const currentExpensesList = state.billingEntity.byId[data.billingId].prefilledExpensesList;
  const filteredExpenses = currentExpensesList.filter((el) => el.id !== data.expenseId);
  const currentSchema = byId[data.billingId];
  return ({
    ...state,
    billingEntity: {
      ...state.billingEntity,
      byId: {
        ...byId,
        [data.billingId]: {
          ...currentSchema,
          prefilledExpensesList: filteredExpenses,
        },
      },
    }
  });
};

const setDefaultSuccess = () => ({
  ...initialState,
});

const actionsLookup = {
  [LOAD_SUCCESS]: (state, action) => loadReportSuccess(action)(state),
  [UPDATE_SUCCESS]: (state, action) => updateSuccess(action)(state),
  [DELETE_SUCCESS]: (state, action) => deleteSuccess(action)(state),
  [LOAD_BILLING_SUCCESS]: (state, action) => loadBillingReportSuccess(action)(state),
  [UPDATE_BILLING_SUCCESS]: (state, action) => updateBillingReportSuccess(action)(state),
  [DELETE_BILLING_SUCCESS]: (state, action) => deleteBillingReportSuccess(action)(state),
  [GET_ORT_SCHEMAS]: (state) => startRequestOrtSchemas(true)(state),
  [GET_ORT_SCHEMAS_SUCCESS]: (state, action) => getOrtSchemasSuccess(action)(state),
  [GET_ORT_SCHEMAS_FAIL]: (state) => loadOrtSchemasFail(false)(state),
  [ADD_ORT_SCHEMA_SUCCESS]: (state, action) => addOrtSchemaSuccess(action)(state),
  [UPDATE_ORT_SCHEMA_SUCCESS]: (state, action) => updateOrtSchemaSuccess(action)(state),
  [REMOVE_ORT_SCHEMA_SUCCESS]: (state, action) => removeOrtSchemaSuccess(action)(state),
  [ADD_ORT_RULE_SUCCESS]: (state, action) => addOrtSchemaRuleSuccess(action)(state),
  [UPDATE_ORT_RULE_SUCCESS]: (state, action) => updateOrtSchemaRuleSuccess(action)(state),
  [REMOVE_ORT_RULE_SUCCESS]: (state, action) => removeOrtRuleSuccess(action)(state),
  [GET_BILLING_SCHEMAS_SUCCESS]: (state, action) => loadBillingSchemaSuccess(action)(state),
  [ADD_BILLING_SCHEMA_SUCCESS]: (state, action) => addBillingSchemaSuccess(action)(state),
  [UPDATE_BILLING_SCHEMA_SUCCESS]: (state, action) => updateBillingSchemaSuccess(action)(state),
  [REMOVE_BILLING_SCHEMA_SUCCESS]: (state, action) => removeBillingSchemaSuccess(action)(state),
  [SAVE_PREFILLED_EXPENSE_SUCCESS]: (state, action) => savePrefilledExpenseSuccess(action)(state),
  [REMOVE_PREFILLED_EXPENSE_SUCCESS]: (state, action) => removePrefilledExpenseSuccess(action)(state),
  [ADD_BILLING_RULE_SUCCESS]: (state, action) => addBillingSchemaRuleSuccess(action)(state),
  [REMOVE_BILLING_RULE_SUCCESS]: (state, action) => removeBillingSchemaRuleSuccess(action)(state),
  [UPDATE_BILLING_ROLE_SUCCESS]: (state, action) => updateBillingRoleSchemaRuleSuccess(action)(state),
  [REMOVE_BILLING_ROLE_SUCCESS]: (state, action) => removeBillingRoleSchemaRuleSuccess(action)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}