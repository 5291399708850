import isFunction from 'lodash/isFunction';
import globalConst from '../../constants';
import { serializeParamsToQueryString } from '../../utils/serialize';

const LOAD = 'redux-ducks/manager/LOAD';
const LOAD_SUCCESS = 'redux-ducks/manager/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-ducks/manager/LOAD_FAIL';

const LOAD_MANAGER = 'redux-ducks/manager/LOAD_MANAGER';
const LOAD_MANAGER_SUCCESS = 'redux-ducks/manager/LOAD_MANAGER_SUCCESS';
const LOAD_MANAGER_FAIL = 'redux-ducks/manager/LOAD_MANAGER_FAIL';

const CREATE = 'redux-ducks/manager/CREATE';
const CREATE_SUCCESS = 'redux-ducks/manager/CREATE_SUCCESS';
const CREATE_FAIL = 'redux-ducks/manager/CREATE_FAIL';

const UPDATE = 'redux-ducks/manager/UPDATE';
const UPDATE_SUCCESS = 'redux-ducks/manager/UPDATE_SUCCESS';
const UPDATE_FAIL = 'redux-ducks/manager/UPDATE_FAIL';

const REMOVE = 'redux-ducks/manager/REMOVE';
const REMOVE_SUCCESS = 'redux-ducks/manager/REMOVE_SUCCESS';
const REMOVE_FAIL = 'redux-ducks/manager/REMOVE_FAIL';

const SET_DEFAULT = 'redux-ducks/manager/SET_DEFAULT';

const initialState = {
  entity: {
    loading: false,
    allIds: [],
    byId: {},
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    },
  },
};

const load = (paramsUrl) => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get(`v3/planners/managers?${paramsUrl}`),
});

const create = (data) => ({
  types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
  promise: (client) => client.post('v3/planners/managers', { data }),
  data,
});

/**
 * Get manager by id
 */
export const getManagerById = (id, include) => ({
  types: [LOAD_MANAGER, LOAD_MANAGER_SUCCESS, LOAD_MANAGER_FAIL],
  promise: (client) => client.get(`v3/planners/managers/${id}?${include}`)
});

/**
 * Update manager by id
 */
const update = (data, include) => ({
  types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
  promise: (client) => client.patch(`v3/planners/managers/${data.manager.id}?${include}`, { data }),
});

export const removeManager = (id) => ({
  types: [REMOVE, REMOVE_SUCCESS, REMOVE_FAIL],
  promise: (client) => client.del(`v3/planners/managers/${id}`),
  id: parseInt(id, globalConst.RADIX_DECIMAL),
});

export const setDefault = () => ({
  type: SET_DEFAULT
});

export const createManager = (params) => (dispatch) => dispatch(create(params));
export const updateManager = (params, include) => (dispatch) => dispatch(update(params, include));

export const loadManagers = (params) => (dispatch) => {
  const paramsUrl = serializeParamsToQueryString(params);
  return dispatch(load(paramsUrl));
};

//
// Actions
// --------------------------------

const updateRequestOnLoad = () => (state) => ({
  ...state,
  entity: {
    ...state.entity,
    loading: true,
  }
});

const loadManagersSuccess = (action) => (state) => {
  const { data, meta } = action.result;
  const updId = data.map((item) => +item.id);
  const normalize = {};

  data.forEach((item) => {
    const organizationIds = item.relationships?.organizations?.data.map((org) => +org.id) || [];

    normalize[item.id] = {
      ...item.attributes,
      title: `${item.attributes.first_name} ${item.attributes.last_name}`,
      initial: `${item.attributes.first_name.charAt(0)}${item.attributes.last_name.charAt(0)}`,
      organizationIds,
    };
  });

  return ({
    ...state,
    entity: {
      ...state.entity,
      allIds: updId,
      byId: normalize,
      loading: false,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      },
    },
  });
};

const updateRequestOnLoadFail = () => (state) => ({
  ...state,
  entity: {
    loading: false,
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    },
    allIds: [],
    byId: {}
  },
});

const createSuccess = (action) => (state) => {
  const { allIds, byId } = state.entity;
  const { data } = action.result;

  return ({
    ...state,
    entity: {
      ...state.entity,
      byId: {
        ...byId,
        [data.attributes.id]: {
          ...data.attributes,
          title: `${data.attributes.first_name} ${data.attributes.last_name}`,
          initial: `${data.attributes.first_name.charAt(0)}${data.attributes.last_name.charAt(0)}`,
          organizationIds: action.data.manager.organizations || [],
        }
      },
      allIds: [
        ...allIds,
        data.attributes.id
      ],
    },
  });
};

const removeSuccess = (action) => (state) => {
  const { allIds, byId } = state.entity;
  const filteredAllIds = allIds.filter((id) => id !== action.id);

  delete byId[action.id];

  return ({
    ...state,
    entity: {
      ...state.entity,
      allIds: filteredAllIds,
      byId,
    },
  });
};

const setDefaultSuccess = () => initialState;

const actionsLookup = {
  [LOAD]: (state) => updateRequestOnLoad()(state),
  [LOAD_SUCCESS]: (state, action) => loadManagersSuccess(action)(state),
  [LOAD_FAIL]: (state, action) => updateRequestOnLoadFail(action)(state),
  [CREATE_SUCCESS]: (state, action) => createSuccess(action)(state),
  [REMOVE_SUCCESS]: (state, action) => removeSuccess(action)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}