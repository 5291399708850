import { configureStore } from '@reduxjs/toolkit'
import promiseMiddleware from 'redux-promise';
import multi from 'redux-multi';
import { thunk } from 'redux-thunk';
import createMiddleware from './middleware/clientMiddleware';
import rootReducer from './modules';
import config from '../config';

const configureStoreMain = (client) => {
  const middleware = [
    multi,
    promiseMiddleware,
    createMiddleware(client),
    thunk,
  ];

  const store = configureStore({
    reducer: rootReducer(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    devTools: !config.isProduction,
  });

  if (!config.isProduction && module.hot) {
    module.hot.accept('./modules', () => {
      const newRootReducer = require('./modules').default; // eslint-disable-line global-require
      store.replaceReducer(newRootReducer());
    });
  }

  return store;
};

export default configureStoreMain;
