import isFunction from 'lodash/isFunction';
import groupBy from 'lodash/groupBy';

const LOAD = 'redux-ducks/agreements/LOAD';
const LOAD_SUCCESS = 'redux-ducks/agreements/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-ducks/agreements/LOAD_FAIL';

const GENERATE = 'redux-ducks/agreements/GENERATE';
const GENERATE_SUCCESS = 'redux-ducks/agreements/GENERATE_SUCCESS';
const GENERATE_FAIL = 'redux-ducks/agreements/GENERATE_FAIL';

const DELETE_AGREEMENT = 'redux-ducks/agreements/DELETE_AGREEMENT';
const DELETE_AGREEMENT_SUCCESS = 'redux-ducks/agreements/DELETE_AGREEMENT_SUCCESS';
const DELETE_AGREEMENT_FAIL = 'redux-ducks/agreements/DELETE_AGREEMENT_FAIL';

const SEND_AGREEMENT = 'redux-ducks/agreements/SEND_AGREEMENT';
const SEND_AGREEMENT_SUCCESS = 'redux-ducks/agreements/SEND_AGREEMENT_SUCCESS';
const SEND_AGREEMENT_FAIL = 'redux-ducks/agreements/SEND_AGREEMENT_FAIL';

const LOAD_TEMPLATE = 'redux-ducks/agreements/LOAD_TEMPLATE';
const LOAD_TEMPLATE_SUCCESS = 'redux-ducks/agreements/LOAD_TEMPLATE_SUCCESS';
const LOAD_TEMPLATE_FAIL = 'redux-ducks/agreements/LOAD_TEMPLATE_FAIL';

const LOAD_BILLING_AGREEMENTS = 'redux-ducks/agreements/LOAD_BILLING_AGREEMENTS';
const LOAD_BILLING_AGREEMENTS_SUCCESS = 'redux-ducks/agreements/LOAD_BILLING_AGREEMENTS_SUCCESS';
const LOAD_BILLING_AGREEMENTS_FAIL = 'redux-ducks/agreements/LOAD_BILLING_AGREEMENTS_FAIL';

const SAVE_TEMPLATE = 'redux-ducks/agreements/SAVE_TEMPLATE';
const SAVE_TEMPLATE_SUCCESS = 'redux-ducks/agreements/SAVE_TEMPLATE_SUCCESS';
const SAVE_TEMPLATE_FAIL = 'redux-ducks/agreements/SAVE_TEMPLATE_FAIL';

const UPDATE_TEMPLATE = 'redux-ducks/agreements/UPDATE_TEMPLATE';
const UPDATE_TEMPLATE_SUCCESS = 'redux-ducks/agreements/UPDATE_TEMPLATE_SUCCESS';
const UPDATE_TEMPLATE_FAIL = 'redux-ducks/agreements/UPDATE_TEMPLATE_FAIL';

const DELETE_TEMPLATE = 'redux-ducks/agreements/DELETE_TEMPLATE';
const DELETE_TEMPLATE_SUCCESS = 'redux-ducks/agreements/DELETE_TEMPLATE_SUCCESS';
const DELETE_TEMPLATE_FAIL = 'redux-ducks/agreements/DELETE_TEMPLATE_FAIL';

const initialState = {
  entity: {
    agreements: [],
    pagination: {
      page: 1,
      total: 1,
      total_pages: 1,
    },
  },
  templates: [],
  billingAgreements: [],
};

/**
 * Agreements CRUD
 */

export const loadAgreements = (paramsUrl) => {
  const defaultIncludes = 'worker,organization,signed_document';
  const billingIncludes = 'billing_agreement,agreement_template';
  const include = `include=${defaultIncludes},${billingIncludes}${paramsUrl ? `&${paramsUrl}` : ''}`;
  return ({
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.get(`v3/planners/agreements?${include}`),
  });
};

export const generateAgreement = (data) => {
  const include = 'include=worker,organization,billing_agreement,agreement_template';

  return ({
    types: [GENERATE, GENERATE_SUCCESS, GENERATE_FAIL],
    promise: (client) => client.post(`v3/planners/agreements?${include}`, { data })
  });
};

export const deleteAgreement = (id) => ({
  types: [DELETE_AGREEMENT, DELETE_AGREEMENT_SUCCESS, DELETE_AGREEMENT_FAIL],
  promise: (client) => client.del(`v3/planners/agreements/${id}`),
  id
});

export const sendAgreement = (id) => ({
  types: [SEND_AGREEMENT, SEND_AGREEMENT_SUCCESS, SEND_AGREEMENT_FAIL],
  promise: (client) => client.patch(`v3/planners/agreements/${id}/send_to_sign`),
  id
});

export const loadBillingAgreements = (data) => ({
  types: [LOAD_BILLING_AGREEMENTS, LOAD_BILLING_AGREEMENTS_SUCCESS, LOAD_BILLING_AGREEMENTS_FAIL],
  promise: (client) => client.get(`v3/planners/billing_agreements?locale=${data.locale}`),
});

/**
 * Agreements template CRUD
 */

export const loadTemplates = () => ({
  types: [LOAD_TEMPLATE, LOAD_TEMPLATE_SUCCESS, LOAD_TEMPLATE_FAIL],
  promise: (client) => client.get('v3/planners/agreement_templates')
});

export const saveTemplate = (data) => ({
  types: [SAVE_TEMPLATE, SAVE_TEMPLATE_SUCCESS, SAVE_TEMPLATE_FAIL],
  promise: (client) => client.post('v3/planners/agreement_templates', { data })
});

export const updateTemplate = (data) => ({
  types: [UPDATE_TEMPLATE, UPDATE_TEMPLATE_SUCCESS, UPDATE_TEMPLATE_FAIL],
  promise: (client) => client.patch(`v3/planners/agreement_templates/${data.id}`, { data })
});

export const deleteTemplate = (id) => ({
  types: [DELETE_TEMPLATE, DELETE_TEMPLATE_SUCCESS, DELETE_TEMPLATE_FAIL],
  promise: (client) => client.del(`v3/planners/agreement_templates/${id}`),
  id,
});

const loadAgreementsSuccess = (action) => (state) => {
  const { data, included, meta } = action.result;

  const groupedIncludes = groupBy(included, 'type');
  const findBy = ({ type, id }) => (id && groupedIncludes[type]?.find((entity) => entity.id === id));

  const prepAgreements = data.map((agreement) => {
    const {
      // eslint-disable-next-line camelcase
      worker, agreement_template, organization, billing_agreement
    } = agreement.relationships;

    const workerRef = findBy({ type: 'worker_index', id: worker.data.id });
    // eslint-disable-next-line camelcase
    const agreementTemplateRef = findBy({ type: 'agreement_template', id: agreement_template.data.id });
    const organizationRef = findBy({ type: 'organization', id: organization.data?.id });
    // eslint-disable-next-line camelcase
    const billingAgreementRef = findBy({ type: 'billing_agreement', id: billing_agreement.data?.id });
    const documentRef = findBy({ type: 'document', id: agreement.relationships.signed_document?.data?.id });

    return ({
      id: agreement.attributes.id,
      downloadUrl: agreement.attributes.pdf_file_url,
      status: agreement.attributes.status,
      startAt: agreement.attributes.start_at,
      finishAt: agreement.attributes.finish_at,
      logo: agreement.attributes.logo,
      worker: workerRef?.attributes || {},
      agreement: agreementTemplateRef?.attributes || {},
      organization: organizationRef?.attributes || {},
      billingAgreement: billingAgreementRef?.attributes || {},
      signedDocument: documentRef?.attributes || {},
    });
  });

  return ({
    ...state,
    entity: {
      ...state.entity,
      agreements: prepAgreements,
      pagination: {
        page: meta.pagination.page,
        total: meta.pagination.total,
        total_pages: meta.pagination.total_pages
      }
    }
  });
};

const generateAgreementsSuccess = (action) => (state) => {
  const { data, included } = action.result;

  const prepAgreement = {
    id: data.attributes.id,
    downloadUrl: data.attributes.pdf_file_url,
    startAt: data.attributes.start_at,
    finishAt: data.attributes.finish_at,
    status: data.attributes.status,
    worker: included
      .find((work) => work.id === data.relationships.worker.data.id && work.type === 'worker_index').attributes,
    agreement: included
      .find((template) => template.id === data.relationships.agreement_template.data.id)?.attributes,
    organization: included
      .find(({ id }) => id === data.relationships.organization.data?.id)?.attributes,
    billingAgreement: included.find((el) => el.type === 'billing_agreement')?.attributes || '',
  };

  return ({
    ...state,
    entity: {
      ...state.entity,
      agreements: [
        prepAgreement,
        ...state.entity.agreements,
      ],
    },
  });
};

const deleteAgreementSuccess = (action) => (state) => {
  const { id } = action;
  const filteredAgreements = state.entity.agreements.filter((el) => el.id !== id);

  return ({
    ...state,
    entity: {
      ...state.entity,
      agreements: filteredAgreements
    }
  });
};

const loadTemplateSuccess = (action) => (state) => {
  const { data } = action.result;
  return ({
    ...state,
    templates: [
      ...data.map((el) => el.attributes)
    ]
  });
};

const loadBillingAgreementsSuccess = (action) => (state) => {
  const { data } = action.result;
  return ({
    ...state,
    billingAgreements: [
      ...data.map((el) => el.attributes)
    ]
  });
};

const updateTemplateSuccess = (action) => (state) => {
  const { data } = action.result;
  const { templates } = state;
  const templateToUpdate = templates.find((el) => el.id === +data.id);
  const index = templates.indexOf(templateToUpdate);
  if (index !== -1) {
    templates.splice(index, 1);

    templates.splice(index, 0, data.attributes);

    return ({
      ...state,
      templates
    });
  }
  return ({
    ...state,
    templates: [
      ...state.templates,
      data.attributes
    ]
  });
};

const deleteTemplateSuccess = (action) => (state) => {
  const { id } = action;
  const filteredTemplates = state.templates.filter((el) => el.id !== id);
  return ({
    ...state,
    templates: filteredTemplates,
  });
};

const sendAgreementSuccess = (action) => (state) => {
  const { id } = action;
  const { agreements } = state;
  const agreementIndex = state.agreements.findIndex((el) => el.id === id);

  agreements[agreementIndex] = { ...agreements[agreementIndex], ...action.result.data.attributes };

  return ({
    ...state,
    entity: {
      ...state.entity,
      agreements
    }
  });
};

const actionsLookup = {
  [LOAD_SUCCESS]: (state, action) => loadAgreementsSuccess(action)(state),
  [GENERATE_SUCCESS]: (state, action) => generateAgreementsSuccess(action)(state),
  [LOAD_TEMPLATE_SUCCESS]: (state, action) => loadTemplateSuccess(action)(state),
  [LOAD_BILLING_AGREEMENTS_SUCCESS]: (state, action) => loadBillingAgreementsSuccess(action)(state),
  [DELETE_AGREEMENT_SUCCESS]: (state, action) => deleteAgreementSuccess(action)(state),
  [UPDATE_TEMPLATE_SUCCESS]: (state, action) => updateTemplateSuccess(action)(state),
  [SAVE_TEMPLATE_SUCCESS]: (state, action) => updateTemplateSuccess(action)(state),
  [DELETE_TEMPLATE_SUCCESS]: (state, action) => deleteTemplateSuccess(action)(state),
  [SEND_AGREEMENT_SUCCESS]: (state, action) => sendAgreementSuccess(action)(state),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}