import isFunction from 'lodash/isFunction';
import flowRight from 'lodash/flowRight';
import { serializeParamsToQueryString } from '../../utils/serialize';
import { checkItemInArray } from '../../utils/utils';
import globalConst from '../../constants';

export const LOAD = 'redux-ducks/invitee/LOAD';
export const LOAD_SUCCESS = 'redux-ducks/invitee/LOAD_SUCCESS';
export const LOAD_FAIL = 'redux-ducks/invitee/LOAD_FAIL';
export const SET_DEFAULT = 'redux-ducks/invitee/SET_DEFAULT';
export const INCREASE_COUNT = 'redux-ducks/invitee/INCREASE_COUNT';

export const RESEND_BEGIN = 'redux-ducks/invitee/RESEND_BEGIN';
export const RESEND_SUCCESS = 'redux-ducks/invitee/RESEND_SUCCESS';
export const RESEND_FAIL = 'redux-ducks/invitee/RESEND_FAIL';

export const REMOVE_BEGIN = 'redux-ducks/invitee/REMOVE_BEGIN';
export const REMOVE_SUCCESS = 'redux-ducks/invitee/REMOVE_SUCCESS';
export const REMOVE_FAIL = 'redux-ducks/invitee/REMOVE_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  entity: {
    allIds: [],
    byId: {},
    pagination: {
      page: 1,
      total: 0,
      total_pages: 1,
    }
  },
  error: {},
  countRequest: 0,
};

/**
 * Load list of invited workers
 * @param {object} params filter, includes
 * @param {number} count request count
 */
export const load = (paramsUrl, count) => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  count,
  promise: (client) => client.get(`invites?${paramsUrl}`)
});

export const resend = (id) => ({
  types: [RESEND_BEGIN, RESEND_SUCCESS, RESEND_FAIL],
  promise: (client) => client.post(`invites/${id}/resend`)
});

export const remove = (id) => ({
  types: [REMOVE_BEGIN, REMOVE_SUCCESS, REMOVE_FAIL],
  promise: (client) => client.del(`invites/${id}`),
  id: parseInt(id, globalConst.RADIX_DECIMAL),
});

/**
 * Set to default state
 */
export const setDefault = () => ({
  type: SET_DEFAULT,
});

export const fetchInvites = (params) => (dispatch, getState) => {
  const paramsUrl = serializeParamsToQueryString(params, true);

  // save number of requests
  const count = getState().invitee.countRequest + 1;
  dispatch({ type: INCREASE_COUNT, count });

  return dispatch(load(paramsUrl, count));
};

const updateRequestOnLoad = (value) => (state) => ({
  ...state,
  loading: value
});

const updateRequestOnLoadSuccess = (value) => (state) => ({
  ...state,
  loading: value,
  loaded: true,
  error: null
});

const updateInviteeSuccess = (action) => (state) => {
  if (state.countRequest > action.count) {
    return ({
      ...state,
    });
  }

  const { invites, meta } = action.result;
  let updId = [];
  const normalize = {};

  invites.forEach((item) => {
    if (!checkItemInArray(updId, item.id)) {
      updId = [...updId, item.id];
    }

    normalize[item.id] = item;
  });

  return {
    ...state,
    loading: false,
    loaded: true,
    entity: {
      ...state.entity,
      allIds: updId,
      byId: normalize,
      pagination: {
        page: meta ? parseInt(meta.pagination.page, globalConst.RADIX_DECIMAL) : 1,
        total: meta ? meta.pagination.total : updId.length,
        total_pages: meta ? meta.pagination.total_pages : 1,
      }
    },
    error: null
  };
};

const updateRequestOnLoadFail = (action, value) => (state) => ({
  ...state,
  loading: false,
  loaded: value,
  entity: {
    allIds: [],
    byId: {},
    pagination: {
      page: 1,
      total: 0,
      total_pages: 1,
    }
  },
  error: action.error
});

const removeSuccess = (action) => (state) => {
  const { allIds, byId } = state.entity;
  const filteredAllIds = allIds.filter((id) => id !== action.id);

  delete byId[action.id];

  return ({
    ...state,
    entity: {
      allIds: filteredAllIds,
      byId,
      pagination: {
        ...state.entity.pagination,
        total: state.entity.pagination.total - 1,
      },
    }
  });
};

const setCountSuccess = (action) => (state) => ({
  ...state,
  countRequest: action.count
});

const setDefaultSuccess = () => initialState;

const actionsLookup = {
  [LOAD]: (state) => updateRequestOnLoad(true)(state),
  [LOAD_SUCCESS]: (state, action) => flowRight(
    updateRequestOnLoadSuccess(false),
    updateInviteeSuccess(action),
  )(state),
  [LOAD_FAIL]: (state, action) => updateRequestOnLoadFail(action, false)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
  [REMOVE_SUCCESS]: (state, action) => removeSuccess(action)(state),
  [INCREASE_COUNT]: (state, action) => setCountSuccess(action)(state),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}
